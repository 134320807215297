// PDFOptionsBar.tsx
import { useEffect, useState } from "react";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import CloseIcon from "@mui/icons-material/Close";
import { Grid, IconButton } from "@mui/material";

interface PDFOptionsBarProps {
    file: any;
    scrolledIndex: number;
    numPages: number;
    scaleText: string;
    nextPage: () => void;
    prevPage: () => void;
    handleZoomIn: () => void;
    handleZoomOut: () => void;
    goToPage: (n: number) => void;
    setZoomLevel: (percent: string) => void;
    zoomInEnabled: boolean;
    zoomOutEnabled: boolean;
    setIsOpen: Function;
}

export const PDFOptionsBar: React.FC<PDFOptionsBarProps> = ({
    file,
    scrolledIndex,
    numPages,
    scaleText,
    nextPage,
    prevPage,
    handleZoomIn,
    handleZoomOut,
    goToPage,
    setZoomLevel,
    zoomInEnabled,
    zoomOutEnabled,
    setIsOpen
}) => {
    const [inputValue, setInputValue] = useState(`${scrolledIndex + 1}`);

    useEffect(() => {
        setInputValue(`${scrolledIndex + 1}`);
    }, [scrolledIndex]);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
    };

    const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === "Enter") {
            const value = parseInt(inputValue, 10);
            if (!isNaN(value) && value > 0) {
                scrollToPage(value - 1);
            }
        }
    };

    const scrollToPage = (page: number) => {
        goToPage(page);
    };

    return (
        <Grid container spacing={2} sx={{ py: 1, px: 1 }}>
            <Grid item xs={1} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <IconButton size="small" aria-label="up" onClick={() => setIsOpen(false)} disableFocusRipple disableRipple disableTouchRipple>
                    <CloseIcon />
                </IconButton>
            </Grid>
            <Grid item xs={6} sx={{ display: "flex", alignItems: "center" }}>
                <div style={{ fontWeight: "bold", paddingLeft: 10 }} className="text font-bold">
                    {file.fileName}
                </div>
            </Grid>
            <Grid item xs={5} sx={{ display: "flex", justifyContent: "flex-end" }}>
                <div style={{ fontWeight: "bold" }} className="text font-bold">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                        <IconButton
                            size="small"
                            aria-label="up"
                            onClick={() => prevPage()}
                            disabled={scrolledIndex === 0}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                        >
                            <KeyboardArrowUpIcon />
                        </IconButton>
                        <input
                            style={{ marginLeft: 1, height: 25, width: 36, borderRadius: 5, paddingTop: 2, paddingBottom: 2, marginRight: 2 }}
                            value={inputValue}
                            onChange={handleChange}
                            onKeyDown={handleKeyDown}
                        />
                        <div> / {numPages}</div>
                        <IconButton
                            size="small"
                            aria-label="up"
                            onClick={() => nextPage()}
                            disabled={scrolledIndex === numPages - 1}
                            disableFocusRipple
                            disableRipple
                            disableTouchRipple
                        >
                            <KeyboardArrowDownIcon />
                        </IconButton>
                    </div>
                </div>
            </Grid>

            {/* <Grid item xs={3}>
                <div style={{ display: "flex", alignItems: "center", justifyContent: "center" }}>
                    <IconButton
                        size="small"
                        aria-label="up"
                        onClick={() => prevPage()}
                        disabled={scrolledIndex === 0}
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                    >
                        <KeyboardArrowUpIcon />
                    </IconButton>
                    <div>
                        <Button
                            ref={anchorRef}
                            id="composition-button"
                            aria-controls={open ? "composition-menu" : undefined}
                            aria-expanded={open ? "true" : undefined}
                            aria-haspopup="true"
                            onClick={() => handleToggle()}
                            endIcon={!zoomPopoverOpen ? <KeyboardArrowDownIcon /> : <KeyboardArrowUpIcon />}
                        >
                            {scaleText}
                        </Button>
                        <Popper sx={{ zIndex: 1 }} open={open} anchorEl={anchorRef.current} role={undefined} placement="bottom-start" transition disablePortal>
                            {({ TransitionProps, placement }) => (
                                <Grow
                                    {...TransitionProps}
                                    style={{
                                        transformOrigin: placement === "bottom-start" ? "left top" : "left bottom"
                                    }}
                                >
                                    <Paper>
                                        <ClickAwayListener onClickAway={() => setOpen(false)}>
                                            <MenuList
                                                autoFocusItem={open}
                                                id="composition-menu"
                                                aria-labelledby="composition-button"
                                                onKeyDown={handleListKeyDown}
                                            >
                                                <MenuItem onClick={() => handleClose("50")}>50%</MenuItem>
                                                <MenuItem onClick={() => handleClose("75")}>75%</MenuItem>
                                                <MenuItem onClick={() => handleClose("100")}>100%</MenuItem>
                                                <MenuItem onClick={() => handleClose("125")}>125%</MenuItem>
                                                <MenuItem onClick={() => handleClose("150")}>150%</MenuItem>
                                            </MenuList>
                                        </ClickAwayListener>
                                    </Paper>
                                </Grow>
                            )}
                        </Popper>
                    </div>
                    <IconButton
                        size="small"
                        aria-label="up"
                        onClick={() => nextPage()}
                        disabled={scrolledIndex === numPages - 1}
                        disableFocusRipple
                        disableRipple
                        disableTouchRipple
                    >
                        <KeyboardArrowDownIcon />
                    </IconButton>
                </div>
            </Grid> */}
        </Grid>
    );
};
