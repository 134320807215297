import { useEffect, useState } from "react";
import { Typography, useMediaQuery, useTheme } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { theme } from "../../../consts/versionB/consts/theme";
import { getDisclaimerNote } from "../../../api/versionB/api/api";
import { DisclaimerObj } from "../../../api/versionB/api";
import CommonButton from "../Common/CommonButton";

const CoverageAINote = () => {
    const muiTheme = useTheme();

    const matchesSm = useMediaQuery(muiTheme.breakpoints.down("sm"));
    const [open, setOpen] = useState(false);
    const [note, setNote] = useState<string>("");

    useEffect(() => {
        callDisclaimerAPI();
    }, []);

    const callDisclaimerAPI = async () => {
        try {
            const result: DisclaimerObj = await getDisclaimerNote();
            setNote(result.html);
        } catch (error) {
            setNote("");
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    function Copyright(props: any) {
        return (
            <span style={{ color: "#0071b9" }}>
                {`© ${new Date().getFullYear()} Crawford & Company`}
                {!matchesSm && <span style={{ color: "#0071b9" }}>. All Rights Reserved.</span>}
            </span>
        );
    }
    return (
        <>
            <Typography style={{ fontWeight: "600", fontSize: 11, paddingTop: 10 }}>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 2 }}>
                        <Copyright />
                    </div>

                    <div style={{ flex: 1, display: "flex", justifyContent: "right" }}>
                        <span style={{ cursor: "pointer", color: theme.palette.primaryLight, textDecoration: "underline" }} onClick={() => setOpen(true)}>
                            Disclaimer
                        </span>
                    </div>
                </div>
            </Typography>
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: theme.border.scardBorder
                    }
                }}
                open={open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="dialog">{"Disclaimer"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        <DialogContentText id="dialog-description">
                            <p dangerouslySetInnerHTML={{ __html: note }}></p>
                        </DialogContentText>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <CommonButton btnHandler={handleClose} text="Close" btnType="default" />
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CoverageAINote;
