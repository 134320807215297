import * as React from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import { claimInfo, lossInfo, policyInfo } from "../../api/data";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

interface IProps {
    data: any[];
}
export default function DetailsDrawerAccordion({ data }: IProps) {
    const [expanded, setExpanded] = React.useState<string | false>("");

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography style={{ fontSize: 14 }}>Claim Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {claimInfo.map(item => {
                        return (
                            <div style={{ border: "1px solid #eee", marginBottom: 4, padding: 10 }} key={item.key}>
                                <Typography style={headingCSS}>{item.label}</Typography>
                                <Typography style={valueCSS}>
                                    {data[0] && data[0][item.key]
                                        ? item.key === "receivedDate" ||
                                          item.key === "policyStartDate" ||
                                          item.key === "lossDate" ||
                                          item.key === "policyExpiryDate"
                                            ? new Date(data[0][item.key]).toLocaleDateString()
                                            : data[0][item.key]
                                        : "N/A"}
                                </Typography>
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography style={{ fontSize: 14 }}>Loss Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {lossInfo.map(item => {
                        return (
                            <div style={{ border: "1px solid #eee", marginBottom: 4, padding: 10 }} key={item.key}>
                                <Typography style={headingCSS}>{item.label}</Typography>
                                <Typography style={valueCSS}>
                                    {data[0] && data[0][item.key]
                                        ? item.key === "receivedDate" ||
                                          item.key === "policyStartDate" ||
                                          item.key === "lossDate" ||
                                          item.key === "policyExpiryDate"
                                            ? data[0][item.key] !== "N/A"
                                                ? new Date(data[0][item.key]).toLocaleDateString()
                                                : "N/A"
                                            : item.key === "perilTypeID"
                                            ? data[0][item.key] === "PTWR"
                                                ? "Water"
                                                : data[0][item.key] === "PTFR"
                                                ? "Fire"
                                                : data[0][item.key] === "PTFL"
                                                ? "Flood"
                                                : data[0][item.key] === "PTTF"
                                                ? "Theft"
                                                : data[0][item.key] === "PTVL"
                                                ? "Vehicle"
                                                : data[0][item.key] === "PTWD"
                                                ? "Wind/Hail"
                                                : "N/A"
                                            : item.key === "propertyTypeID"
                                            ? data[0][item.key] === "PCOM"
                                                ? "Commercial"
                                                : "Residential"
                                            : data[0][item.key]
                                        : "N/A"}
                                </Typography>
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography style={{ fontSize: 14 }}>Policy Information</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {policyInfo.map(item => {
                        return (
                            <div style={{ border: "1px solid #eee", marginBottom: 4, padding: 10 }} key={item.key}>
                                <Typography style={headingCSS}>{item.label}</Typography>
                                <Typography style={valueCSS}>
                                    {data[0] && data[0][item.key]
                                        ? item.key === "receivedDate" ||
                                          item.key === "policyStartDate" ||
                                          item.key === "lossDate" ||
                                          item.key === "policyExpiryDate"
                                            ? new Date(data[0][item.key]).toLocaleDateString()
                                            : data[0][item.key]
                                        : "N/A"}
                                </Typography>
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

const headingCSS = {
    color: "#204486",
    fontWeight: "600",
    width: "100%",
    fontSize: "12px"
};

const valueCSS = {
    color: "#555",
    width: "100%",
    fontSize: "13px"
};
