import * as React from "react";
import Paper from "@mui/material/Paper";
import Menu from "@mui/material/Menu";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress, MenuItem, TextField, Typography, makeStyles, useMediaQuery, useTheme } from "@mui/material";
import FilterList from "@mui/icons-material/FilterList";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./SearchClaim.module.css";
import { theme } from "../../../consts/versionB/consts/theme";
import { getAllPoliciesAPI, searchClaim } from "../../../api/versionB/api/api";
import { searched_claims } from "../../../api/versionB/api/dummy_data";
import { IPolicies, SearchedClaim } from "../../../api/versionB/api/models";
import { useClientAndClaimOptions } from "../../../context/versionB/ClientAndClaimProvider";
import { all_claims, berkley_claims, canada_claims, zurich_claims, chubb_claims, openly_claims } from "../../../api/versionB/api/data";
import { useLocation } from "react-router-dom";

export default function ClaimSearch() {
    const location = useLocation();

    const options =
        location.pathname === "/policy-chat/version-2"
            ? ["Openly", "Kemper", "Canada", "Berkley"]
            : location.pathname === "/policy-chat/version-2/development/threshold_value"
            ? ["Kemper", "Canada", "Berkley", "Zurich"]
            : ["Zurich", "Chubb"];
    const muiTheme = useTheme();
    const matchesSm = useMediaQuery(muiTheme.breakpoints.down("sm"));
    const { selectedClient, setSelectedClient, selectedOption, setSelectedOption, setSelectedClaimDetails } = useClientAndClaimOptions();
    // Policies state
    const [isPoliciesLoading, setIsPoliciesLoading] = React.useState<boolean>(false);
    const [allPolicies, setAllPolicies] = React.useState<string[]>([...options]);
    const [policyValue, setPolicyValue] = React.useState<string>(options[0]);
    const [inputPolicyValue, setInputPolicyValue] = React.useState<string>("");
    const [selectInternalClaimDetails, setSelectedInternalClaimDetails] = React.useState(undefined);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [searchTerm, setSearchTerm] = React.useState<string>("");
    const timeoutIdRef = React.useRef<number | undefined>(undefined);
    const [filteredData, setFilteredData] = React.useState<SearchedClaim[]>([]);
    const [isLoading, setIsLoading] = React.useState(false);
    const [key, setKey] = React.useState(0);
    const open = Boolean(anchorEl);

    // Extracting out policies options
    // React.useEffect(() => {
    //     getAllPolicies();
    // }, []);

    const getAllPolicies = async () => {
        setIsPoliciesLoading(true);
        try {
            let options: string[] = [];
            const result = await getAllPoliciesAPI();
            result.map((item: IPolicies) => {
                options.push(item.policyName);
            });
            setAllPolicies([...options]);
            setPolicyValue(options[0]);
            setIsPoliciesLoading(false);
        } catch (e) {
            setIsPoliciesLoading(false);
        }
    };

    React.useEffect(() => {
        setSelectedClaimDetails(undefined);
        setSelectedInternalClaimDetails(undefined);
        setSearchTerm("");
    }, [selectedClient]);

    React.useEffect(() => {
        setSelectedClient(options[0]);
    }, []);

    React.useEffect(() => {
        debouncedSearch(searchTerm);

        return () => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, [searchTerm]);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const selectPolicy = (value: string) => {
        setSelectedOption(value);
        setFilteredData([]);
        handleClose();
    };

    const handleSearch = async (value: string) => {
        if (value === "") {
            setFilteredData([]);
        } else {
            try {
                const request = {
                    selectedOption: selectedOption,
                    value: value
                };
                const result = await searchClaim(request);
                const data: SearchedClaim[] =
                    selectedOption === "Claim"
                        ? (policyValue === "Kemper"
                              ? all_claims
                              : policyValue === "Canada"
                              ? canada_claims
                              : policyValue === "Berkley"
                              ? berkley_claims
                              : policyValue === "Zurich"
                              ? zurich_claims
                              : policyValue === "Chubb"
                              ? chubb_claims
                              : policyValue === "Openly"
                              ? openly_claims
                              : []
                          ).filter(item => item.claimNo.toLowerCase().includes(value.toLowerCase()))
                        : (policyValue === "Kemper"
                              ? all_claims
                              : policyValue === "Canada"
                              ? canada_claims
                              : policyValue === "Berkley"
                              ? berkley_claims
                              : policyValue === "Zurich"
                              ? zurich_claims
                              : policyValue === "Chubb"
                              ? chubb_claims
                              : policyValue === "Openly"
                              ? openly_claims
                              : []
                          ).filter(item => item.programNumber.toLowerCase().includes(value.toLowerCase()));
                setFilteredData([...data.slice(0, 3)]);
            } catch (e) {
                setFilteredData([]);
            }
        }
    };

    const debounce = (callback: (value: string) => void, delay: number) => {
        return (value: string) => {
            if (timeoutIdRef.current) {
                clearTimeout(timeoutIdRef.current);
            }

            timeoutIdRef.current = setTimeout(() => {
                callback(value);
            }, delay);
        };
    };

    // Debounce the search function
    const debouncedSearch = debounce(handleSearch, 300); // Adjust the delay as needed (in milliseconds)

    const findIndex = (option: SearchedClaim) => {
        return filteredData.findIndex(item => item.claimNo === option.claimNo);
    };

    const customRenderOptions = (
        props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLLIElement> & React.LiHTMLAttributes<HTMLLIElement>,
        option: SearchedClaim
    ) => {
        return (
            <li {...props} className={styles.tableLi} style={{ background: theme.palette.black }} key={option.claimNo}>
                <table className={styles.tableBox} key={option.claimNo}>
                    <tbody className={styles.tableBody}>
                        <tr
                            className={styles.tableRow}
                            style={{ cursor: "pointer", background: findIndex(option) % 2 !== 0 ? theme.palette.lightGray2 : theme.palette.white }}
                        >
                            {matchesSm && selectedOption === "Program" && (
                                <td className={styles.tablebodyvalue} style={{ color: theme.palette.primaryGray, width: "100%" }}>
                                    {option.programNumber}
                                </td>
                            )}
                            {!matchesSm && (
                                <td className={styles.tablebodyvalue} style={{ color: theme.palette.primaryGray, width: "35%" }}>
                                    {option.programNumber}
                                </td>
                            )}
                            {matchesSm && selectedOption === "Claim" && (
                                <td className={styles.tablebodyvalue} style={{ color: theme.palette.primaryGray, width: "100%" }}>
                                    {option.claimNo}
                                </td>
                            )}
                            {!matchesSm && (
                                <td className={styles.tablebodyvalue} style={{ color: theme.palette.primaryGray, width: "25%" }}>
                                    {option.claimNo}
                                </td>
                            )}
                            {!matchesSm && (
                                <td className={styles.tablebodyvalue} style={{ color: theme.palette.primaryGray, width: "20%" }}>
                                    {new Date(option.policyStartDate).toLocaleDateString()}
                                </td>
                            )}
                            {!matchesSm && (
                                <td className={styles.tablebodyvalue} style={{ color: theme.palette.primaryGray, width: "20%" }}>
                                    {new Date(option.policyExpiryDate).toLocaleDateString()}
                                </td>
                            )}
                        </tr>
                    </tbody>
                </table>
            </li>
        );
    };

    const handler = () => {
        if (selectInternalClaimDetails) {
            setIsLoading(true);
            setSearchTerm("");
            setSelectedClaimDetails(selectInternalClaimDetails);
            setSelectedInternalClaimDetails(undefined);
            setKey(prev => prev + 1);
            setIsLoading(false);
        }
    };

    // policy filter
    const filterOptions = (options: string[], { inputValue }: { inputValue: string }) => {
        // Filter options based on input value
        const filteredOptions = options.filter(option => option.toLowerCase().includes(inputValue.toLowerCase()));
        // Return only the first `maxOptionsToShow` options
        return filteredOptions.slice(0, 3);
    };

    return (
        <React.Fragment>
            <div style={{ width: "100%" }}>
                <Paper
                    component="form"
                    sx={{
                        m: "1px 10px",
                        display: "flex",
                        alignItems: "center",
                        boxShadow: "none",
                        background: theme.palette.white
                    }}
                    className={styles.searchWrapper}
                >
                    <Autocomplete
                        disabled={isPoliciesLoading}
                        disableClearable
                        value={policyValue!}
                        onChange={(event: any, newValue: string) => {
                            setPolicyValue(newValue);
                            setSelectedClient(newValue);
                            setFilteredData([]);
                            setKey(prev => prev + 1);
                        }}
                        inputValue={inputPolicyValue}
                        onInputChange={(event, newInputValue) => {
                            setInputPolicyValue(newInputValue);
                        }}
                        filterOptions={filterOptions}
                        PaperComponent={({ children }) => {
                            return (
                                <Paper
                                    style={{
                                        paddingTop: 5,
                                        paddingBottom: 5,
                                        marginTop: 0,
                                        borderRadius: theme.border.scardBorder,
                                        paddingRight: 0,
                                        paddingLeft: 0
                                    }}
                                    elevation={1}
                                >
                                    {children}
                                </Paper>
                            );
                        }}
                        options={allPolicies}
                        sx={{ width: matchesSm ? 100 : 120, background: theme.palette.lightWhite, height: "100%", display: "flex", alignItems: "center" }}
                        style={{ borderTopLeftRadius: theme.border.scardBorder, borderBottomLeftRadius: theme.border.scardBorder }}
                        noOptionsText={<div style={{ fontSize: "12px" }}>No options</div>}
                        renderInput={params => (
                            <InputBase
                                style={{ fontSize: 14, padding: 10 }}
                                onChange={e => setInputPolicyValue(e.target.value)}
                                onKeyDown={event => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        event.stopPropagation();
                                    }
                                }}
                                value={inputPolicyValue}
                                fullWidth
                                inputProps={params.inputProps}
                                {...params.InputProps}
                                placeholder={`Policy`}
                            />
                        )}
                        renderOption={(props, option, { selected }) => (
                            <li
                                {...props}
                                style={{
                                    background: theme.palette.white,
                                    fontSize: "12px",
                                    fontWeight: selected ? "bold" : 500,
                                    color: theme.palette.primaryGray
                                }}
                            >
                                {option}
                            </li>
                        )}
                    />
                    <Autocomplete
                        key={key}
                        disabled={selectedClient === null ? true : false}
                        freeSolo
                        clearOnBlur={true}
                        sx={{ flex: 1 }}
                        onBlur={() => setFilteredData([])}
                        options={filteredData}
                        getOptionLabel={(option: any) => option[selectedOption === "Claim" ? "claimNo" : "programNumber"]}
                        PaperComponent={({ children }) => {
                            if (searchTerm) {
                                return (
                                    <Paper
                                        style={{
                                            paddingTop: 5,
                                            paddingBottom: 5,
                                            marginTop: 0,
                                            borderRadius: theme.border.scardBorder,
                                            paddingRight: 10,
                                            paddingLeft: 10
                                        }}
                                        elevation={1}
                                    >
                                        <table className={styles.tableBox}>
                                            <thead style={{ marginTop: 0 }}>
                                                <tr className={styles.tableRow}>
                                                    {matchesSm && selectedOption === "Program" && (
                                                        <th
                                                            className={styles.tablehead}
                                                            style={{
                                                                background: theme.palette.white,
                                                                color: theme.palette.textGray,
                                                                width: "100%"
                                                            }}
                                                        >
                                                            Program
                                                        </th>
                                                    )}
                                                    {!matchesSm && (
                                                        <th
                                                            className={styles.tablehead}
                                                            style={{
                                                                background: theme.palette.white,
                                                                color: theme.palette.textGray,
                                                                width: "35%"
                                                            }}
                                                        >
                                                            Program
                                                        </th>
                                                    )}
                                                    {matchesSm && selectedOption === "Claim" && (
                                                        <th
                                                            className={styles.tablehead}
                                                            style={{
                                                                background: theme.palette.white,
                                                                color: theme.palette.textGray,
                                                                width: "100%"
                                                            }}
                                                        >
                                                            Claim #
                                                        </th>
                                                    )}
                                                    {!matchesSm && (
                                                        <th
                                                            className={styles.tablehead}
                                                            style={{ background: theme.palette.white, color: theme.palette.textGray, width: "25%" }}
                                                        >
                                                            Claim #
                                                        </th>
                                                    )}
                                                    {!matchesSm && (
                                                        <th
                                                            className={styles.tablehead}
                                                            style={{ background: theme.palette.white, color: theme.palette.textGray, width: "20%" }}
                                                        >
                                                            Start
                                                        </th>
                                                    )}
                                                    {!matchesSm && (
                                                        <th
                                                            className={styles.tablehead}
                                                            style={{ background: theme.palette.white, color: theme.palette.textGray, width: "20%" }}
                                                        >
                                                            Expiry
                                                        </th>
                                                    )}
                                                </tr>
                                            </thead>
                                        </table>
                                        {children}
                                    </Paper>
                                );
                            } else {
                                return <></>;
                            }
                        }}
                        onChange={(event, newValue: any) => selectedClient !== null && setSelectedInternalClaimDetails(newValue)}
                        renderInput={params => (
                            <InputBase
                                value={searchTerm}
                                style={{ fontSize: 14, paddingLeft: 10, paddingRight: 0, paddingBottom: 6, paddingTop: 6 }}
                                disabled={selectedClient === null ? true : false}
                                onChange={e => setSearchTerm(e.target.value)}
                                onKeyDown={event => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        event.stopPropagation();
                                        handler();
                                    }
                                }}
                                fullWidth
                                inputProps={params.inputProps}
                                {...params.InputProps}
                                placeholder={`${selectedOption} search`}
                                endAdornment={
                                    !selectInternalClaimDetails ? (
                                        <React.Fragment>
                                            <IconButton disabled={selectedClient === null ? true : false} type="button" onClick={handleClick}>
                                                <FilterList />
                                            </IconButton>
                                        </React.Fragment>
                                    ) : (
                                        <React.Fragment>
                                            {!isLoading ? (
                                                <IconButton
                                                    sx={{
                                                        borderTopRightRadius: theme.border.scardBorder,
                                                        borderBottomRightRadius: theme.border.scardBorder,
                                                        borderTopLeftRadius: 0,
                                                        borderBottomLeftRadius: 0,
                                                        width: "auto",
                                                        height: "100%",
                                                        background: theme.palette.primaryLight,
                                                        "&:hover": {
                                                            background: theme.palette.primaryLight,
                                                            opacity: 0.8
                                                        }
                                                    }}
                                                    disabled={selectedClient === null ? true : false}
                                                    type="button"
                                                    onClick={() => !isLoading && handler()}
                                                >
                                                    <ArrowForwardIcon sx={{ color: theme.palette.white }} />
                                                </IconButton>
                                            ) : (
                                                <IconButton
                                                    sx={{
                                                        width: "auto",
                                                        height: "100%",
                                                        background: theme.palette.white,
                                                        "&:hover": {
                                                            background: theme.palette.white
                                                        }
                                                    }}
                                                    type="button"
                                                >
                                                    <CircularProgress
                                                        size={23}
                                                        sx={{
                                                            color: theme.palette.primaryLight
                                                        }}
                                                    />
                                                </IconButton>
                                            )}
                                        </React.Fragment>
                                    )
                                }
                            />
                        )}
                        renderOption={customRenderOptions}
                    />
                    <Menu
                        elevation={1}
                        id="basic-menu"
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                        MenuListProps={{
                            "aria-labelledby": "basic-button"
                        }}
                    >
                        <MenuItem onClick={() => selectPolicy("Claim")} sx={{ fontSize: 12 }}>
                            Claim
                        </MenuItem>
                        <MenuItem onClick={() => selectPolicy("Program")} sx={{ fontSize: 12 }}>
                            Program
                        </MenuItem>
                    </Menu>
                </Paper>
            </div>
        </React.Fragment>
    );
}
