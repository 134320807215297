import { useState } from "react";
import { IconButton, Stack, TextField } from "@fluentui/react";
import { Send28Filled } from "@fluentui/react-icons";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import styles from "./QuestionInputField.module.css";
import { theme } from "../../../consts/versionB/consts/theme";
import StopCircleIcon from "@mui/icons-material/StopCircle";
import { SearchedClaim } from "../../../api/versionB/api";
interface Props {
    selectedClaimDetails: SearchedClaim | undefined;
    onSend: (question: string) => void;
    disabled: boolean;
    placeholder?: string;
    clearOnSend?: boolean;
    cancelRequest: Function;
}

export const QuestionInputField = ({ selectedClaimDetails, onSend, disabled, placeholder, clearOnSend, cancelRequest }: Props) => {
    const [question, setQuestion] = useState<string>("");

    const sendQuestion = () => {
        if (disabled || !question.trim()) {
            return;
        }
        onSend(question);

        if (clearOnSend) {
            setQuestion("");
        }
    };

    const onEnterPress = (ev: React.KeyboardEvent<Element>) => {
        if (ev.key === "Enter" && !ev.shiftKey) {
            ev.preventDefault();
            sendQuestion();
        }
    };

    const onQuestionChange = (_ev: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => {
        if (!newValue) {
            setQuestion("");
        }
        setQuestion(newValue!);
    };

    const sendQuestionDisabled = disabled || !question.trim();

    return (
        <Stack
            horizontal
            style={{ background: !selectedClaimDetails ? theme.palette.lightWhite : theme.palette.white }}
            className={styles.questionInputContainer}
        >
            <TextField
                styles={{
                    fieldGroup: {
                        maxHeight: "150px",
                        overflowY: "auto",
                        overflowX: "hidden",
                        borderRadius: 8,
                        "::-webkit-scrollbar": {
                            width: "5px",
                            backgroundColor: "#fff"
                        },
                        "::-webkit-scrollbar-thumb": {
                            backgroundColor: "#e1e1e9",
                            borderRadius: "6px"
                        },
                        "::-webkit-scrollbar-thumb:hover": {
                            backgroundColor: " rgb(216, 216, 216)"
                        },
                        "::-webkit-scrollbar-track": {
                            background: "#fff"
                        }
                    }
                }}
                autoAdjustHeight
                className={styles.questionInputTextArea}
                placeholder={placeholder}
                multiline
                resizable={false}
                borderless
                value={question}
                onChange={onQuestionChange}
                onKeyDown={onEnterPress}
                style={{
                    background: !selectedClaimDetails ? theme.palette.lightWhite : "transparent",
                    borderRadius: 8,
                    paddingRight: 40
                }}
                disabled={!selectedClaimDetails}
            />
            <div className={styles.questionInputButtonsContainer}>
                {disabled ? (
                    <div
                        className={`${styles.questionInputSendButton}`}
                        aria-label="Cancel"
                        onClick={() => cancelRequest()}
                        // style={{ background: theme.palette.primaryDark }}
                    >
                        <StopCircleIcon sx={{ fontSize: 30, color: theme.palette.primaryDark }} />
                    </div>
                ) : (
                    <div
                        className={`${styles.questionInputSendButton} ${sendQuestionDisabled ? styles.questionInputSendButtonDisabled : ""}`}
                        aria-label="Ask question button"
                        onClick={sendQuestion}
                        style={{ background: theme.palette.primaryDark }}
                    >
                        <ArrowUpwardIcon sx={{ fontSize: 25, color: theme.palette.white }} />
                    </div>
                )}
            </div>
        </Stack>
    );
};
