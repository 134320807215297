import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormGroup, FormControlLabel, Checkbox } from "@mui/material";
import { colors } from "../../consts/colors";
import { ClaimContext } from "../../context/ClaimOutcomeProvider";

export default function ClaimOutcomeStepper() {
    const data = React.useContext(ClaimContext);

    const steps = [
        {
            label: "Fully Covered",
            tagline:
                "The claim is explicitly included in the HO3 policy without limitations. The policyholder gets full reimbursement or repair/replacement, minus any deductible",
            description: "",
            checked: data?.selectedClaim === 1
        },
        {
            label: "Partially Covered",
            tagline:
                "The claim is covered but has certain restrictions. Limitations could arise from sub-limits, policy conditions, or deductibles. The policyholder receives a portion of the claim's value.",
            description: "",
            checked: data?.selectedClaim === 2
        },
        {
            label: "Not Covered",
            tagline: "The claim isn't included or addressed in the HO3 policy, so there's no reimbursement or benefit for the claim.",
            description: "",
            checked: data?.selectedClaim === 3
        },
        {
            label: "Need additional information to assess Coverage",
            tagline:
                "This status is the initial step in the claim assessment process, applied when the information available is either insufficient or too ambiguous to determine coverage. It is a preliminary stage, necessitating further investigation or information gathering.",
            description: "",
            checked: data?.selectedClaim === 4
        }
    ];
    return (
        <Box sx={{ width: "100%", paddingRight: 1, paddingLeft: 1 }}>
            {steps.map((step, index) => (
                <Box sx={{ mb: 2 }} key={step.label}>
                    <FormGroup>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    sx={{
                                        "&.Mui-checked": {
                                            color: colors.primaryDark
                                        }
                                    }}
                                    onClick={() => data?.setSelectedClaim(index + 1)}
                                    checked={step.checked}
                                    size="small"
                                />
                            }
                            label={<Typography style={{ fontSize: 14 }}>{step.label}</Typography>}
                        />
                        <Typography style={{ fontSize: 13 }}>{step.tagline}</Typography>
                        <small style={{ fontSize: 12, color: colors.primaryDark, fontWeight: "bold", textDecoration: "underline" }}>Documentation</small>
                    </FormGroup>
                </Box>
            ))}
        </Box>
    );
}
