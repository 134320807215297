interface Colors {
    primaryDark: string;
    primaryLight: string;
    primaryGray: string;
    actionColor: string;
    lightGray: string;
    lightGray2: string;
}

export const colors: Colors = {
    primaryDark: "#204486",
    primaryLight: "#0071B9",
    primaryGray: "#555555",
    actionColor: "#DF195C",
    lightGray: "#D7D2CB",
    lightGray2: "#EFEFEF"
};
