interface IProps {
    operator: string | null;
}

const ActiveMode = ({ operator }: IProps) => {
    return (
        <div style={{ display: "flex", justifyContent: "center" }}>
            <div style={{ background: "#f0f7ff", width: "98%", borderTopLeftRadius: 6, borderTopRightRadius: 6, border: "1px solid rgba(229, 234, 242, 0.5)" }}>
                <span style={{ color: "#0072E5", fontSize: 12, paddingLeft: 10, paddingRight: 10, fontWeight: "500" }}>Active Module: {operator}</span>
            </div>
        </div>
    );
};

export default ActiveMode;
