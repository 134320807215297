// App Theme
export interface ITheme {
    palette: {
        bg: string;
        primaryDark: string;
        primaryLight: string;
        primaryGray: string;
        actionColor: string;
        black: string;
        darkGray: string;
        textGray: string;
        lightGray: string;
        lightGray2: string;
        lightGrayD1: string;
        white: string;
        white2: string;
        whiteF2: string;
        lightWhite: string;
        link: string;
        blueLight: string;
        blueLightText: string;
        red: string;
        btnRed: string;
    };
    typography: {
        chat: {
            question: {
                fontWeight: number | string;
                color: string;
            };
            answer: {
                fontWeight: number | string;
                color: string;
            };
        };
    };
    border: {
        bcardBorder: string;
        scardBorder: string;
    };
}
export const theme: ITheme = {
    palette: {
        bg: "#EDEDED",
        primaryDark: "#204486",
        primaryLight: "#0071B9",
        primaryGray: "#555555",
        actionColor: "#DF195C",
        black: "#000000",
        darkGray: "#333",
        textGray: "#878f94",
        lightGray: "#D7D2CB",
        lightGray2: "#EFEFEF",
        lightGrayD1: "#D1DBFA",
        white: "#FFFFFF",
        white2: "#E8EBFA",
        whiteF2: "#F2F2F2",
        lightWhite: "#F5F5F5",
        link: "#123BB6",
        blueLight: "#f0f7ff",
        blueLightText: "#0072E5",
        red: "#ef4444",
        btnRed: "#b91c1c"
    },
    typography: {
        chat: {
            question: {
                fontWeight: "normal",
                color: "#000"
            },
            answer: {
                fontWeight: "normal",
                color: "#000"
            }
        }
    },
    border: {
        bcardBorder: "40px",
        scardBorder: "20px"
    }
};
