import * as React from "react";
import {
    Slide,
    Dialog,
    DialogTitle,
    styled,
    DialogContent,
    IconButton,
    Box,
    Typography,
    Button,
    MenuItem,
    Slider,
    FormControl,
    InputLabel,
    Select,
    SelectChangeEvent
} from "@mui/material";
import { TransitionProps } from "@mui/material/transitions";
import CloseIcon from "@mui/icons-material/Close";
import { createTheme, ThemeProvider, Theme, useTheme } from "@mui/material/styles";
import MuiAlert, { AlertProps } from "@mui/material/Alert";
import styles from "./SettingsDialog.module.css";
import { theme } from "../../../consts/versionB/consts/theme";
import { useLocation, useNavigate } from "react-router-dom";
import ArchivedChatsDialog from "./ArchivedChatsDialog";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    setSettingDialogShow: Function;
    settingDialogShow: boolean;
}

const customTheme = (outerTheme: Theme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#eee",
                        "--TextField-brandBorderHoverColor": "#eee",
                        "--TextField-brandBorderFocusedColor": "#888",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)"
                        }
                    }
                }
            },

            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before, &:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)"
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)"
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)"
                        }
                    }
                }
            }
        }
    });

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} style={{ backgroundColor: "#d2f4d3", color: "#1a7f64", fontWeight: "bold" }} />;
});

const SettingsDialog = (props: IProps) => {
    const location = useLocation();
    const showThresholdSettings = location.pathname === "/policy-chat/version-2/development/threshold_value" ? true : false;
    const showOptimizationVersion = location.pathname === "/policy-chat/version-2/development" ? true : false;

    const [isLoading, setIsLoading] = React.useState(false);
    const [archivedDialogShow, setArchivedDialogShow] = React.useState(false);
    const [threshVal, setThreshVal] = React.useState(() => {
        const val = localStorage.getItem("threshold_value");
        return val ? parseInt(val) : 5;
    });
    const [optimizationVal, setOptimizationVal] = React.useState(() => {
        const val = localStorage.getItem("active_optamization_version");
        return val ? val : "base";
    });

    const navigate = useNavigate();
    const outerTheme = useTheme();

    React.useEffect(() => {
        if (props.settingDialogShow) {
            getValue();
        }
    }, [localStorage]);

    const switchToOld = () => {
        setIsLoading(true);
        // localStorage.setItem("appVersion", "old");
        setTimeout(() => {
            setIsLoading(false);
            navigate("/apps");
        }, 1000);
    };
    const updateValue = (event: Event, newValue: number | number[]) => {
        const updatedValue = typeof newValue === "number" ? newValue : newValue[0];
        localStorage.setItem("threshold_value", updatedValue.toString());
        setThreshVal(updatedValue);
    };
    const getValue = () => {
        const val = localStorage.getItem("threshold_value");
        if (val) {
            let updatedVal = parseInt(val);
            setThreshVal(updatedVal);
        }
    };

    const handleChange = (event: SelectChangeEvent) => {
        localStorage.setItem("active_optamization_version", event.target.value);
        setOptimizationVal(event.target.value as string);
    };

    return (
        <>
            <BootstrapDialog
                PaperProps={{
                    style: {
                        borderRadius: theme.border.scardBorder
                    }
                }}
                keepMounted
                TransitionComponent={Transition}
                onClose={() => props.setSettingDialogShow(false)}
                aria-labelledby="feedback-form"
                open={props.settingDialogShow}
                fullWidth
                maxWidth="sm"
                scroll="body"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Settings
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => props.setSettingDialogShow(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>

                <DialogContent dividers>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                        <Box sx={{ display: "flex", justifyContent: "space-between", marginBottom: 2 }}>
                            <Typography component={"p"} variant="body1" sx={{ fontSize: 14, fontWeight: "bold" }}>
                                Version
                            </Typography>
                            <div>
                                <Button onClick={() => switchToOld()} variant="contained" sx={{ fontSize: 12, textTransform: "none" }}>
                                    {isLoading ? "Redirecting..." : "Back to Previous Version"}
                                </Button>
                            </div>
                        </Box>
                        <Box sx={{ display: "flex", justifyContent: "space-between" }}>
                            <Typography component={"p"} variant="body1" sx={{ fontSize: 14, fontWeight: "bold" }}>
                                Archived Chats
                            </Typography>
                            <div>
                                <Button onClick={() => setArchivedDialogShow(true)} variant="contained" sx={{ fontSize: 12, textTransform: "capitalize" }}>
                                    Manage
                                </Button>
                            </div>
                        </Box>
                        <Box sx={{ display: showOptimizationVersion ? "flex" : "none", justifyContent: "space-between", marginTop: 2 }}>
                            <Typography component={"p"} variant="body1" sx={{ fontSize: 14, fontWeight: "bold" }}>
                                Optimization Approach
                            </Typography>
                            <div style={{ width: 200 }}>
                                <FormControl fullWidth>
                                    <InputLabel id="demo-simple-select-label">Optimization Value</InputLabel>
                                    <Select
                                        labelId="demo-simple-select-label"
                                        id="demo-simple-select"
                                        value={optimizationVal}
                                        label="Optimization Value"
                                        onChange={handleChange}
                                    >
                                        <MenuItem value={"base"}>Base</MenuItem>
                                        <MenuItem value={"base_node"}>Base Node</MenuItem>
                                        <MenuItem value={"optim_1"}>Optimization 1</MenuItem>
                                        <MenuItem value={"optim_2"}>Optimization 2</MenuItem>
                                        <MenuItem value={"optim_3"}>Optimization 3</MenuItem>
                                        <MenuItem value={"optim_1_node"}>Optimization 1 Node</MenuItem>
                                        <MenuItem value={"app3"}>Test App</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </Box>
                        <Box sx={{ display: showThresholdSettings ? "flex" : "none", justifyContent: "space-between", marginTop: 2 }}>
                            <Typography component={"p"} variant="body1" sx={{ fontSize: 14, fontWeight: "bold" }}>
                                Threshold Value
                            </Typography>
                            <div style={{ width: 250, marginRight: 10, marginLeft: 10 }}>
                                <Slider value={threshVal} step={1} marks min={1} max={10} valueLabelDisplay="auto" onChange={updateValue} />
                                Currently active: {threshVal / 10}
                            </div>
                        </Box>
                    </ThemeProvider>
                </DialogContent>
            </BootstrapDialog>

            {/* // Archived Chats Dialog */}
            <ArchivedChatsDialog setArchivedDialogShow={setArchivedDialogShow} archivedDialogShow={archivedDialogShow} />
        </>
    );
};

export default SettingsDialog;
