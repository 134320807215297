import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { theme } from "../../../consts/versionB/consts/theme";
import CommonButton from "./CommonButton";

interface IProps {
    isAlertVisible: boolean;
    setIsAlertVisible: Function;
    alertTitle: string;
    alertDescription: string;
    boldDescription?: string;
    alertHandler: () => void;
    buttonText: string[];
}

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CommonDialog({ isAlertVisible, setIsAlertVisible, alertTitle, alertDescription, boldDescription, alertHandler, buttonText }: IProps) {
    const handleClose = () => {
        setIsAlertVisible(false);
    };

    const runFunc = () => {
        alertHandler();
        setIsAlertVisible(false);
    };

    return (
        <React.Fragment>
            <Dialog
                PaperProps={{
                    style: {
                        borderRadius: theme.border.scardBorder
                    }
                }}
                fullWidth
                maxWidth="sm"
                open={isAlertVisible}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle sx={{ fontWeight: "bold", fontSize: 16 }}>{alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" sx={{ color: theme.palette.black }}>
                        {alertDescription} <b>{boldDescription}</b>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ p: 2 }}>
                    <CommonButton btnHandler={handleClose} text={buttonText[0]} btnType="default" />
                    <CommonButton btnHandler={runFunc} text={buttonText[1]} btnType="danger" />
                </DialogActions>
            </Dialog>
        </React.Fragment>
    );
}
