import { useState } from "react";
import { Typography } from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import Button from "@mui/material/Button";

const CoverageAINote = () => {
    const [open, setOpen] = useState(false);
    const note = ` This AI-generated response are intended to assist in the decision-making process. It is imperative to meticulously review and validate the information provided to ensure its accuracy and applicability to each unique case. Always align insights with detailed policy wording, pertinent legal guidelines, and specific client instructions to guarantee precision and accuracy in coverage decisions.`;

    const handleClose = () => {
        setOpen(false);
    };

    const limitedWords = () => {
        const words = note.split(" ");
        return words.slice(0, 20).join(" ");
    };

    function Copyright(props: any) {
        return (
            <span style={{ color: "#0071b9" }}>
                {`© ${new Date().getFullYear()} Crawford & Company`}
                <span style={{ color: "#0071b9" }}>. All Rights Reserved.</span>
            </span>
        );
    }
    return (
        <>
            {/* <Typography style={{ fontWeight: "600", fontSize: 10, color: "#878f94", textAlign: "center", paddingTop: 10 }}>
                {limitedWords()}...{" "}
                <span style={{ paddingTop: 5, cursor: "pointer", color: "#0000EE", textDecoration: "underline" }} onClick={() => setOpen(true)}>
                    more
                </span>
            </Typography> */}
            <Typography style={{ fontWeight: "600", fontSize: 11, paddingTop: 10 }}>
                <div style={{ display: "flex" }}>
                    <div style={{ flex: 1 }}>
                        <Copyright />
                    </div>
                    {/* <div style={{ flex: 1, justifyContent: "center", display: "flex" }}>
                        <span style={{ color: "#0071b9" }}>All Rights Reserved</span>
                    </div> */}
                    <div style={{ flex: 1, display: "flex", justifyContent: "right" }}>
                        <span style={{ cursor: "pointer", color: "#0071b9", textDecoration: "underline" }} onClick={() => setOpen(true)}>
                            Disclaimer
                        </span>
                    </div>
                </div>
            </Typography>
            <Dialog open={open} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="dialog">{"Disclaimer"}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="dialog-description">
                        <DialogContentText id="dialog-description">
                            This AI-generated response are intended to assist in the decision-making process. It is imperative to meticulously review and
                            validate the information provided to ensure its accuracy and applicability to each unique case.
                            <br />
                            <br />
                            Always align insights with detailed policy wording, pertinent legal guidelines, and specific client instructions to guarantee
                            precision and accuracy in coverage decisions.
                        </DialogContentText>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} autoFocus>
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    );
};

export default CoverageAINote;
