import { useState, useRef, useEffect } from "react";
import { Box, styled, Typography, Stack, Grid, TextField, InputAdornment, IconButton, AppBar, Toolbar, Button } from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import SendIcon from "@mui/icons-material/Send";
import { Send28Filled, SparkleFilled } from '@fluentui/react-icons'
import LinearProgress from "@mui/material/LinearProgress";
import { colors } from "../../consts/colors";
import ChatGPTChatContainer from "../../components/ChatGPTUI/ChatGPTUI";
import { Approaches, ChatRequest, chatWithGPTApi } from "../../api";
import GPTEmptyScreen from "./GPTEmptyScreen";
import Header from "../../components/Header/Header";
import logo from "../../assets/logo.png";
import { Navigate, useNavigate } from "react-router-dom";
import Logo from "../../components/Logo/Logo";
import AbortControllerUI from "../../components/ChatGPTUI/AbortControllerUI";
import styles from "./Chat.module.css";
import QuesAnsBox from "../../components/ChatGPTUI/QuesAnsBox";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnswerLoading } from "../../components/Common/AnswerLoading";
import ErrorSnackbar from "../../components/ErrorSnackbar/ErrorSnackbar";

const Container = styled(Box)(() => ({
    height: "100vh"
}));

const TextFieldContainer = styled(Box)(() => ({
    height: 90,
    position: "sticky",
    bottom: 0,
    flex: "0 0 5px",
    padding: 15,
    width: "100%",
    maxWidth: "1028px",
    background: "white",
    margin: "0 auto",
    borderRadius: 8,
    boxShadow: "0px 8px 16px rgba(0, 0, 0, 0.14), 0px 0px 2px rgba(0, 0, 0, 0.12)"
}));

const ChatGPT = () => {
    const abortController = useRef<any>(null);
    const messagesEndRef = useRef<any>(null);

    const [isHovered, setIsHovered] = useState(false);
    const [chatMessage, setChatMessage] = useState("");
    const lastQuestionRef = useRef<string>("");
    const [isConfigPanelOpen, setIsConfigPanelOpen] = useState(false);
    const [promptTemplate, setPromptTemplate] = useState<string>("");
    const [retrieveCount, setRetrieveCount] = useState<number>(3);
    const [useSemanticRanker, setUseSemanticRanker] = useState<boolean>(true);
    const [useSemanticCaptions, setUseSemanticCaptions] = useState<boolean>(false);
    const [excludeCategory, setExcludeCategory] = useState<string>("");
    const [useSuggestFollowupQuestions, setUseSuggestFollowupQuestions] = useState<boolean>(false);
    const [answers, setAnswers] = useState<[user: string, response: any][]>([]);

    const [isLoading, setIsLoading] = useState(false);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    const navigate = useNavigate();

    useEffect(() => {
        isLoading && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [isLoading]);

    const makeApiRequest = async (question: string) => {
        lastQuestionRef.current = question;

        // error && setError(undefined);
        setIsLoading(true);
        // setActiveCitation(undefined);
        // setActiveAnalysisPanelTab(undefined);

        // Setting abort request
        abortController.current = new AbortController()
        const abortSignal = abortController.current.signal;

        try {
            const history: any = answers.map(a => ({ user: a[0], bot: a[1].answer }));
            const request: ChatRequest = {
                history: [...history, { user: question, bot: undefined }],
                approach: Approaches.ChatReadApproach,
                overrides: {
                    promptTemplate: promptTemplate.length === 0 ? undefined : promptTemplate,
                    excludeCategory: excludeCategory.length === 0 ? undefined : excludeCategory,
                    top: retrieveCount,
                    semanticRanker: useSemanticRanker,
                    semanticCaptions: useSemanticCaptions,
                    suggestFollowupQuestions: useSuggestFollowupQuestions
                }
            };
            const result = await chatWithGPTApi(request, abortSignal);
            setAnswers([...answers, [question, result]]);
            setIsLoading(false)

        } catch (e) {
            setIsLoading(false)
            setShowErrorAlert(true)
            // setError(e);
        }
        finally {
            setIsLoading(false);
        }
    };

    const onSubmit = (): void => {
        makeApiRequest(chatMessage);
        setChatMessage("");
    };

    const handleKeyDown = (event: React.KeyboardEvent<HTMLDivElement>) => {
        if (event.key === "Enter" && !event.shiftKey) {
            event.preventDefault();
            onSubmit();
        }
    };

    const disableSendBtn = () => {
        if (chatMessage === "" || !chatMessage) {
            return true;
        }
        return false;
    };

    return (
        <>
            {/* <Header>
                <Box flexGrow={1}>
                    <Logo />
                </Box>
                <Box flexGrow={0.5}>
                    <Typography variant="h6" component="div">
                        Crawford Legal AI
                    </Typography>
                </Box>
                <Box flexGrow={1}></Box>
            </Header> */}
            <AppBar position="static" sx={{ backgroundColor: colors.primaryDark }}>
                <Toolbar><Grid container xs={2}>
                    <Logo /></Grid>
                    <Grid container xs={8} sx={{ display: "flex", alignItems: "center", justifyContent: "center" }}>  <Typography variant="h6" component="div" sx={{ textAlign: "center" }}>
                        Crawford Legal AI
                    </Typography></Grid>
                    <Grid container xs={2} sx={{ display: "flex", alignItems: "center", justifyContent: "end" }}>
                        <Button startIcon={<AddIcon
                            sx={{
                                color: "#fff",
                                fontSize: 18
                            }}
                        />} variant="outlined" onClick={() => {//@ts-ignore
                            lastQuestionRef.current = "";
                            setAnswers([])
                        }} sx={{ color: "white" }}>New Chat</Button></Grid></Toolbar>
            </AppBar>
            <Box sx={{ height: "calc(100vh - 70px)" }}>
                <Grid container spacing={0}>
                    {/* <Grid item md={2}>
                        <Box
                            sx={{
                                display: "flex",
                                height: "calc(100vh - 64px)",
                                width: "100%",
                                backgroundColor: colors.primaryGray,
                                padding: 2
                            }}
                        >
                            <Stack
                                direction="row"
                                alignItems="center"
                                onClick={() => {
                                    //@ts-ignore
                                    lastQuestionRef.current = "";
                                    setAnswers([]);
                                }}
                                onMouseEnter={() => setIsHovered(true)}
                                onMouseLeave={() => setIsHovered(false)}
                                sx={{
                                    border: `1px solid ${colors.actionColor}`,
                                    height: 40,
                                    color: colors.primaryGray,
                                    p: 1,
                                    width: "100%",
                                    borderRadius: "10px",
                                    "&:hover": {
                                        backgroundColor: colors.actionColor,
                                        cursor: "pointer",
                                        color: "#fff"
                                    }
                                }}
                            >
                                <AddIcon
                                    sx={{
                                        color: "#fff",
                                        fontSize: 18
                                    }}
                                />
                                <Typography sx={{ ml: 1, fontSize: 14, color: "#fff" }}>New Chat</Typography>
                            </Stack>
                        </Box>
                    </Grid> */}

                    <Grid item xs={12}>
                        <Box
                            sx={{
                                display: "flex",
                                position: "relative",
                                flexDirection: "column",
                                // backgroundColor: colors.lightGray,
                                height: "calc(100vh - 64px)",
                                alignItems: "center"
                            }}
                        >
                            {!lastQuestionRef.current ? (
                                // <GPTEmptyScreen />
                                <div className={styles.chatEmptyState}>
                                    <SparkleFilled fontSize={"100px"} primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Chat logo" />
                                    <h1 className={styles.chatEmptyStateTitle}>Crawford Legal AI</h1>
                                </div>
                            ) : (
                                <Box sx={{
                                    height: "80vh", width: "100%", overflow: "auto",
                                    maxWidth: "1028px",
                                }}>
                                    {answers.map((item: any) => {
                                        return <QuesAnsBox item={item} />;
                                    })}
                                    {isLoading && (
                                        <>
                                            <Box
                                                sx={{
                                                    width: "100%",
                                                    height: "auto",
                                                    margin: "10px 0px 10px 0px",
                                                }}
                                            >
                                                <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                    <UserChatMessage message={lastQuestionRef.current} />
                                                    <div className={styles.chatMessageGptMinWidth}>
                                                        <AnswerLoading />
                                                    </div>
                                                </div>
                                            </Box>
                                            <div style={{ paddingLeft: 10 }}>
                                                <AbortControllerUI abortController={abortController} />
                                            </div>
                                        </>
                                    )}
                                    <span ref={messagesEndRef}></span>
                                </Box>
                            )}


                            <TextFieldContainer style={{ marginBottom: 20 }}>
                                <TextField
                                    size="small"
                                    focused
                                    fullWidth
                                    value={chatMessage}
                                    multiline
                                    rows={1}
                                    maxRows={2}
                                    minRows={2}
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            "& fieldset": {
                                                border: "none"
                                            },
                                            "&:hover fieldset": {
                                                border: "none"
                                            },
                                            "&.Mui-focused fieldset": {
                                                border: "none"
                                            }
                                        },

                                        "& .MuiInput-input": {
                                            color: colors.lightGray
                                        }
                                    }}
                                    placeholder={!answers[0] && !isLoading ? "Type your question" : ""}
                                    onChange={(event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => setChatMessage(event.target.value)}
                                    onKeyDown={handleKeyDown}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton onClick={onSubmit} disabled={disableSendBtn()}>
                                                    <Send28Filled primaryFill="rgba(115, 118, 225, 1)" />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        sx: {
                                            color: colors.primaryGray
                                        }
                                    }}
                                />
                            </TextFieldContainer>
                        </Box>
                    </Grid>
                </Grid>
            </Box>

            {/* // Error handler */}
            <ErrorSnackbar showErrorAlert={showErrorAlert} setShowErrorAlert={setShowErrorAlert} />
        </>
    );
};

export default ChatGPT;
