import { useState, useEffect, useContext } from "react";
import styles from "./ClaimSelection.module.css";
import { Box, Button, Grid, Typography, AppBar, Toolbar, CssBaseline } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Text } from "@fluentui/react";
import { ArrowExit20Regular, Chat20Regular } from "@fluentui/react-icons";
import UniversalSearch from "../../components/ClaimDetails/UniversalSearch";
import { IClaimData, all_claims, claimInfo, json_approach_claims, lossInfo, policyInfo, rag_approach_claims } from "../../api/data";
import lens_coveredg from "../../assets/lens_coveredg.png";
import ClaimOutcomeStepper from "../../components/ClaimDetails/ClaimOutcomesStepper";
import { colors } from "../../consts/colors";
import Logo from "../../components/Logo/Logo";
import { useAuth } from "../../context/AuthProvider";
import { ClaimContext } from "../../context/ClaimOutcomeProvider";

const ClaimSelection = () => {
    const location = useLocation();
    const params = new URLSearchParams(location.search);
    const approach = params.get("approach");
    const navigate = useNavigate();

    const data = useContext(ClaimContext);
    const { logout } = useAuth();
    const [selectedOption, setselectedOption] = useState<string>("Claim");
    const [selectedClaimDetails, setSelectedClaimDetails] = useState<IClaimData>();
    const [filteredData, setFilteredData] = useState<IClaimData[]>([]);

    // Resetting claim details
    // useEffect(() => {
    //     data?.setSelectedClaim(0);
    // }, []);

    // filter data
    useEffect(() => {
        if (approach === "json" || approach === "rag" || approach === "coveredg") {
            const newData: IClaimData[] =
                selectedOption === "Claim"
                    ? (approach === "json"
                          ? json_approach_claims
                          : approach === "rag"
                          ? rag_approach_claims
                          : approach === "coveredg"
                          ? all_claims
                          : []
                      ).filter(item => item.claimNo === selectedClaimDetails?.claimNo)
                    : (approach === "json"
                          ? json_approach_claims
                          : approach === "rag"
                          ? rag_approach_claims
                          : approach === "coveredg"
                          ? all_claims
                          : []
                      ).filter(item => item.programNumber === selectedClaimDetails?.programNumber);
            setFilteredData([...newData]);
        } else {
            navigate("/apps");
        }
    }, [selectedClaimDetails]);

    const handleLogout = () => {
        logout();
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%"
            }}
        >
            <div className={styles.container}>
                <div className={styles.topHeader}>
                    <AppBar position="static" sx={{ backgroundColor: colors.primaryDark }}>
                        <Toolbar>
                            <Grid container>
                                <Grid item sm={6} md={3} lg={3} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                                    <Logo />
                                    {/* <img
                                        src={logo}
                                        width={"auto"}
                                        height={45}
                                        style={{
                                            cursor: "pointer"
                                        }}
                                    /> */}
                                </Grid>
                                <Box
                                    sx={{
                                        display: { xs: "none", sm: "none", md: "flex", lg: "flex" }
                                    }}
                                    component={Grid}
                                    item
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    style={{ justifyContent: "center", alignItems: "center" }}
                                >
                                    <UniversalSearch
                                        setselectedOption={setselectedOption}
                                        setSelectedClaimDetails={setSelectedClaimDetails}
                                        selectedOption={selectedOption}
                                        approach={approach}
                                    />
                                </Box>
                                <Grid item sm={6} md={3} lg={3} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                                    <div style={{ display: "flex", alignItems: "center", gap: "6px", cursor: "pointer" }} onClick={() => handleLogout()}>
                                        <ArrowExit20Regular />
                                        <Text style={{ color: "#efefef" }}>{"Logout"}</Text>
                                    </div>
                                </Grid>
                                <Box
                                    sx={{
                                        display: { xs: "flex", sm: "flex", md: "none", lg: "none" },
                                        my: 1
                                    }}
                                    component={Grid}
                                    item
                                    xs={12}
                                    sm={12}
                                    md={6}
                                    lg={6}
                                    style={{ justifyContent: "center", alignItems: "center" }}
                                >
                                    <UniversalSearch
                                        setselectedOption={setselectedOption}
                                        setSelectedClaimDetails={setSelectedClaimDetails}
                                        selectedOption={selectedOption}
                                        approach={approach}
                                    />
                                </Box>
                            </Grid>
                        </Toolbar>
                    </AppBar>
                </div>

                {/* // Claim Box */}
                <Grid item sx={{ px: 5, my: 4 }}>
                    {/* {selectedClaimNumber && <Milestones />} */}
                    {selectedClaimDetails ? (
                        <Grid container style={{ background: "white", padding: 0 }}>
                            <Grid item xs={12} sm={9} md={9} lg={9}>
                                <Grid container>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className={styles.outerBox}>
                                            <Box className={styles.detailBox}>
                                                <div className={styles.detailBoxHeader}>Claim Information</div>
                                                <Grid container style={{ padding: 10 }}>
                                                    {claimInfo.map(item => {
                                                        return (
                                                            <Grid item xs={12} sm={6} md={6} lg={4} key={item.key}>
                                                                <div className={styles.heading}>{item.label}</div>
                                                                <div className={styles.value}>
                                                                    {filteredData[0] && filteredData[0][item.key as keyof IClaimData]
                                                                        ? item.key === "receivedDate" ||
                                                                          item.key === "policyStartDate" ||
                                                                          item.key === "lossDate" ||
                                                                          item.key === "policyExpiryDate"
                                                                            ? new Date(filteredData[0][item.key as keyof IClaimData]).toLocaleDateString()
                                                                            : filteredData[0][item.key as keyof IClaimData]?.toString()
                                                                        : "N/A"}
                                                                </div>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className={styles.outerBox}>
                                            <Box className={styles.detailBox}>
                                                <div className={styles.detailBoxHeader}>Loss Information</div>
                                                <Grid container style={{ padding: 10 }}>
                                                    {lossInfo.map(item => {
                                                        return (
                                                            <Grid item xs={12} sm={6} md={6} lg={4} key={item.key}>
                                                                <div className={styles.heading}>{item.label}</div>
                                                                <div className={styles.value}>
                                                                    {filteredData[0] && filteredData[0][item.key as keyof IClaimData]
                                                                        ? item.key === "receivedDate" ||
                                                                          item.key === "policyStartDate" ||
                                                                          item.key === "lossDate" ||
                                                                          item.key === "policyExpiryDate"
                                                                            ? filteredData[0][item.key as keyof IClaimData] !== "N/A"
                                                                                ? new Date(filteredData[0][item.key as keyof IClaimData]).toLocaleDateString()
                                                                                : "N/A"
                                                                            : item.key === "perilTypeID"
                                                                            ? filteredData[0][item.key as keyof IClaimData] === "PTWR"
                                                                                ? "Water"
                                                                                : filteredData[0][item.key as keyof IClaimData] === "PTFR"
                                                                                ? "Fire"
                                                                                : filteredData[0][item.key as keyof IClaimData] === "PTFL"
                                                                                ? "Flood"
                                                                                : filteredData[0][item.key as keyof IClaimData] === "PTTF"
                                                                                ? "Theft"
                                                                                : filteredData[0][item.key as keyof IClaimData] === "PTVL"
                                                                                ? "Vehicle"
                                                                                : filteredData[0][item.key as keyof IClaimData] === "PTWD"
                                                                                ? "Wind/Hail"
                                                                                : "N/A"
                                                                            : item.key === "propertyTypeID"
                                                                            ? filteredData[0][item.key as keyof IClaimData] === "PCOM"
                                                                                ? "Commercial"
                                                                                : "Residential"
                                                                            : filteredData[0][item.key as keyof IClaimData]
                                                                        : "N/A"}
                                                                </div>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12}>
                                        <Box className={styles.outerBox}>
                                            <Box className={styles.detailBox}>
                                                <div className={styles.detailBoxHeader}>Policy Information</div>
                                                <Grid container style={{ padding: 10 }}>
                                                    {policyInfo.map(item => {
                                                        return (
                                                            <Grid item xs={12} sm={6} md={3} lg={3} key={item.key}>
                                                                <div className={styles.heading}>{item.label}</div>
                                                                <div className={styles.value}>
                                                                    {filteredData[0] && filteredData[0][item.key as keyof IClaimData]
                                                                        ? item.key === "receivedDate" ||
                                                                          item.key === "policyStartDate" ||
                                                                          item.key === "lossDate" ||
                                                                          item.key === "policyExpiryDate"
                                                                            ? new Date(filteredData[0][item.key as keyof IClaimData]).toLocaleDateString()
                                                                            : filteredData[0][item.key as keyof IClaimData]?.toString()
                                                                        : "N/A"}
                                                                </div>
                                                            </Grid>
                                                        );
                                                    })}
                                                </Grid>
                                            </Box>
                                        </Box>
                                    </Grid>
                                    <Grid item xs={12} sm={12} md={12} lg={12} sx={{ mb: 2, mt: 1 }} style={{ display: "flex", justifyContent: "center" }}>
                                        <Button
                                            variant={"contained"}
                                            style={{ background: "#204488", textTransform: "none", marginRight: 10 }}
                                            onClick={() =>
                                                navigate("/policy-chat/version-1", {
                                                    state: {
                                                        selectedClaimNumber: selectedClaimDetails["claimNo"],
                                                        selectedProgram: selectedClaimDetails["programNumber"],
                                                        operator: approach
                                                    }
                                                })
                                            }
                                        >
                                            <Chat20Regular style={{ marginRight: 10 }} /> Chat with Policy
                                        </Button>
                                        <Button
                                            variant={"contained"}
                                            style={{ background: "#204488", textTransform: "none", marginLeft: 10 }}
                                            onClick={() => false}
                                        >
                                            <Chat20Regular style={{ marginRight: 10 }} /> Chat with Document
                                        </Button>
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={3} md={3} lg={3}>
                                <Box className={styles.outerBox}>
                                    <Box className={styles.detailBox}>
                                        <div className={styles.detailBoxHeader}>Outcomes</div>
                                        <Grid container style={{ padding: 5 }}>
                                            <ClaimOutcomeStepper />
                                        </Grid>
                                    </Box>
                                </Box>
                            </Grid>
                        </Grid>
                    ) : (
                        <Box style={{ height: "70vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <div>
                                <div style={{ display: "flex", justifyContent: "center" }}>
                                    <img
                                        src={lens_coveredg}
                                        width={"auto"}
                                        height={250}
                                        // style={{
                                        //     cursor: "pointer",
                                        //     marginBottom: 50
                                        // }}
                                    />
                                </div>
                                <Typography style={{ fontSize: 30, color: "#555", fontWeight: "bold", paddingTop: 50 }}>
                                    AI for claim coverage assistance
                                </Typography>
                            </div>
                        </Box>
                    )}
                </Grid>
            </div>
        </div>
    );
};

export default ClaimSelection;
