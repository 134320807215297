export const enum Approaches {
    ChatReadApproach = "rcr",
    DatabaseChat = "dbc",
    Langchain = "pdflang",
    PolicyGPT = "wholedoc",
    JSONChat = "json",
    RAGChat = "rag"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    error?: string;
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type Response = {
    answer: string;
    thoughts: string;
    error?: string;
};

export type ResponseWithDataPoints = {
    answer: string;
    data_points: any;
    error?: string;
};

export type ResponseWithReferences = {
    content: string;
    references: any;
    role: string;
    thoughts?: string;
    error?: string;
};

export type SearchedClaim = {
    claimNo: string;
    policyStartDate: string | Date;
    policyExpiryDate: string | Date;
    programNumber: string;
    carrierName: string;
};

export type SearchedClient = {
    clientName: string;
    totalClaims: string | number;
};

export type OutcomeObj = {
    title: string;
    description: string;
    documentation_url: string;
};

export type ClaimDetailObj = {
    claimNo: string;
    receivedDate: any;
    perilTypeID: string;
    carrierName: string;
    remark: string;
    bucketStatus: string;
    modifiedDate: Date | string;
    assignee: string;
    assigneeID: string;
    policyNo: string;
    policyStartDate: any;
    policyExpiryDate: any;
    claimSourceTypeID: string;
    sourceReference: string;
    isDuplicateClaim: boolean;
    lossDate: any;
    catCode: string;
    claimRefNo: string;
    clientGroup: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    carrierReferenceNumber: string;
    service: string;
    assignor: string;
    policyHolderName: string;
    policyHolderPhone: string;
    policyHolderAddress: string;
    policyHolderCity: string;
    policyHolderState: string;
    policyHolderCountry: string;
    policyHolderZip: string;
    reason: string;
    latitude: string;
    longitude: string;
    lastModifiedBy: string;
    isMyClaim: boolean;
    programNumber: string;
    isMyteam: boolean;
    propertyTypeID: string;
    pendingReason: any;
    isXactClaimUpdate: boolean;
    xactClaimUpdateCount: string;
    xactClaimLastUpdatedDateTime: string;
};

export type DisclaimerObj = {
    html: string;
};

export interface HistoryItem {
    id: string;
    title: string;
    create_time: Date | string;
    isArchived: boolean;
    updatetime: Date | string;
}
[];

export interface IPolicies {
    policyID: string;
    policyName: string;
}

export interface IClaimDocument {
    documentName: string;
    documentPath: string;
    category: string;
    uploadedAt: string;
    processedAt: string;
    status: string;
}
