import { cosineSearchData } from "./cosine-similarity";

interface WordData {
    text: string;
    spanIdx: number;
    wordIdx: number;
}

export const multiHighlight = (textToHighlight: string, pageNumber: number) => {
    // const spans = document.querySelectorAll(`div[data-page-number='${pageNumber}'] .react-pdf__Page__textContent.textLayer span`);
    const spansCurrentPage = document.querySelectorAll(`div[data-page-number='${pageNumber}'] .react-pdf__Page__textContent.textLayer span`);
    const spansNewPage = document.querySelectorAll(`div[data-page-number='${pageNumber + 1}'] .react-pdf__Page__textContent.textLayer span`);

    // Convert NodeList to Array
    const spansCurrentPageArray = Array.from(spansCurrentPage);
    const spansNewPageArray = Array.from(spansNewPage);

    // Combine the spans from pages for multihighlight check
    const spans = [...spansCurrentPageArray, ...spansNewPageArray];
    const words: WordData[] = [];

    spans.forEach(span => {
        if (span.classList.contains("markedContent")) {
            const parent: any = span as HTMLElement;
            if (parent.hasChildNodes()) {
                const range = document.createRange();
                range.selectNodeContents(parent);
                const fragment = range.extractContents();
                parent.parentNode.insertBefore(fragment, parent);
                parent.parentNode.removeChild(parent);
            }
        }
    });
    spans.forEach((span, spanIdx) => {
        const htmlSpan = span as HTMLElement;
        const spanWords = htmlSpan.textContent || "";

        spanWords.split(" ").map((text, wordIdx) => {
            words.push({ text, spanIdx, wordIdx });
        });
    });

    let searchString = textToHighlight;

    searchString = searchString.replace(/\s{2,}/g, " ");
    searchString = searchString.replace(/\t/g, " ");
    searchString = searchString
        .toString()
        .trim()
        .replace(/(\r\n|\n|\r)/g, " ")
        .replace(/^[0-9\s.]+/, "")
        .replace(/Â/g, "");
    const searchWords = searchString.split(" ");
    const lenSearchString = searchWords.length;

    if (!lenSearchString) {
        return;
    }

    const firstWord = searchWords[0] === "|" || searchWords[0] === " " ? searchWords[1] : searchWords[0];
    if (!firstWord) {
        return;
    }

    // active search -> Direct
    const searchData = generateDirectSearchData(firstWord, words, lenSearchString);
    // const searchData = generateFuzzySearchData(words, lenSearchString);

    const result = cosineSearchData(searchData, searchString);
    if (result && result.length > 0) {
        const res = Math.max(...result.map((o: any) => o.score!));
        const resIndex = result.findIndex((val: any) => val.score === res);
        const searchResult = result[resIndex]?.item;
        const startSpan = searchResult?.startSpan || 0;
        const endSpan = searchResult?.endSpan || 0;
        const startWordIdx = searchResult?.startWordIdx || 0;
        const endWordIdx = searchResult?.endWordIdx || 0;
        for (let i = startSpan; i < endSpan + 1; i++) {
            const spanToHighlight = spans[i] as HTMLElement;

            if (i == startSpan) {
                if (startWordIdx === 0) {
                    highlightHtmlElement(spanToHighlight);
                } else {
                    partialHighlight(startWordIdx, spanToHighlight, DIRECTION.START);
                }
            } else if (i == endSpan) {
                if (endWordIdx === 0) {
                    return;
                } else {
                    partialHighlight(endWordIdx, spanToHighlight, DIRECTION.END);
                }
            } else {
                highlightHtmlElement(spanToHighlight);
            }
        }
    }
    return true;
};

const HIGHLIGHT_CLASSNAME = "highlighted_pdf_text";

const highlightHtmlElement = (div: HTMLElement) => {
    const text = div.textContent || "";
    const newSpan = document.createElement("span");
    newSpan.className = HIGHLIGHT_CLASSNAME;
    newSpan.innerText = text;
    div.innerText = "";
    div.appendChild(newSpan);
};

enum DIRECTION {
    START,
    END
}
const partialHighlight = (idx: number, span: HTMLElement, direction = DIRECTION.START) => {
    const text = span.textContent;
    if (!text) {
        return;
    }
    const test = text.split(" ")[idx - 1] || "";
    const substringToHighlight = test;

    span.textContent = "";

    const parts = text.split(substringToHighlight);

    parts.forEach((part, index) => {
        if (direction === DIRECTION.START) {
            if (index == 0) {
                span.appendChild(document.createTextNode(part));
            } else {
                span.appendChild(document.createTextNode(test));
                const highlightSpan = document.createElement("span");
                highlightSpan.className = HIGHLIGHT_CLASSNAME;
                highlightSpan.textContent = part;
                span.appendChild(highlightSpan);
            }
        }

        if (direction === DIRECTION.END) {
            if (index == 0) {
                const highlightSpan = document.createElement("span");
                highlightSpan.className = HIGHLIGHT_CLASSNAME;
                highlightSpan.textContent = part;
                span.appendChild(highlightSpan);
                span.appendChild(document.createTextNode(part));
            } else {
                span.appendChild(document.createTextNode(test));
                span.appendChild(document.createTextNode(part));
            }
        }
    });
};

interface SearchStrings {
    text: string;
    startSpan: number;
    endSpan: number;
    startWordIdx: number;
    endWordIdx: number;
}

function generateDirectSearchData(startString: string, words: WordData[], n: number): SearchStrings[] {
    const searchStrings: SearchStrings[] = [];
    let textArr: string[] = [];
    for (let i = 0; i < words.length; i++) {
        if (words[i]?.text === startString) {
            textArr.push(words[i]?.text);
            let endIndex = i + n < words.length ? i + n : words.length - 1;
            for (let j = 1; j <= endIndex - i; j++) {
                if (words[j + i]?.text === "") {
                    endIndex = endIndex + 1;
                } else {
                    textArr.push(words[j + i]?.text);
                }
            }
            const startSpan = words[i]?.spanIdx || 0;
            const endSpan = words[endIndex - 1]?.spanIdx || 0;
            const startWordIdx = words[i]?.wordIdx || 0;
            const endWordIdx = words[i + n]?.wordIdx || 0;
            searchStrings.push({
                text: textArr.join(" "),
                startSpan,
                endSpan,
                startWordIdx,
                endWordIdx
            });
        }
        textArr = [];
    }
    return searchStrings;
}
