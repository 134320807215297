import React from "react";
import ReactDOM from "react-dom/client";
import { HashRouter, Routes, Route, Navigate, Outlet } from "react-router-dom";
import { initializeIcons } from "@fluentui/react";

import "./index.css";

// import Layout from "./pages/layout/Layout";
// import NoPage from "./pages/NoPage";
// import OneShot from "./pages/oneshot/OneShot";
// import Chat from "./pages/chat/Chat";
import AppSelection from "./pages/app-selection/app-selection";
import ChatGPT from "./pages/chat-gpt/chat-gpt";
import CodexChat from "./pages/codex/CodexChat";
import Langchain from "./pages/langchain/Langchain";
import { useAuth } from "./context/AuthProvider";
import MainLogin from "./pages/authentication/MainLogin";
import Auth0ProviderWithChildren from "./Auth0Provider";
import AuthProvider from "./context/AuthProvider";
import ClaimSelection from "./pages/claim-selection/ClaimSelection";
import PolicyChat from "./pages/policy-chat/PolicyChat";
import Loader from "./components/Loader/Loader";
import PolicyChatNewer from "./pages/versionB/policy-chat/PolicyChat";
import ClientAndClaimProvider from "./context/versionB/ClientAndClaimProvider";
import Maintainance from "./pages/Maintainance";

initializeIcons();

const PrivateRoute = () => {
    const { isAuthenticated } = useAuth();
    if (!isAuthenticated) return <Navigate to="login" replace />;

    return <Outlet />;
};

export default function App() {
    const { isAuthenticated, isLoading } = useAuth();
    const isUnderMaintainance = false;
    if (isLoading) return <Loader />;
    return (
        <HashRouter>
            <Routes>
                <Route
                    path="/"
                    element={
                        isUnderMaintainance ? (
                            <Navigate to="/under-maintainance" />
                        ) : !isAuthenticated ? (
                            <Navigate to="/login" />
                        ) : (
                            <Navigate to="/policy-chat/version-2" />
                        )
                    }
                />
                {!isAuthenticated && <Route path="/login" element={<MainLogin />} />}
                <Route path="/under-maintainance" element={<Maintainance />} />
                <Route element={<PrivateRoute />}>
                    <Route path="/apps" element={<AppSelection />} />
                    <Route path="/codex" element={<CodexChat />} />
                    <Route path="/general" element={<ChatGPT />} />
                    <Route path="/langchain" element={<Langchain />} />
                    <Route path="/details" element={<ClaimSelection />} />
                    <Route path="/policy-chat/version-1" element={<PolicyChat />} />
                    <Route
                        path="/policy-chat/version-2"
                        element={
                            <ClientAndClaimProvider>
                                <PolicyChatNewer />
                            </ClientAndClaimProvider>
                        }
                    />
                    <Route
                        path="/policy-chat/version-2/development"
                        element={
                            <ClientAndClaimProvider>
                                <PolicyChatNewer />
                            </ClientAndClaimProvider>
                        }
                    />
                    <Route
                        path="/policy-chat/version-2/development/threshold_value"
                        element={
                            <ClientAndClaimProvider>
                                <PolicyChatNewer />
                            </ClientAndClaimProvider>
                        }
                    />
                </Route>
                <Route
                    path="*"
                    element={
                        isUnderMaintainance ? (
                            <Navigate to="/under-maintainance" />
                        ) : !isAuthenticated ? (
                            <Navigate to="/login" />
                        ) : (
                            <Navigate to="/policy-chat/version-2" />
                        )
                    }
                />
            </Routes>
        </HashRouter>
    );
}

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <React.StrictMode>
        <Auth0ProviderWithChildren>
            <AuthProvider>
                <App />
            </AuthProvider>
        </Auth0ProviderWithChildren>
    </React.StrictMode>
);
