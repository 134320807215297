import React, { useState } from "react";
import { styled } from "@mui/material/styles";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { DocumentMultiple24Regular } from "@fluentui/react-icons";
import CitationDrawer from "./CitationDrawer";

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}));

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)"
}));

interface IProps {
    data: any[];
}
export default function DocumentsDrawer({ data }: IProps) {
    const policiesDocuments: string[] =
        data[0] && data[0]["programNumber"] === "US-Client-0001"
            ? ["US-Client.pdf", "HO0003 (05-11).pdf", "EH1026 (05-17).pdf", "EH1092TX (05-17).pdf"]
            : data[0] && data[0]["programNumber"] === "UK-TPA-Client-0001"
            ? ["UK-TPA-Client.pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0001"
            ? ["Declarations SAMPLE TX.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0002"
            ? ["Decpage1.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0003"
            ? ["Decpage2.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0004"
            ? ["Decpage3.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0005"
            ? ["Decpage4.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0006"
            ? ["Decpage5.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0007"
            ? ["Decpage6.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Canada_0001"
            ? ["HOMEOWNERS COMPREHENSIVE FORM.pdf", "Insurance contract.pdf"]
            : data[0] && data[0]["programNumber"] === "Openly_0001"
            ? ["Openly Base Policy.pdf", "Declaration Openly.pdf", "1503.pdf", "1504.pdf", "1505.pdf", "1506.pdf", "1507.pdf", "1508.pdf", "1509.pdf", "1510.pdf", "1511.pdf", "1512.pdf", "1513.pdf", "1514.pdf", "1515.pdf", "1516.pdf", "1517.pdf", "1518.pdf", "1519.pdf", "1520.pdf", "1521.pdf"]
            : [];
    const inspectionDocuments: string[] = [];
    const others: string[] = [];
    const [isOpen, setIsOpen] = useState(false);
    const [expanded, setExpanded] = React.useState<string | false>("");
    const [selectedFile, setSelectedFile] = useState("");

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion expanded={expanded === "panel1"} onChange={handleChange("panel1")} disabled={policiesDocuments[0] ? false : true}>
                <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                    <Typography style={{ fontSize: 14 }}>Policies</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {policiesDocuments.map(item => {
                        return (
                            <Grid
                                container
                                style={boxCSS}
                                key={item}
                                onClick={() => {
                                    setSelectedFile(item);
                                    setIsOpen(true);
                                }}
                            >
                                <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <DocumentMultiple24Regular style={headingCSS} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography style={headingCSS}>{item}</Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel2"} onChange={handleChange("panel2")} disabled={inspectionDocuments[0] ? false : true}>
                <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                    <Typography style={{ fontSize: 14 }}>Inspection Reports</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {inspectionDocuments.map(item => {
                        return (
                            <Grid
                                container
                                style={boxCSS}
                                key={item}
                                onClick={() => {
                                    setSelectedFile(item);
                                    setIsOpen(true);
                                }}
                            >
                                <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <DocumentMultiple24Regular style={headingCSS} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography style={headingCSS}>{item}</Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion expanded={expanded === "panel3"} onChange={handleChange("panel3")} disabled={others[0] ? false : true}>
                <AccordionSummary aria-controls="panel3d-content" id="panel3d-header">
                    <Typography style={{ fontSize: 14 }}>Others</Typography>
                </AccordionSummary>
                <AccordionDetails>
                    {others.map(item => {
                        return (
                            <Grid
                                container
                                style={boxCSS}
                                key={item}
                                onClick={() => {
                                    setSelectedFile(item);
                                    setIsOpen(true);
                                }}
                            >
                                <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <DocumentMultiple24Regular style={headingCSS} />
                                </Grid>
                                <Grid item xs={10}>
                                    <Typography style={headingCSS}>{item}</Typography>
                                </Grid>
                            </Grid>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <CitationDrawer isOpen={isOpen} setIsOpen={setIsOpen} fileName={selectedFile} page={1} />
        </div>
    );
}

const headingCSS = {
    color: "#204486",
    fontWeight: "600",
    fontSize: "12px"
};

const boxCSS = {
    height: 50,
    border: "1px solid #eee",
    width: "100%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-around",
    marginBottom: 10
};
