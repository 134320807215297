import { Stack } from "@fluentui/react";
import { animated, useSpring } from "@react-spring/web";
import coveredgIcon from "../../assets/ai-logo.svg";
import loading_gif from "../../assets/loading_gif.gif";

import styles from "./Answer.module.css";

export const AnswerLoading = () => {
    const animatedStyles = useSpring({
        from: { opacity: 0 },
        to: { opacity: 1 }
    });

    return (
        <animated.div style={{ ...animatedStyles }}>
            <Stack className={styles.answerContainer}>
                <div>
                    <img src={coveredgIcon} width={"auto"} height={30} />
                </div>
                <Stack.Item>
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <p className={styles.answerText} style={{ padding: 0 }}>
                            Generating Response
                            {/* <span className={styles.loadingdots} /> */}
                        </p>
                        <img src={loading_gif} width={"auto"} height={50} />
                    </div>
                </Stack.Item>
            </Stack>
        </animated.div>
    );
};
