import { Grid, Typography } from "@mui/material";
import styles from "./UserQueryGenerating.module.css";
import { theme } from "../../../consts/versionB/consts/theme";
import coveredgIcon from "../../../assets/ai-logo.svg";
import loading_gif from "../../../assets/loading_gif.gif";
interface IProps {
    message: string;
    user: string;
}

const UserQueryGenerating = ({ message, user }: IProps) => {
    return (
        <>
            <Grid container>
                <Grid item xs={1} sm={0.6}>
                    <div
                        className={styles.userAvatar}
                        style={{
                            background: theme.palette.primaryDark,
                            color: theme.palette.white
                        }}
                    >
                        {user[0]}
                    </div>
                </Grid>
                <Grid item xs={11} sm={11.4}>
                    <Typography variant="body1" component={"p"} sx={{ fontWeight: "bold" }}>
                        You
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={0.6}></Grid>
                <Grid item xs={11} sm={11.4}>
                    <span style={{ whiteSpace: "pre-line" }}>{message}</span>
                </Grid>
            </Grid>

            <Grid container sx={{ mt: 2 }}>
                <Grid item xs={1} sm={0.6}>
                    <img src={coveredgIcon} width={"auto"} height={22} />
                </Grid>
                <Grid item xs={11} sm={11.4}>
                    <Typography variant="body1" component={"p"} sx={{ fontWeight: "bold" }}>
                        CoverageAI
                    </Typography>
                </Grid>
                <Grid item xs={1} sm={0.6} sx={{ display: "flex", alignItems: "center" }}></Grid>
                <Grid item xs={11} sm={11.4}>
                    <div className={styles.resultWrapper}>
                        {/* <div className={styles.circle} /> */}
                        <Typography variant="body1" component={"p"} sx={{ fontSize: 12, color: theme.palette.textGray }}>
                            Generating Response
                        </Typography>
                        <img src={loading_gif} width={"auto"} height={50} />
                    </div>
                </Grid>
            </Grid>
        </>
    );
};

export default UserQueryGenerating;
