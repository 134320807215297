import { dummy_res } from "./dummy_data";
import { AskResponse, ChatRequest, Response, ResponseWithDataPoints, ResponseWithReferences } from "./models";

// Chat GPT API
export async function chatWithGPTApi(options: ChatRequest, signal: AbortSignal): Promise<AskResponse> {
    const response = await fetch("/chatwithgpt", {
        method: "POST",
        signal,
        headers: { "Content-Type": "application/json" },
        body: JSON.stringify({
            history: options.history,
            approach: "rcr",
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

// ChatWithCodex API
export async function chatWithCodex(options: { approach: string; question: string; db_name: string }, signal: AbortSignal): Promise<Response> {
    const response = await fetch("/dbc", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            question: options.question,
            db_name: options.db_name
        })
    });

    const parsedResponse: Response = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

// Query with Langchain
export async function queryWithLangchain(options: { approach: string; query: string }, signal: AbortSignal): Promise<ResponseWithDataPoints> {
    const response = await fetch("/pdfLangchain", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            query: options.query
        })
    });

    const parsedResponse: ResponseWithDataPoints = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

// Policy Chat API
export async function policyChatAPI(
    options: { approach: string; query: string; history: Object[]; policy: string },
    signal: AbortSignal
): Promise<ResponseWithReferences> {
    const response = await fetch("/policygpt", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            query: options.query,
            history: options.history,
            policy: options.policy
        })
    });

    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

// JSON Chat API
export async function jsonChatAPI(
    options: { approach: string; query: string; history: Object[]; policy: string },
    signal: AbortSignal
): Promise<ResponseWithReferences> {
    const response = await fetch("/json", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            query: options.query,
            history: options.history,
            policy: options.policy
        })
    });
    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

// RAG Chat API
export async function ragChatAPI(
    options: { approach: string; query: string; history: Object[]; policy: string; optimizationVersion: string },
    signal: AbortSignal
): Promise<ResponseWithReferences> {
    const response = await fetch("/rag", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            query: options.query,
            history: options.history,
            policy: options.policy,
            optimizationVersion: options.optimizationVersion
        })
    });
    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}
