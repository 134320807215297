export const searched_claims = [
    {
        claimNo: "123132312",
        policyStartDate: "2022-02-16T00:00:00",
        policyExpiryDate: "2024-06-24T00:00:00",
        programNumber: "UK-TPA-Client-0001",
        carrierName: "UK-TPA-Client"
    },
    {
        claimNo: "6453738457",
        policyStartDate: "2022-11-09T00:00:00",
        policyExpiryDate: "2024-01-29T00:00:00",
        programNumber: "UK-TPA-Client-0001",
        carrierName: "UK-TPA-Client"
    },
    {
        claimNo: "CC_00366374",
        policyStartDate: "2022-12-14T00:00:00",
        policyExpiryDate: "2024-03-25T00:00:00",
        programNumber: "UK-TPA-Client-0001",
        carrierName: "UK-TPA-Client"
    },
    {
        claimNo: "54628746",
        policyStartDate: "2022-02-15T00:00:00",
        policyExpiryDate: "2023-12-18T00:00:00",
        programNumber: "US-Client-0001",
        carrierName: "US-Client"
    },
    {
        claimNo: "7454735243",
        policyStartDate: "2023-02-01T00:00:00",
        policyExpiryDate: "2024-02-01T00:00:00",
        programNumber: "US-Client-0001",
        carrierName: "US-Client"
    },
    {
        claimNo: "846472834",
        policyStartDate: "2022-04-20T00:00:00",
        policyExpiryDate: "2024-06-25T00:00:00",
        programNumber: "US-Client-0001",
        carrierName: "US-Client"
    },
    {
        claimNo: "098098098",
        policyStartDate: "2022-04-20T00:00:00",
        policyExpiryDate: "2024-06-25T00:00:00",
        programNumber: "JSON-Client-001",
        carrierName: "US-Client"
    },
    {
        claimNo: "098098097",
        policyStartDate: "2022-04-20T00:00:00",
        policyExpiryDate: "2024-06-25T00:00:00",
        programNumber: "RAG-Client-001",
        carrierName: "UK-TPA-Client"
    }
];

export const searched_clients = [
    {
        clientName: "Zurich",
        totalClaims: "12"
    },
    {
        clientName: "GetSafe",
        totalClaims: "3"
    },
    {
        clientName: "Kemper",
        totalClaims: "5"
    }
];

export const dummy_outcomes = [
    {
        title: "Fully Covered",
        description:
            "The claim is explicitly included in the HO3 policy without limitations. The policyholder gets full reimbursement or repair/replacement, minus any deductible",
        documentation_url: ""
    },
    {
        title: "Partially Covered",
        description:
            "The claim is covered but has certain restrictions. Limitations could arise from sub-limits, policy conditions, or deductibles. The policyholder receives a portion of the claim's value.",
        documentation_url: ""
    },
    {
        title: "Not Covered",
        description: "The claim isn't included or addressed in the HO3 policy, so there's no reimbursement or benefit for the claim.",
        documentation_url: ""
    },
    {
        title: "Need additional information to assess Coverage",
        description:
            "This status is the initial step in the claim assessment process, applied when the information available is either insufficient or too ambiguous to determine coverage. It is a preliminary stage, necessitating further investigation or information gathering.",
        documentation_url: ""
    }
];

export const dummy_res = {
    content:
        "Summary:\n- Fire Damage: Covered\n- Damage to Wooden Floor: Covered\n\n1. The policy provides coverage for fire damage<cite>101096</cite>.\n2. The wooden floor installed by the customer is considered part of the contents of the building, and the policy provides coverage for contents<cite>101047</cite>.\n3. The amount of insurance for this damage is £7,785<cite>201011</cite>.\n4. The deductible for this claim is £250<cite>201013</cite>.\n5. However, the policy includes a condition that all sump boxes, filters, traps, and grease removal devices must be cleaned at least once every week and all extraction systems including flues, hoods, canopies, extraction motors, fans, and the entire length of any ducting must be cleaned at least once every twelve months<cite>301002</cite>.",
    references: {
        "101002": {
            page: "2",
            path: "Policy Wording4.pdf",
            text: ""
        },
        "101046": {
            page: "10",
            path: "Policy Wording4.pdf",
            text: " Certain words in this policy have special meanings. These words and their meanings are detailed in this section and apply wherever  we  have printed them in bold throughout.  These definitions apply to the entire policy excluding Section J – Legal expenses. However, certain words have special meanings that only apply to a particular section of this policy. These are stated at the beginning of the relevant section as special definitions and will apply in that section wherever the defined words are shown in bold italics. "
        },
        "101047": {
            page: "10",
            path: "Policy Wording4.pdf",
            text: "Any single, continuous or repeated act of fraud or dishonesty. "
        },
        "101048": {
            page: "10",
            path: "Policy Wording4.pdf",
            text: " Those areas immediately adjacent to the  buildings  of the  premises  for which you have a valid Pavement Permission under section 115 E of the Highways Act 1980. "
        },
        "101049": {
            page: "10",
            path: "Policy Wording4.pdf",
            text: "Death, bodily injury, illness or disease including medically recognised psychiatric illness. "
        },
        "101050": {
            page: "10",
            path: "Policy Wording4.pdf",
            text: " The electrical or mechanical failure of  equipment  arising from internal causes which requires repair or replacement to enable normal operation to continue. "
        },
        "101051": {
            page: "10",
            path: "Policy Wording4.pdf",
            text: " The buildings of the  premises  for which  you  are legally responsible including residential accommodation and outbuildings used in connection with the  business  or for domestic purposes and including: \n a)  landlord’s fixtures and fittings up to the amount stated in the schedule   b)  extensions, annexes, canopies, fixed signs, gangways, conveniences, lamp posts and street furniture   c)  walls, gates and fences   d)  foundations   e)  drains, sewers, ducting, cables, wires and associated control gear and accessories on the  premises  and extending to the public mains    f)  adjoining and specifically associated yards, car parks, roads, pavements and forecourts all constructed of solid materials   g)  shop front up to the amount stated in the schedule.  \nExcluding landlords’ contents. "
        },
        "101052": {
            page: "10",
            path: "Policy Wording4.pdf",
            text: "The business stated in the schedule and including: \n a)  maintenance of property and premises owned or occupied by  you    b)  the provision and management of canteen, social, sports and welfare organisations for the benefit of  employees    c)  first aid, security, fire and ambulance service   d)  private work carried out by an  employee  for any director or senior executive of the  business    e)  your  attendance at or participation in exhibitions, trade fairs and conferences    f)  provision of charitable activities advised to  us  and agreed by  us  in writing.   "
        },
        "101053": {
            page: "10",
            path: "Policy Wording4.pdf",
            text: " Loss resulting from interruption of or interference with the  business  carried on by  you  at the  premises  in consequence of  damage  to property used by  you  at the  premises  for the purpose of the  business . "
        },
        "101054": {
            page: "10",
            path: "Policy Wording4.pdf",
            text: " Any person in  business  with  you  under the terms of a partnership agreement whether express or implied under legislation. "
        },
        "101055": {
            page: "11",
            path: "Policy Wording4.pdf",
            text: "Any disease which can be transmitted by means of any substance or agent from any organism to another organism where: \n a)  the substance or agent includes, but is not limited to, a virus, bacterium, parasite or other organism or any variation thereof, whether deemed living or not; and   b)  the method of transmission, whether direct or indirect, includes but is not limited to, airborne transmission, bodily fluid transmission, transmission from or to any surface or object, solid, liquid or gas or between organisms; and   c)  the disease, substance or agent can cause or threaten damage to human health or human welfare or can cause or threaten damage to, deterioration of, loss of value of, marketability of or loss of use of property.  "
        },
        "101056": {
            page: "11",
            path: "Policy Wording4.pdf",
            text: "Computer hardware and its peripheral devices used for electronic processing, communication and storage of data. "
        },
        "101057": {
            page: "11",
            path: "Policy Wording4.pdf",
            text: " Trade fixtures and fittings, machinery,  equipment  and any other contents including: \n a)  the shop front and if fixed to the  buildings , any external signs, fitments and blinds    b)  any telephone installation, gas or electricity meter   c)  documents, transparencies, manuscripts and business books but only for their value as stationery plus the cost of clerical labour necessary to reproduce them   d)  computer and electronic equipment systems records but only for the cost of the materials and the clerical labour and computer time necessary to reproduce them up to £10,000   e)  tenants improvements and decorations   all contained in or on the  buildings  at the  premises  and belonging to  you  or for which  you  are responsible.    Excluding:   i)  stock   ii)  glass in the shop front   iii)  landlord’s fixtures and fittings   iv)  vehicles licensed for road use and their accessories   v)  livestock   vi)  deeds, bonds, bills of exchange, promissory notes, securities, medals, coins or stamps forming part of a collection   vii)  explosives   viii)  money   ix)  property more specifically insured   x)  jewellery   xi)  contents in the open.  "
        },
        "101058": {
            page: "11",
            path: "Policy Wording4.pdf",
            text: "Physical loss, destruction or damage. "
        },
        "101059": {
            page: "11",
            path: "Policy Wording4.pdf",
            text: "Any computer or data processing equipment or media or microchip or integrated circuit or any similar device or any computer software or computer firmware. "
        },
        "101060": {
            page: "11",
            path: "Policy Wording4.pdf",
            text: "In law or as a matter of fact. "
        },
        "101061": {
            page: "11",
            path: "Policy Wording4.pdf",
            text: " Your  assessment of the cost of  reinstatement  of the property insured at the level of costs applying at the start of the period of insurance (ignoring inflationary factors which may operate subsequently) together with due allowance for: \n a)  additional cost of  reinstatement  to comply with European Union and public authority requirements    b)  professional fees   c)  debris removal costs.  "
        },
        "101062": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: "Fire, lightning, explosion, aircraft or other aerial devices or articles dropped from them, riot, civil commotion, strikers, locked-out workers, persons taking part in labour disturbances, malicious persons, theft or attempted theft, earthquake, storm, flood, escape of water from any tank, apparatus or pipe or impact by any vehicle or by goods falling from them or by any animal. "
        },
        "101063": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: "Any actions or instructions constructed or generated with the ability to damage, interfere with or otherwise affect the availability of networks, network services, network connectivity or information systems. Denial of service attacks include but are not limited to the: \n a)  generation of excess traffic into network addresses   b)  exploitation of system or network weaknesses   c)  generation of excess or non-genuine traffic between and amongst networks.  "
        },
        "101064": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: " Any natural person under a contract of service or apprenticeship with  you  which will be deemed to include: \n a)  self-employed persons   b)  persons under work experience schemes   c)  any person hired or borrowed by  you  from another employer    d)  working partners or proprietors   e)  any officer or member of the organisations who constitute the  business    f)  non-executive directors   g)  employees whilst engaged in industry Trade Association committees duties  \n whilst under  your  control and supervision and working for  you  in connection with the  business . "
        },
        "101065": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: " Electrically and mechanically powered machinery and equipment, including  computers  forming part of the  contents . "
        },
        "101066": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: " The amount stated in this policy, the schedule or any endorsement for which  you  will be responsible and which will be deducted from any payment under this policy after all other terms and conditions have been applied. "
        },
        "101067": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: " The upward movement of the ground beneath the  buildings  as a result of the soil expanding. "
        },
        "101068": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: " Unauthorised access to any  computer  or other  equipment  or component or system or item which processes, stores, transmits, retrieves or receives data whether  your  property or not. "
        },
        "101069": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: "The sudden movement of soil on a slope or the gradual creep of a slope over time. "
        },
        "101070": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: "Permanent and total loss of sight which will be deemed to have occurred: \n a)  in both eyes when the condition is shown to  our  satisfaction to be permanent and without expectation of recovery and the  person insured’s  name has been added to the Register of Blind Persons on the authority of a fully qualified ophthalmic specialist    b)  in one eye when the degree of sight remaining after correction is 3/60 or less on the Snellen Scale and  we  are satisfied that the condition is permanent and without expectation of recovery.   "
        },
        "101071": {
            page: "12",
            path: "Policy Wording4.pdf",
            text: " a)  In the case of a lower limb loss by permanent physical severance at or above the ankle or permanent total loss of use of an entire leg or foot   b)  in the case of an upper limb loss by permanent physical severance of the entire 4 fingers through or above the metacarpal phalangeal joints or permanent total loss of use of an entire arm or hand.  "
        },
        "101072": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: " Current coinage, current bank and currency notes, postal orders, cheques, banker’s drafts, bills of exchange, unused units in postage stamp franking machines, postage stamps, revenue stamps, National Savings certificates, National Insurance stamps, stamped or franked National Insurance cards, Holiday-with-Pay stamps, Dental Practice Board Payment forms, Premium Savings bonds, luncheon vouchers, trading stamps, credit card sales vouchers, consumer redemption vouchers and gift tokens accepted by  you  and VAT purchases invoices all pertaining to the  business  and belonging to  you  or for which  you  are responsible. "
        },
        "101073": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: " Money  in the form of crossed cheques, postal orders, crossed bankers’ drafts, credit card sales vouchers, Premium Savings bonds, National Savings certificates, unused units in postage stamp franking machines, stamped or franked National Insurance cards and VAT purchase invoices all pertaining to the  business  and belonging to  you  or for which  you  are responsible. "
        },
        "101074": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: "Any installation of such class or description as may be prescribed by regulations made by the relevant Secretary of State from time to time by statutory instrument being an installation designed or adapted for: \n a)  the production or use of atomic energy   b)  the carrying out of any process which is preparatory or ancillary to the production or use of atomic energy and which involves or is capable of causing the emission of ionising radiation   c)  the storage, processing or disposal of nuclear fuel or bulk quantities of other radioactive matter being matter which has been produced or irradiated in the production or use of nuclear fuel.  "
        },
        "101075": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: "Any plant including any machinery, equipment or appliance whether affixed to land or not, designed or adapted for the production of atomic energy by a fission process in which a controlled chain reaction can be maintained without an additional source of neutrons. "
        },
        "101076": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: " a)  In respect of a  person insured  who is gainfully employed by  you  and is below state retirement age and above 16 years of age: disablement caused excluding  loss of limb, loss of eye, total loss of hearing  or  total loss of speech  which will in all probability totally prevent the  person insured  from engaging in their  usual occupation  (as defined in Section L – Personal accident) for the remainder of their life    b)  in respect of a  person insured  who is not gainfully employed by  you  or is above the state retirement age or below 16 years of age: disablement caused excluding  loss of limb, loss of eye, total loss of hearing  or  total loss of speech  which will in all probability entirely prevent the  person insured  from engaging in any and every occupation for the remainder of their life.   "
        },
        "101077": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: "Any item of clothing or any other personal item made to be worn, used or carried about the person including passports, driving licences and proof-of-age cards. "
        },
        "101078": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: " You ,  your business partner  or any  employee . "
        },
        "101079": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: "The premises stated in the schedule. "
        },
        "101080": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: " Employer, company, partnership, public authority or individual for whom  you  have agreed to carry out work under the terms of the contract. "
        },
        "101081": {
            page: "13",
            path: "Policy Wording4.pdf",
            text: " a)  The rebuilding or replacement of property suffering  damage  which provided that  our  liability is not increased may be carried out:  i)  in any manner suitable to  your  requirements    ii)  upon another site      b)  the repair or restoration of property suffering  damage   \nin either case to a condition equivalent to or substantially the same as but not better or more extensive than its condition when new. "
        },
        "101082": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: " a)  Stock and materials in trade and goods in trust contained in the  buildings  of the  premises  and owned by  you  or for which  you  are responsible excluding stock in the open other than as covered under Material Damage extension – Outside catering    b)  wines and spirits up to the limit stated in the schedule   c)  cigarettes, cigars and tobacco up to the limit stated in the schedule.  "
        },
        "101083": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: " The downward movement of the bearing soil on which the  building  rests. "
        },
        "101084": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: "Great Britain, Northern Ireland, the Isle of Man and the Channel Islands. "
        },
        "101085": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: " a)  Any act or preparation in respect of action or threat of action designed to influence the government  de jure or de facto  of any nation or any political division of any nation, or in pursuit of political, religious, ideological, or similar purposes to intimidate the public or a section of the public of any nation by any person or group of persons whether acting alone or on behalf of or in connection with any organisation or government  de jure or de facto  and which:  i)  involves violence against one or more persons   ii)  involves damage to property   iii)  endangers life other than that of the person committing the action   iv)  creates a risk to health or safety of the public or a section of the public   v)  is designed to interfere with or to disrupt an electronic system      b)  any action in controlling, preventing, suppressing, retaliating against or responding to any act or preparation in respect of action or threat of action described in a) above.  "
        },
        "101086": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: "Total and permanent loss of hearing. "
        },
        "101087": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: "Total and permanent loss of speech. "
        },
        "101088": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: " You  or any  employee  who has undertaken suitable and adequate training or holds relevant qualifications to administer treatment or use equipment to a competent level. "
        },
        "101089": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: " Any  building  or part of  building  or flat which is empty, disused, unfurnished or no longer in active use by  you  or any of  your  tenants. "
        },
        "101090": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: "Program code, programming instruction or any set of instructions intentionally constructed with the ability to damage, interfere with or otherwise adversely affect computer programs, data files or operations whether involving self-replication or not including but not limited to Trojan horses, worms and logic bombs. "
        },
        "101091": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: "Zurich Insurance Company Ltd. "
        },
        "101092": {
            page: "14",
            path: "Policy Wording4.pdf",
            text: "The person, people or the company stated in the schedule as the insured. "
        },
        "101096": {
            page: "15",
            path: "Policy Wording4.pdf",
            text: "Fire, lightning, explosion or earthquake. "
        },
        "101097": {
            page: "15",
            path: "Policy Wording4.pdf",
            text: "Theft or attempted theft involving: \r\n a)  forcible and violent entry to or exit from a  building  at the  premises    b)  bodily injury  or threat of  bodily injury  against  you  or  your employees.   \r\nExcluding: \r\n i)  theft or attempted theft of the fabric of the  buildings    ii)  contents  and  stock  in excess of £1,000 contained in outbuildings    iii)  theft or attempted theft caused or contributed to by any of  your employees    iv)  damage  to cash registers unless the drawer has been left open and all  money  removed whenever the  premises  is closed for  business  or unattended    v)  damage  or  business interruption  unless all existing devices for securing the  buildings  of the  premises  are put into full and effective operation whenever the  premises  is closed for  business  or unattended.   "
        },
        "101098": {
            page: "15",
            path: "Policy Wording4.pdf",
            text: "Riot or civil, labour or political disturbances or vandals or malicious people. "
        },
        "101116": {
            page: "19",
            path: "Policy Wording4.pdf",
            text: "This section includes: \r\n a)  any newly acquired  buildings  or  contents  in the  territorial limits  not otherwise insured    b)  alterations, additions and improvements to  buildings  or  contents    but not for any increase in value during the current period of insurance at any of the premises insured under this policy. Provided that:   i)  the most  we  will pay at any one situation is 10% of the sum insured for  buildings  and  contents  up to a maximum of £500,000    ii)  you  tell  us  as soon as reasonably possible of any extension of cover detailed above and arrange insurance cover from the date that our liability commenced.   "
        },
        "101117": {
            page: "19",
            path: "Policy Wording4.pdf",
            text: ""
        },
        "101118": {
            page: "19",
            path: "Policy Wording4.pdf",
            text: " a)  refilling fire extinguishing appliances   b)  recharging gas flooding systems   c)  replacing used sprinkler heads   d)  refilling sprinkler tanks where water costs are metered   e)  resetting fire and intruder alarms   all following insured  damage .   "
        },
        "101119": {
            page: "19",
            path: "Policy Wording4.pdf",
            text: " We  will pay for accidental breakage of fixed glass in windows, doors, showcases, counters and shelves which  you  are legally responsible for at the  premises .   The most  we  will pay is the cost of replacing broken glass with glass of similar quality or as otherwise recommended by British Standard code of practice BS 6262.   We  will also pay up to £500 any one loss for: \r\n a)  the cost of boarding up until the broken glass is replaced   b)  damage to  contents  or  stock  caused by breakage of glass in the shop front    c)  damage to frames and framework of any description and the cost of removing or replacing any  contents  or  stock  which may have to be removed to replace the glass.   \r\nProvided cover is not otherwise excluded by any of insured events 1 to 9.  Excluding: \r\n i)  silvering, lettering, bending or ornamenting any glass in excess of £1,000 any one loss   ii)  breakage of cracked or scratched glass   iii)  loss, destruction or damage resulting from repairs or alterations to the  premises    iv)  loss or destruction of or damage to glass that is more specifically insured elsewhere.  "
        },
        "101120": {
            page: "20",
            path: "Policy Wording4.pdf",
            text: "Applicable to each item insured on the day 1 inflation protection basis as stated in the schedule. \r\n a)  At the start of each period of insurance  you  will notify  us  of the  declared value  of the property insured by each of the applicable items. If  you  do not declare this  we  will take the last amount declared by  you  as the  declared value  for the following period of insurance.    b)  Our  liability for the repair or restoration of property partly  damaged  will not exceed the amount which would have been payable had that property been totally destroyed.    c)  Where because of provisos a) i), ii) or iii) of Claims condition 7 claims are payable under paragraphs a) 1) or 2) of Claims condition 7 the sum insured under each item will be the  declared value  uplifted by the percentage stated in the schedule at the time of the  damage .   "
        },
        "101121": {
            page: "20",
            path: "Policy Wording4.pdf",
            text: " If index linking is stated in  your  schedule as applying  we  will automatically adjust the sums insured for  buildings ,  stock  and  contents  in line with changes in suitable indices of cost. This adjustment will continue after any  damage  if the repairs or  reinstatement  are done without delay.   We  will not charge any extra premium during the period of insurance but at the end of the period  we  will calculate the renewal premium based on the revised sum insured. "
        },
        "101123": {
            page: "20",
            path: "Policy Wording4.pdf",
            text: " We  will pay for excess water, gas or electricity supply charges demanded from  you  by the supply authority following loss of metered supplies as a result of  damage  by the operative Insured events to fixed pipes, apparatus and tanks provided that  you  have kept a weekly written record of meter readings from the supply authority.   The most  we  will pay for any one occurrence is £10,000. "
        },
        "101124": {
            page: "20",
            path: "Policy Wording4.pdf",
            text: " The interest of any freeholder, mortgagee, lessor, heritable creditor ‘Primo Loco’ or ‘Secundo Loco’ or similar party is noted. The nature and extent of such additional interests must be disclosed immediately following  damage  which is the subject of any claim. "
        },
        "101125": {
            page: "20",
            path: "Policy Wording4.pdf",
            text: " We  will pay for accidental breakage of fixed sanitaryware at the  premises  for which  you  are legally responsible.   Excluding loss, destruction or damage resulting from repairs or alterations to the  premises . "
        },
        "101126": {
            page: "20",
            path: "Policy Wording4.pdf",
            text: " In the event of  damage  to property insured caused by Insured events 5 or 8  we  will also pay for:    a)  reasonable costs and expenses necessarily incurred in locating the source of the  damage    b)  reasonable costs and expenses necessarily incurred in repairing any  damage  caused in locating the source of the  damage .   \r\n The most  we  will pay for any one occurrence is £25,000. "
        },
        "101127": {
            page: "21",
            path: "Policy Wording4.pdf",
            text: " We  will pay the cost of metered electricity, gas, oil or water for which  you  are legally responsible arising from its unauthorised use by persons taking possession, keeping possession or occupying the  premises  without  your  authority.  Provided that all practicable steps are taken to terminate unauthorised use as soon as it is discovered.   The most  we  will pay is £10,000 or 10% of the sum insured for Section A of the relevant  premises  whichever is the lesser. "
        },
        "101128": {
            page: "21",
            path: "Policy Wording4.pdf",
            text: " We  will pay the cost of accidental  damage  to underground cables, pipes or tanks servicing the  buildings  which  you  are legally responsible for. "
        },
        "101138": {
            page: "22",
            path: "Policy Wording4.pdf",
            text: " The insurance in respect of  contents  and  stock  includes  damage  caused by the operative Insured events occurring at locations where  you  are providing outside catering within the  territorial limits  or the Republic of Ireland.   The most  we  will pay in respect of all losses occurring at any one location is £5,000.   Excluding damage caused by storm or flood to  contents  and  stock  in the open or contained in a marquee or tent. "
        },
        "101139": {
            page: "22",
            path: "Policy Wording4.pdf",
            text: " We  will pay for  damage  by the operative Insured events to  your  pedal cycles, clothing and personal effects or those of  your employees .   The most  we  will pay for any one occurrence is £1,000.  Excluding jewellery and furs. "
        },
        "101140": {
            page: "22",
            path: "Policy Wording4.pdf",
            text: " We  will pay for  damage  to furniture, trade fixtures and fittings and utensils in the open at the  premises  or  adjacent premises  by Insured events 1, 3, 5, 6, 7, 8, 9 and 10, unless stated otherwise in the schedule.   The most  we  will pay for any one occurrence is £5,000. "
        },
        "101141": {
            page: "22",
            path: "Policy Wording4.pdf",
            text: " We  will pay for  damage  to  contents  while temporarily removed from the  premises  for cleaning, renovation, repair or similar purposes provided that the  damage  is caused by an operative Insured event and the  contents  are within the  territorial limits  or the Republic of Ireland at the time of  damage .   The most  we  will pay for any one occurrence is 20% of the contents sum insured. \r\n Excluding:   a)  loss, destruction or damage to  your  personal belongings or those of  your  directors,  employees  or visitors    b)  loss, destruction or damage caused by storm or flood while in the open.  "
        },
        "101142": {
            page: "22",
            path: "Policy Wording4.pdf",
            text: " The sums insured in respect of  stock  will be automatically increased each year by 30% during November, December and the 30 days prior to Easter. "
        },
        "201007": {
            page: "2",
            path: "Policy Schedule4.pdf",
            text: "Material damage, Insured\r\nBusiness interruption, Insured\r\nBook debts, Insured\r\nMoney, Insured\r\nDeterioration of stock, Insured\r\nLoss of licence, Not insured\r\nEmployers' liability, Insured\r\nPublic and products liability, Insured\r\nGoods in transit, Insured\r\nSpecified items 'all risks', Not insured\r\nLegal expenses, Not insured\r\nEmployee dishonesty, Not insured\r\nPersonal accident, Not insured\r\nTerrorism, Not insured"
        },
        "201008": {
            page: "3",
            path: "Policy Schedule4.pdf",
            text: "The excesses stated below are payable in the event of a claim unless stated otherwise within the endorsements and subjectivities section of your policy. Each excess shown is your total excess and includes any policy, voluntary and/or amended excesses that apply."
        },
        "201009": {
            page: "3",
            path: "Policy Schedule4.pdf",
            text: "Post Office 125-127 The Vale London United Kingdom W3 7RQ"
        },
        "201010": {
            page: "3",
            path: "Policy Schedule4.pdf",
            text: "Grocer"
        },
        "201011": {
            page: "3",
            path: "Policy Schedule4.pdf",
            text: 'Refer to section A in your Shop policy Index linking, Yes  \nDay 1, No  \nItems with # denotes index linking,\nBuildings  \n1. Buildings, Â£0  \n2. Loss of rental income, Â£0  \n3. Landlords fixtures and fittings, Â£0  \n4. Shop front, Â£0  \nStock and contents  \n1. Stock, "Â£23,611 #"\n2. Wines and spirits, "Â£7,083#"\n"3. Cigarettes, cigars and tobacco", "Â£5,903#"\n4. All other Contents, "Â£7,785#"\n5. Tenants improvements, Â£0'
        },
        "201012": {
            page: "3",
            path: "Policy Schedule4.pdf",
            text: ""
        },
        "201013": {
            page: "3",
            path: "Policy Schedule4.pdf",
            text: "Â£250 Other than:\n9. Subsidence Â£0"
        },
        "201014": {
            page: "4",
            path: "Policy Schedule4.pdf",
            text: 'Refer to section B in your Shop policy\nLoss of income, "Â£2,000,000"\nIndemnity period (months), 12\nBook debts, "Â£10,000"'
        },
        "201015": {
            page: "4",
            path: "Policy Schedule4.pdf",
            text: 'Refer to section C in your Shop policy\n"Non-negotiable money Â£250,000"\nFor money in any other form:  \n1. In transit by employees, "Â£7,500"\n2. In transit by post, "Â£1,000"\n3. In any bank night safe, "Â£7,500"\n4. In premises during business hours, "Â£7,500"\n5. In your home or that of any employee you authorise, Â£500  \n6. In premises outside business hours not in a locked safe or strong room, Â£250  \n7. In vending or gaming machines in the premises, Â£250  \n8. Money in safe outside of business hours, "Unspecified safe Â£2,500"\n9 Elsewhere Nil'
        },
        "201016": {
            page: "4",
            path: "Policy Schedule4.pdf",
            text: 'Refer to section D in your Shop policy Deterioration of stock, "Â£10,000"\nDeterioration of stock - excess, Â£75'
        },
        "201017": {
            page: "5",
            path: "Policy Schedule4.pdf",
            text: "The following Special condition(s) are applicable to this premises if stated as yes below. Refer to your policy for full condition wording(s).\nMinimum security standards, Yes\nMinimum security standards - intruder alarm condition, Yes"
        },
        "201018": {
            page: "5",
            path: "Policy Schedule4.pdf",
            text: "Endorsements and subjectivities that apply to your premises are shown below. Full endorsement and/or subjectivity wordings are listed at the end of this schedule under the Endorsements and subjectivities wordings section."
        },
        "201019": {
            page: "5",
            path: "Policy Schedule4.pdf",
            text: ""
        },
        "201020": {
            page: "6",
            path: "Policy Schedule4.pdf",
            text: ""
        },
        "201021": {
            page: "6",
            path: "Policy Schedule4.pdf",
            text: "The excesses stated below are payable in the event of a claim unless stated otherwise within the endorsements and subjectivities section of your policy. Each excess shown is your total excess and includes any policy and/or amended excesses that apply."
        },
        "201022": {
            page: "6",
            path: "Policy Schedule4.pdf",
            text: 'Refer to section F in your Shop policy.\n"Employers\' liability - limit of indemnity, "Â£10,000,000"\nEmployers\' liability - limit of indemnity arising out of terrorism, "Â£5,000,000"'
        },
        "201023": {
            page: "6",
            path: "Policy Schedule4.pdf",
            text: 'Refer to section G in your Shop policy.\nRefer to section D in your Shop policy Deterioration of stock, "Â£10,000"\nDeterioration of stock - excess, Â£75\n\nThe following Special condition(s) are applicable to this premises if stated as yes below. Refer to your policy for full condition wording(s).\n\nMinimum security standards, Yes\nMinimum security standards - intruder alarm condition, Yes\n\nEndorsements and subjectivities that apply to your premises are shown below. Full endorsement and/or subjectivity wording(s) are listed at the end of this schedule under the Endorsements and subjectivities wordings section.\n\nThe excesses stated below are payable in the event of a claim unless stated otherwise within the endorsements and subjectivities section of your policy. Each excess shown is your total excess and includes any policy and/or amended excesses that apply.\n\nRefer to section F in your Shop policy.\n\nEmployers\' liability - limit of indemnity, "Â£10,000,000"\nEmployers\' liability - limit of indemnity arising out of terrorism, "Â£5,000,000"\n\nRefer to section G in your Shop policy.\n\nPublic and products liability - limit of indemnity, "Â£2,000,000"\nThird party property damage - excess, Â£250'
        },
        "201024": {
            page: "6",
            path: "Policy Schedule4.pdf",
            text: 'Refer to section H in your Shop policy.\nGoods in transit - limit of indemnity, "Â£2,500"\nGoods in transit - excess, Â£75'
        },
        "201025": {
            page: "7",
            path: "Policy Schedule4.pdf",
            text: "This policy is subject to the general endorsements shown below:"
        },
        "201026": {
            page: "7",
            path: "Policy Schedule4.pdf",
            text: ""
        },
        "301001": {
            page: "7",
            path: "Policy Schedule4.pdf",
            text: "Please find below the endorsements and subjectivities that apply to your policy."
        },
        "301002": {
            page: "7",
            path: "Policy Schedule4.pdf",
            text: "It is a condition precedent to our liability for damage caused by fire that:\na) All sump boxes, filters, traps and grease removal devices shall be cleaned at least once every week. \nb) All extraction systems including flues, hoods, canopies, extraction motors, fans and the entire length of any ducting shall be cleaned at least once every twelve months. \nc) All oily or greasy waste and cloths will be kept in metal bins with metal lids and removed from the buildings at the end of each day. \n\nAnd if deep fat frying is undertaken: \nd) All cooking equipment used for deep fat frying shall be fitted with a thermostat to prevent fat or oil exceeding 205 degrees centigrade and a high temperature non-self-resetting limit control to shut off the heat source if fat or oil exceeds 230 degrees centigrade. \ne) All cooking equipment used for deep fat frying: \n   i) Will be installed and operated in accordance with the manufacturer's instructions. \n   ii) Will be serviced at least once every twelve months by a suitably qualified engineer and otherwise maintained as necessary. \n   iii) Will not be left unattended whilst the heat source is operating."
        },
        "301003": {
            page: "7",
            path: "Policy Schedule4.pdf",
            text: "The excess for escape of water is Â£350."
        },
        "301004": {
            page: "7",
            path: "Policy Schedule4.pdf",
            text: "Damage caused by the theft or attempted theft of tobacco products housed within a shop cabinet, cupboard, or drawer is not insured unless the shop cabinet, cupboard, or drawer is lockable or protected by a lockable shutter and the roller shutter and the cabinet, cupboard, or drawer is locked whenever the premises are closed for business or left unattended. The most we will pay for tobacco contained:\na) In each shop cabinet, cupboard or drawer is Â£5,000. b) Elsewhere is Â£200."
        },
        "301005": {
            page: "7",
            path: "Policy Schedule4.pdf",
            text: "i) Where we require or agree to accept an alternative method of security, we will endorse this policy. \nii) This endorsement has a continuing effect and its terms should be kept in mind. If circumstances arise which render you unable to comply with any part of it, you should advise us immediately to see if we can help you obtain reinstatement of cover.\nZurich Insurance Company Ltd\nA public limited company incorporated in Switzerland. Registered in the Canton of Zurich, No. CHE-105.833.114, registered offices at Mythenquai 2, 8002 Zurich. UK Branch registered in England and Wales no BR000105. UK Branch Head Office: The Zurich Centre, 3000 Parkway, Whiteley, Fareham, Hampshire PO15 7JZ. Zurich Insurance Company Ltd is authorised and regulated in Switzerland by the Swiss Financial Market Supervisory Authority FINMA. Authorised by the Prudential Regulation Authority. Subject to regulation by the Financial Conduct Authority and limited regulation by the Prudential Regulation Authority. Details about the extent of our regulation by the Prudential Regulation Authority are available from us on request. Our firm reference number is 959113."
        }
    },
    role: "assistant",
    thoughts:
        '[{"Damage": "Fire", "Damage_Description": "On 5 February 2024, there was a fire at the risk address which has caused damage to the shop including the wooden floor that was installed by the customer.", "Coverage_Description": "The policy provides coverage for fire damage<cite>101096</cite>. The wooden floor installed by the customer is considered part of the contents of the building, and the policy provides coverage for contents<cite>101047</cite>. The policy also includes the cost of refilling fire extinguishing appliances following insured damage<cite>101118</cite>. However, it is a condition precedent to our liability for damage caused by fire that all sump boxes, filters, traps and grease removal devices shall be cleaned at least once every week and all extraction systems including flues, hoods, canopies, extraction motors, fans and the entire length of any ducting shall be cleaned at least once every twelve months<cite>301002</cite>.", "Coverage_Section": "Section A \\u2013 Material damage<cite>101096</cite>", "Base_Policy_Citations": "101096, 101047, 101118", "Coverage_Name": "Material Damage", "Amount_of_insurance": "\\u00a37,785<cite>201011</cite>", "Deductible": "\\u00a3250<cite>201013</cite>", "Covered_or_Not_Covered": "Covered<cite>201007</cite>", "Coverage_Category": "Material damage, Insured<cite>201007</cite>", "Declaration_Citation": "201011, 201013, 201007", "Applied_Endorsement": "The endorsement AR0022 Cooking condition (B) applies to this policy. It states that all sump boxes, filters, traps and grease removal devices shall be cleaned at least once every week and all extraction systems including flues, hoods, canopies, extraction motors, fans and the entire length of any ducting shall be cleaned at least once every twelve months<cite>301002</cite>.", "Endorsement_Citation": "301002"}]'
};

export const history_items = [
    {
        id: "1",
        title: "Steps to ensure claim processed efficiently ?",
        create_time: "2024-02-06T12:00:00.000000+00:00",
        isArchived: false,
        updatetime: "2024-02-06T12:00:00.000000+00:00"
    },
    {
        id: "2",
        title: "Preventive measures to avoid incidents",
        create_time: "2024-01-29T12:00:00.000000+00:00",
        isArchived: false,
        updatetime: "2024-01-29T12:00:00.000000+00:00"
    },
    {
        id: "3",
        title: "is there any limitations to this coverage?",
        create_time: "2023-02-31T12:00:00.000000+00:00",
        isArchived: true,
        updatetime: "2023-02-31T12:00:00.000000+00:00"
    }
];

export const dummy_policies_data = [
    {
        policyID: "1",
        policyName: "Kemper"
    },
    {
        policyID: "2",
        policyName: "Canada"
    },
    {
        policyID: "3",
        policyName: "Berkley"
    }
];
