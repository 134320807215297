import { useState } from "react";
import { Grid, Paper, styled, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { IconButton, Stack } from "@fluentui/react";
import styles from "./QuesAnsBox.module.css";
import { useEffect, useRef } from 'react';
import { AnswerIcon } from "../Answer/AnswerIcon";
import clipboardCopy from "clipboard-copy";

interface IProps {
    item: [string, { answer: string }]
}

export default function QuesAnsBox({ item }: IProps) {
    const answerRef = useRef(null);
    const messagesEndRef = useRef<any>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [item]);

    const handleHTMLContent = () => {
        if (item[1].answer?.includes('<table>')) {
            return <div dangerouslySetInnerHTML={{ __html: item[1].answer }} style={{ color: "#000" }} />;
        } else if (item[1].answer?.includes('\n')) {
            let removedNewLine = item[1].answer?.replace(/\n/g, "<br />");
            return <div dangerouslySetInnerHTML={{ __html: removedNewLine }} style={{ fontWeight: "600", color: "#000" }} />
        } else
            return (
                <p style={{ fontWeight: "600", color: "#000" }}>
                    {item[1].answer}
                </p >
            );
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                margin: "10px 0px 10px 0px",
            }}
        >
            {/* // Ques */}
            <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.quesContainer}>
                            <div className={styles.message}>{item[0]}</div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.chatMessageGpt} ref={answerRef}>
                            <Stack className={styles.answerContainer} verticalAlign="space-between">
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign="space-between">
                                        <AnswerIcon />
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Tooltip title="copy">
                                                    <div onClick={() => {
                                                        if (answerRef.current) {
                                                            //@ts-ignore
                                                            clipboardCopy(item[1].answer)
                                                                .then(() => {
                                                                    console.log("Text copied to clipboard");
                                                                })
                                                                .catch((err: any) => {
                                                                    console.log(err);
                                                                });
                                                        }
                                                    }} style={{ cursor: "pointer", width: 40, height: 40, backgroundColor: "#efefef", borderRadius: 8, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                        <IconButton
                                                            style={{ color: "black" }}
                                                            iconProps={{ iconName: "Copy" }}
                                                            title="copy"
                                                            ariaLabel="copy"
                                                            size={30}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item grow>
                                    {handleHTMLContent()}
                                </Stack.Item>
                            </Stack>
                        </div>

                    </Grid>
                </Grid>
            </div>
            {/* // Auto scroll to end */}
            <span ref={messagesEndRef}></span>
        </Box>
    );
}