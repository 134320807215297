import { useState } from "react";
import { Box, Tooltip } from "@mui/material";
import sidebar_row from "../../../assets/versionB/icons/sidebar_row.svg";
import sidebar_left_arrow from "../../../assets/versionB/icons/sidebar_left_arrow.svg";
import sidebar_right_arrow from "../../../assets/versionB/icons/sidebar_right_arrow.svg";
interface IProps {
    title?: string;
    placement: string;
    togglePosition: string;
    drawerFunc: Function;
    isOpen: boolean;
}
const DrawerToggler = ({ placement, isOpen, togglePosition, drawerFunc }: IProps) => {
    const [isHover, setIsHover] = useState(false);
    const handleHover = (val: boolean) => {
        setIsHover(val);
    };

    const handleListner = () => {
        setIsHover(false);
        isOpen ? drawerFunc(false) : drawerFunc(true);
    };
    return (
        <Box sx={{ width: 10, px: 1, display: "flex", alignItems: "center", justifyContent: "center" }}>
            <Tooltip
                title={isOpen ? "Close" : "Open"}
                arrow
                placement={placement === "right" ? "right" : "left"}
                onMouseLeave={() => handleHover(false)}
                onMouseEnter={() => handleHover(true)}
                onClick={handleListner}
            >
                {isHover ? (
                    isOpen && togglePosition === "left" ? (
                        <img src={sidebar_left_arrow} style={{ cursor: "pointer", height: 20, width: "auto" }} />
                    ) : !isOpen && togglePosition === "left" ? (
                        <img src={sidebar_right_arrow} style={{ cursor: "pointer", height: 20, width: "auto" }} />
                    ) : isOpen && togglePosition === "right" ? (
                        <img src={sidebar_right_arrow} style={{ cursor: "pointer", height: 20, width: "auto" }} />
                    ) : !isOpen && togglePosition === "right" ? (
                        <img src={sidebar_left_arrow} style={{ cursor: "pointer", height: 20, width: "auto" }} />
                    ) : (
                        <img src={sidebar_row} style={{ cursor: "pointer", height: 20, width: "auto" }} />
                    )
                ) : (
                    <img src={sidebar_row} style={{ cursor: "pointer", height: 20, width: "auto" }} />
                )}
            </Tooltip>
        </Box>
    );
};

export default DrawerToggler;
