import * as React from "react";
import { Box, Button, Skeleton, Stack } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import LogoutIcon from "@mui/icons-material/Logout";
import DetailsDrawerAccordion from "./DetailsDrawerAccordion";
import DocumentsDrawer from "./DocumentsDrawer";
import ClaimOutcomeStepper from "../ClaimDetails/ClaimOutcomesStepper";
import { theme } from "../../../consts/versionB/consts/theme";
import { ChatHistory } from "./ChatHistory";
import DrawerToggler from "./DrawerToggler";
import CommonAppBar from "../Common/CommonAppBar";
import { HistoryItem, SearchedClaim } from "../../../api/versionB/api";
import { useAuth } from "../../../context/AuthProvider";

const drawerWidth = 250;

interface IComponent {
    children: React.ReactNode;
    detailsData: any[];
    clearHistory: Function;
    isClaimDetailLoading: boolean;
    selectedClaimDetails: SearchedClaim | undefined;
    clearChatHistory: Function;
    firstQues: HistoryItem | undefined;
}
export default function PolicyChatLayout({
    children,
    detailsData,
    clearHistory,
    isClaimDetailLoading,
    selectedClaimDetails,
    clearChatHistory,
    firstQues
}: IComponent) {
    const { logout } = useAuth();
    const [open, setOpen] = React.useState(true);
    const [leftDrawerOpen, setLeftDrawerOpen] = React.useState(true);
    const [rightDrawerOpen, setRightDrawerOpen] = React.useState(true);
    const [mobileLeftDrawerOpen, setMobileLeftDrawerOpen] = React.useState<boolean>(false);
    const [mobileRightDrawerOpen, setMobileRightDrawerOpen] = React.useState<boolean>(false);
    const [screenSize, setScreenSize] = React.useState(getCurrentDimension());
    const [rightPanel, setRightPanel] = React.useState("Details");

    React.useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension());
        };
        window.addEventListener("resize", updateDimension);
        // closing already opened drawer
        if (screenSize.width < 1080) {
            setOpen(false);
        } else {
            setOpen(true);
        }
        return () => {
            window.removeEventListener("resize", updateDimension);
        };
    }, [screenSize]);

    const handleLeftDrawer = (state: boolean) => {
        if (screenSize.width >= 1080) {
            setLeftDrawerOpen(state);
        } else {
            setMobileLeftDrawerOpen(state);
        }
    };

    const handleRightDrawer = (state: boolean) => {
        if (screenSize.width >= 1080) {
            setRightDrawerOpen(state);
        } else {
            setMobileRightDrawerOpen(state);
        }
    };

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    const DrawerLoader = () => {
        return (
            <Box sx={{ height: "88vh" }}>
                <Skeleton variant="rounded" animation="wave" height={"20%"} sx={{ mb: 2 }} />
                <Skeleton variant="rounded" animation="wave" height={"20%"} sx={{ mb: 2 }} />
                <Skeleton variant="rounded" animation="wave" height={"20%"} sx={{ mb: 2 }} />
                <Skeleton variant="rounded" animation="wave" height={"20%"} />
            </Box>
        );
    };
    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <CommonAppBar
                position="fixed"
                title="AI for claim coverage assistance"
                icon={<LogoutIcon sx={{ color: theme.palette.textGray, fontSize: 20 }} />}
                functionTitle="Logout"
                func={() => logout()}
                showSearchClaim={true}
            />
            {/* // Desktop view detail drawer */}
            {open && leftDrawerOpen && (
                <Drawer
                    PaperProps={{ style: { background: "transparent", border: "none" }, sx: { pl: 2 } }}
                    variant="permanent"
                    sx={{
                        background: "transparent",
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }
                    }}
                >
                    <Toolbar />
                    <ChatHistory firstQues={firstQues} clearChatHistory={clearChatHistory} />
                </Drawer>
            )}

            {/* // Mobile view detail drawer */}

            <Drawer
                PaperProps={{ style: { background: "#f2f2f2" }, sx: { px: 2 } }}
                sx={{
                    background: "#f2f2f2",
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }
                }}
                anchor={"left"}
                open={mobileLeftDrawerOpen}
                onClose={() => setMobileLeftDrawerOpen(false)}
            >
                <Toolbar />
                <ChatHistory firstQues={firstQues} clearChatHistory={clearChatHistory} />
            </Drawer>

            {/* // Open Button */}

            <Box sx={{ alignItems: "center", display: screenSize.width >= 1080 ? "flex" : "none" }}>
                <DrawerToggler placement="right" isOpen={leftDrawerOpen} togglePosition="left" drawerFunc={handleLeftDrawer} />
            </Box>
            <Box sx={{ alignItems: "center", display: screenSize.width < 1080 ? "flex" : "none" }}>
                <DrawerToggler placement="right" isOpen={false} togglePosition="left" drawerFunc={handleLeftDrawer} />
            </Box>
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Toolbar />
                {children}
            </Box>
            <Box sx={{ alignItems: "center", display: screenSize.width >= 1080 ? "flex" : "none" }}>
                <DrawerToggler placement="left" isOpen={rightDrawerOpen} togglePosition="right" drawerFunc={handleRightDrawer} />
            </Box>
            <Box sx={{ alignItems: "center", display: screenSize.width < 1080 ? "flex" : "none" }}>
                <DrawerToggler placement="left" isOpen={false} togglePosition="right" drawerFunc={handleRightDrawer} />
            </Box>

            {/* // Desktop view drawer document */}
            {open && rightDrawerOpen && (
                <Drawer
                    PaperProps={{ style: { background: "transparent", border: "none" }, sx: { pr: 2 } }}
                    variant={"permanent"}
                    sx={{
                        background: "transparent",
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }
                    }}
                    anchor="right"
                >
                    <Toolbar />
                    {!isClaimDetailLoading ? (
                        <Box
                            sx={{
                                overflow: "hidden",
                                background: theme.palette.white,
                                height: "100%",
                                borderRadius: theme.border.scardBorder,
                                mb: 2
                            }}
                        >
                            {/* <HelpFab /> */}
                            <Box
                                sx={{
                                    width: "100%",
                                    background: theme.palette.white,
                                    position: "sticky",
                                    top: 0,
                                    zIndex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    paddingTop: 1,
                                    paddingBottom: 1,
                                    px: 2
                                }}
                            >
                                <Stack direction="row" sx={{ borderBottom: 1, borderColor: theme.palette.lightGray2, paddingBottom: 2 }}>
                                    {["Details", "Documents", "Outcomes"].map(item => (
                                        <Box sx={{ display: "flex", flexDirection: "column" }} key={item}>
                                            <Button
                                                disabled={!selectedClaimDetails}
                                                disableElevation
                                                disableRipple
                                                disableTouchRipple
                                                disableFocusRipple
                                                onClick={() => setRightPanel(item)}
                                                key={item}
                                                variant="text"
                                                sx={{
                                                    textTransform: "capitalize",
                                                    fontSize: 12,
                                                    fontWeight: "bold",
                                                    color: item === rightPanel ? theme.palette.primaryLight : theme.palette.primaryGray,
                                                    "&:hover": {
                                                        background: "transparent"
                                                    }
                                                }}
                                            >
                                                {item}
                                            </Button>
                                            {item === rightPanel && selectedClaimDetails && (
                                                <Box sx={{ alignSelf: "center", height: 2, background: theme.palette.primaryLight, width: "80%" }}></Box>
                                            )}
                                        </Box>
                                    ))}
                                </Stack>
                            </Box>
                            {rightPanel === "Documents" ? (
                                <DocumentsDrawer disabled={!selectedClaimDetails} data={detailsData} />
                            ) : rightPanel === "Details" ? (
                                <DetailsDrawerAccordion disabled={!selectedClaimDetails} data={detailsData} />
                            ) : (
                                <div style={{ overflowY: "auto", height: "90%" }}>
                                    <ClaimOutcomeStepper disabled={!selectedClaimDetails} />
                                </div>
                            )}
                        </Box>
                    ) : (
                        <DrawerLoader />
                    )}
                </Drawer>
            )}

            {/* // Mobile view drawer */}

            <Drawer
                PaperProps={{ style: { background: "#f2f2f2" }, sx: { px: 2 } }}
                sx={{
                    background: "#f2f2f2",
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }
                }}
                anchor={"right"}
                open={mobileRightDrawerOpen}
                onClose={() => setMobileRightDrawerOpen(false)}
            >
                <Toolbar />
                {!isClaimDetailLoading ? (
                    <Box sx={{ mb: 2, overflow: "hidden", background: theme.palette.white, height: "100%", borderRadius: theme.border.scardBorder }}>
                        <Box
                            sx={{
                                width: "100%",
                                background: theme.palette.white,
                                position: "sticky",
                                top: 0,
                                zIndex: 1,
                                display: "flex",
                                alignItems: "center",
                                justifyContent: "center",
                                paddingTop: 2,
                                paddingBottom: 1
                            }}
                        >
                            <Stack direction="row" sx={{ borderBottom: 1, borderColor: theme.palette.lightGray2, paddingBottom: 2 }}>
                                {["Details", "Documents", "Outcomes"].map(item => (
                                    <Box sx={{ display: "flex", flexDirection: "column" }}>
                                        <Button
                                            disableElevation
                                            disableRipple
                                            disableTouchRipple
                                            disableFocusRipple
                                            onClick={() => setRightPanel(item)}
                                            key={item}
                                            variant="text"
                                            sx={{
                                                textTransform: "capitalize",
                                                fontSize: 12,
                                                fontWeight: "bold",
                                                color: item === rightPanel ? theme.palette.primaryLight : theme.palette.primaryGray,
                                                "&:hover": {
                                                    background: "transparent"
                                                }
                                            }}
                                        >
                                            {item}
                                        </Button>
                                        {item === rightPanel && (
                                            <Box sx={{ alignSelf: "center", height: 2, background: theme.palette.primaryLight, width: "80%" }}></Box>
                                        )}
                                    </Box>
                                ))}
                            </Stack>
                        </Box>
                        {rightPanel === "Documents" ? (
                            <DocumentsDrawer disabled={!selectedClaimDetails} data={detailsData} />
                        ) : rightPanel === "Details" ? (
                            <DetailsDrawerAccordion disabled={!selectedClaimDetails} data={detailsData} />
                        ) : (
                            <ClaimOutcomeStepper disabled={!selectedClaimDetails} />
                        )}
                    </Box>
                ) : (
                    <DrawerLoader />
                )}
            </Drawer>
        </Box>
    );
}
