import React, { Fragment, useEffect, useRef, useState } from "react";
import EditIcon from "@mui/icons-material/Edit";
import ArchiveIcon from "@mui/icons-material/Archive";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import { Box, Button, IconButton, Input, Menu, MenuItem, MenuProps, Typography } from "@mui/material";
import { styled, alpha } from "@mui/material/styles";
import { theme } from "../../../consts/versionB/consts/theme";
import CommonDialog from "../Common/CommonDialog";
import ShareChatDialog from "../Common/ShareChatDialog";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "right"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "left"
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(-0.1),
        minWidth: 180,
        marginLeft: -20,
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0"
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

const ChatHistoryBlock = ({ chatTitle: item, setActiveBtn, activeBtn }: { chatTitle: string; setActiveBtn: Function; activeBtn: string }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [isDeleteAlertVisible, setIsDeleteAlertVisible] = useState(false);
    const [isShareChatAlertVisible, setIsShareChatAlertVisible] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [editableContent, setEditableContent] = useState<string>(item);
    const [isHover, setIsHover] = useState(false);
    const open = Boolean(anchorEl);
    const inputRef = useRef<HTMLInputElement>(null);

    useEffect(() => {
        const handleClickOutside = (event: MouseEvent) => {
            if (inputRef.current && !inputRef.current.contains(event.target as Node)) {
                setIsEditable(false);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);

        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const deleteChat = () => {
        handleClose();
        setIsDeleteAlertVisible(true);
    };

    const renameChat = () => {
        handleClose();
        setIsEditable(true);
    };

    return (
        <Fragment key={item}>
            {isEditable && (
                <Input
                    inputRef={inputRef}
                    autoFocus={isEditable}
                    placeholder=""
                    value={editableContent}
                    style={{ width: "100%", borderBottom: "none" }}
                    disableUnderline
                    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                        if (event.key === "Enter" && !event.shiftKey) {
                            event.preventDefault();
                            setIsEditable(false);
                        }
                    }}
                    sx={{
                        borderRadius: 1,
                        fontSize: 11,
                        px: 1,
                        width: "100%",
                        my: 0.2,
                        justifyContent: "space-between",
                        textTransform: "capitalize",
                        background: item === activeBtn ? theme.palette.blueLight : "transparent"
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => setEditableContent(event.target.value)}
                />
            )}
            {!isEditable && (
                <Box sx={{ marginX: 1.5 }}>
                    <Button
                        onMouseEnter={() => setIsHover(true)}
                        onMouseLeave={() => setIsHover(false)}
                        key={item}
                        onClick={() => setActiveBtn(item)}
                        variant="text"
                        endIcon={
                            item === activeBtn || isHover ? (
                                <>
                                    <IconButton sx={{ padding: 0 }} disableRipple disableFocusRipple disableTouchRipple onClick={handleClick}>
                                        <MoreHorizIcon sx={{ color: theme.palette.blueLightText, fontSize: 14, mr: 1 }} />
                                    </IconButton>
                                    <IconButton sx={{ padding: 0 }} disableRipple disableFocusRipple disableTouchRipple>
                                        <ArchiveIcon sx={{ color: theme.palette.blueLightText, fontSize: 14 }} />
                                    </IconButton>
                                </>
                            ) : (
                                false
                            )
                        }
                        sx={{
                            width: "100%",
                            my: 0.2,
                            justifyContent: "space-between",
                            textTransform: "capitalize",
                            background: item === activeBtn ? theme.palette.blueLight : "transparent"
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center", width: "100%" }}>
                            <Typography
                                component={"p"}
                                variant="caption"
                                sx={{
                                    fontWeight: "bold",
                                    fontSize: 11,
                                    color: item === activeBtn ? theme.palette.blueLightText : theme.palette.primaryGray,
                                    whiteSpace: "nowrap",
                                    overflow: "hidden"
                                }}
                            >
                                {item === activeBtn ? item.substring(0, 23) : isHover ? item.substring(0, 23) : item}
                            </Typography>
                        </Box>
                    </Button>

                    <StyledMenu
                        id="demo-customized-menu"
                        MenuListProps={{
                            "aria-labelledby": "demo-customized-button"
                        }}
                        anchorEl={anchorEl}
                        open={open}
                        onClose={handleClose}
                    >
                        <MenuItem disableRipple sx={{ fontSize: 12, color: theme.palette.primaryGray }} onClick={() => renameChat()}>
                            <EditIcon sx={{ width: 14 }} />
                            Rename
                        </MenuItem>
                        <MenuItem disableRipple sx={{ fontSize: 12, color: theme.palette.red }} onClick={() => deleteChat()}>
                            <DeleteOutlineIcon style={{ width: 14, color: theme.palette.red }} />
                            Delete Chat
                        </MenuItem>
                    </StyledMenu>
                </Box>
            )}

            <CommonDialog
                isAlertVisible={isDeleteAlertVisible}
                setIsAlertVisible={setIsDeleteAlertVisible}
                alertTitle="Delete Chat"
                alertDescription={`This will delete`}
                boldDescription={`Limits for theft claims.`}
                alertHandler={() => false}
                buttonText={["Cancel", "Delete"]}
            />

            {/* // Share ChatDialog */}
            <ShareChatDialog isAlertVisible={isShareChatAlertVisible} setIsAlertVisible={setIsShareChatAlertVisible} />
        </Fragment>
    );
};

export default ChatHistoryBlock;
