import React, { useState, Fragment } from "react";
import { HistoryItem } from "../../../api/versionB/api";
import ChatHistoryBlock from "./ChatHistoryBlock";

const FilteredChatHistory: React.FC<{ data: HistoryItem[] }> = ({ data }) => {
    const [activeBtn, setActiveBtn] = useState<string>("");

    // const [filteredData, setFilteredData] = useState<{ [key: string]: HistoryItem[] }>({});

    // useEffect(() => {
    //     const today = new Date();
    //     const oneDay = 24 * 60 * 60 * 1000;
    //     const filteredDataMap: { [key: string]: HistoryItem[] } = {};
    //     // removing archived data
    //     const openData = data.filter(item => item.isArchived !== true);
    //     openData.forEach(item => {
    //         const diffDays = Math.round(Math.abs((today.getTime() - new Date(item.updatetime).getTime()) / oneDay));
    //         let category = "";

    //         if (diffDays === 0) {
    //             category = "Today";
    //         } else if (diffDays === 1) {
    //             category = "Yesterday";
    //         } else {
    //             category = `Previous ${diffDays} Days`;
    //         }

    //         if (!filteredDataMap[category]) {
    //             filteredDataMap[category] = [];
    //         }

    //         filteredDataMap[category].push(item);
    //     });

    //     // Sort categories based on date
    //     const sortedCategories = Object.keys(filteredDataMap).sort((a, b) => {
    //         const aDate = getDateFromString(a);
    //         const bDate = getDateFromString(b);
    //         return bDate.getTime() - aDate.getTime();
    //     });

    //     const sortedData: { [key: string]: HistoryItem[] } = {};
    //     sortedCategories.forEach(category => {
    //         sortedData[category] = filteredDataMap[category];
    //     });
    //     setFilteredData(sortedData);
    // }, [data]);

    // const getDateFromString = (category: string): Date => {
    //     if (category === "Today") return new Date();
    //     if (category === "Yesterday") {
    //         const yesterday = new Date();
    //         yesterday.setDate(yesterday.getDate() - 1);
    //         return yesterday;
    //     }
    //     const daysAgo = parseInt(category.split(" ")[1]);
    //     const date = new Date();
    //     date.setDate(date.getDate() - daysAgo);
    //     return date;
    // };

    return (
        <Fragment>
            {/* {Object.entries(filteredData).map(([category, items]) => (
                <>
                    {items.map(item => (
                        <ChatHistoryBlock key={item.id} chatTitle={item.title} setActiveBtn={setActiveBtn} activeBtn={activeBtn} />
                    ))}
                </>
            ))} */}
            {data.map((item: HistoryItem, index: number) => (
                <ChatHistoryBlock key={index.toString()} chatTitle={item.title} setActiveBtn={setActiveBtn} activeBtn={activeBtn} />
            ))}
        </Fragment>
    );
};

export default FilteredChatHistory;
