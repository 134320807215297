import * as React from "react";
import { Snackbar, Slide, Button, TextField, Dialog, DialogTitle, styled, DialogContent, DialogActions, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import ThumbUpOffAltIcon from "@mui/icons-material/ThumbUpOffAlt";
import ThumbDownOffAltIcon from "@mui/icons-material/ThumbDownOffAlt";
import { TransitionProps } from "@mui/material/transitions";
import { createTheme, ThemeProvider, Theme, useTheme } from "@mui/material/styles";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import styles from "./FeedbackDialog.module.css";
import FeedbackDialogChecks from "./FeedbackDialogChecks";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    setFeedbackShow: Function;
    feedbackShow: boolean;
    feedbackStatus: string;
}

const customTheme = (outerTheme: Theme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#eee",
                        "--TextField-brandBorderHoverColor": "#eee",
                        "--TextField-brandBorderFocusedColor": "#888",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)"
                        }
                    }
                }
            },

            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before, &:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)"
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)"
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)"
                        }
                    }
                }
            }
        }
    });

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} style={{ backgroundColor: "#d2f4d3", color: "#1a7f64", fontWeight: "bold" }} />;
});

const FeedbackDialog = (props: IProps) => {
    const outerTheme = useTheme();
    const [showSuccessAlert, setShowSuccessAlert] = React.useState<boolean>(false);
    const [allChecks, setAllChecks] = React.useState({
        isHarmfulOrUnsafe: false,
        isTrue: false,
        isHelpful: false
    });

    const submitFeedback = () => {
        props.setFeedbackShow(false);
        setShowSuccessAlert(true);
        setAllChecks({
            isHarmfulOrUnsafe: false,
            isTrue: false,
            isHelpful: false
        });
    };
    return (
        <div>
            <BootstrapDialog
                keepMounted
                TransitionComponent={Transition}
                onClose={() => props.setFeedbackShow(false)}
                aria-labelledby="feedback-form"
                open={props.feedbackShow}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle sx={{ m: 0, p: 2 }} id="feedback-form">
                    <div style={{ display: "flex", alignItems: "center" }}>
                        <div
                            className={styles.dialogHeaderBlock}
                            style={{
                                backgroundColor: props.feedbackStatus === "good" ? "#d2f4d3" : "#fee2e2"
                            }}
                        >
                            {props.feedbackStatus === "good" ? (
                                <ThumbUpOffAltIcon className={styles.iconSize} style={{ color: "#1a7f64" }} />
                            ) : (
                                <ThumbDownOffAltIcon className={styles.iconSize} style={{ color: "#e96c6c" }} />
                            )}
                        </div>{" "}
                        <p className={styles.title}>Feedback</p>
                    </div>
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={() => props.setFeedbackShow(false)}
                    sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: theme => theme.palette.grey[500]
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                        <TextField
                            fullWidth
                            label={
                                props.feedbackStatus === "good"
                                    ? "What do you like about the response?"
                                    : "What was the issue with the response? How could it be improved?"
                            }
                            multiline
                            rows={4}
                            variant="filled"
                        />
                    </ThemeProvider>
                    {props.feedbackStatus === "bad" && <FeedbackDialogChecks allChecks={allChecks} setAllChecks={setAllChecks} />}
                </DialogContent>
                <DialogActions>
                    <Button className={styles.btn} autoFocus onClick={() => submitFeedback()} variant="outlined">
                        Submit feedback
                    </Button>
                </DialogActions>
            </BootstrapDialog>

            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={showSuccessAlert}
                autoHideDuration={5000}
                onClose={() => setShowSuccessAlert(false)}
            >
                <Alert onClose={() => setShowSuccessAlert(false)} severity="success" sx={{ width: "100%" }}>
                    Your feedback submitted successfully.
                </Alert>
            </Snackbar>
        </div>
    );
};

export default FeedbackDialog;
