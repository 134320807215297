import React, { useEffect, useState } from "react";
import { theme } from "../../../consts/versionB/consts/theme";
import { IconButton, Stack } from "@fluentui/react";
import { AccordionDetails, AccordionProps, AccordionSummaryProps, Grid, Tooltip, Typography, Zoom, styled } from "@mui/material";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import styles from "./QuesAnsBox.module.css";
import FeedbackDialog from "../Common/FeedbackDialog";
import ThoughtsDrawer from "./ThoughtsDrawer";
import clipboardCopy from "clipboard-copy";

interface IAllReferences {
    [key: string]: {
        path: string;
        page: number;
        text: string;
    };
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}));

const HTMLFormattedAnswer = ({
    answer,
    showDocs,
    isLoading,
    allAnswersLen,
    objIndex,
    callEditedQuery,
    answerRef
}: {
    answer: [{ role: string; content: string }, { role: string; content: string; references: IAllReferences; thoughts?: string }];
    showDocs: Function;
    isLoading: boolean;
    allAnswersLen: number;
    objIndex: number;
    callEditedQuery: Function;
    answerRef: any;
}) => {
    const [expanded, setExpanded] = useState<boolean>(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [isThoughtsDrawerOpen, setIsThoughtsDrawerOpen] = useState<boolean>(false);
    const [thoughtsContent, setThoughtsContent] = useState<string>("");
    const [feedbackShow, setFeedbackShow] = useState<boolean>(false);
    const [feedbackStatus, setFeedbackStatus] = useState<string>("");

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (isCopied) {
                setIsCopied(false);
            }
        }, 2000);

        return () => {
            clearTimeout(timeOut);
        };
    }, [isCopied]);

    const openPdfWithRef = (path: string, page: number, text: string) => {
        showDocs(path, page, text);
    };

    useEffect(() => {
        window.openPdfWithRef = openPdfWithRef;

        return () => {
            // @ts-ignore: Ignore TypeScript error for window property deletion
            window.openPdfWithRef = undefined;
        };
    }, []);

    const manipulateData = (content: string, references: IAllReferences) => {
        const match = content.match(/<cite>(.*?)<\/cite>/g);
        let uniqArr = [...new Set(match)].sort();
        let generatedObj = uniqArr.map((string: string, index: number) => {
            const match = string.match(/<cite>(.*?)<\/cite>/);
            const referenceKey = Object.keys(references).filter(answer => match && answer === match[1])[0];
            // const passingValue = JSON.stringify(references[referenceKey]);
            const page = JSON.stringify(references[referenceKey].page);
            const path = JSON.stringify(references[referenceKey].path);
            const text = JSON.stringify(references[referenceKey].text.replace(/'/g, '"'));
            return {
                actualVal: match && `<cite>${match[1]}</cite>`,
                addedVal: `<span id='test' onclick='window.openPdfWithRef(${path},${page},${text})' style='background:#d3d3d3; margin-right:2px; font-size:10px; padding:2px; white-space:nowrap; cursor: pointer;'> ${
                    index + 1
                }</span>`,
                newRefNum: index + 1
            };
        });

        let allActualVal: string[] = [];
        generatedObj.map((answer: { actualVal: string | null; addedVal: string; newRefNum: number }) => allActualVal.push(answer.actualVal!));

        let allNewVal: string[] = [];
        generatedObj.map(answer => allNewVal.push(answer.addedVal));

        for (let i = 0; i < allActualVal.length; i++) {
            content = content.replaceAll(allActualVal[i], allNewVal[i]);
        }

        // manuplating references keys
        let updatedReferences: any = {};
        for (const key in references) {
            if (references.hasOwnProperty(key)) {
                const extractKey = generatedObj.filter(answer => answer.actualVal === `<cite>${key}</cite>`);
                if (extractKey[0]) {
                    const newKey = extractKey[0].newRefNum;
                    updatedReferences[newKey] = references[key];
                }
            }
        }
        return [content, updatedReferences];
    };

    const handleHTMLContent = () => {
        if (answer[1].content?.includes("<table>")) {
            return (
                <div dangerouslySetInnerHTML={{ __html: manipulateData(answer[1].content, answer[1].references)[0] }} style={theme.typography.chat.answer} />
            );
        } else if (answer[1].content?.includes("\n") || answer[1].content?.includes("<cite>")) {
            let removedNewLine = answer[1].content?.replace(/\n/g, "<br />");
            return (
                <div
                    dangerouslySetInnerHTML={{ __html: manipulateData(removedNewLine, answer[1].references)[0] }}
                    style={{ ...theme.typography.chat.answer, wordBreak: "break-word" }}
                />
            );
        } else {
            return <p style={theme.typography.chat.answer}>{answer[1].content}</p>;
        }
    };

    const showThoughts = () => {
        setThoughtsContent(answer[1]?.thoughts!);
        setIsThoughtsDrawerOpen(true);
    };

    const shareGoodFeedback = () => {
        setFeedbackStatus("good");
        setFeedbackShow(true);
    };

    const shareBadFeedback = () => {
        setFeedbackStatus("bad");
        setFeedbackShow(true);
    };

    return (
        <>
            <Stack className={styles.answerContainer} verticalAlign="space-between">
                <Stack.Item grow>{handleHTMLContent()}</Stack.Item>
                <Stack.Item grow style={{ marginTop: 10 }}>
                    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)} sx={{ borderRadius: 2 }}>
                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                            <Typography>Sources</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                {Object.keys(manipulateData(answer[1].content, answer[1].references)[1]).map((reference: string, index: number) => {
                                    let obj: IAllReferences = manipulateData(answer[1].content, answer[1].references)[1];
                                    return (
                                        <Grid item lg={4} md={6} sm={12} xs={12} key={index.toString()}>
                                            <Grid
                                                sx={{ py: 1, px: 1 }}
                                                container
                                                style={{
                                                    display: "flex",

                                                    alignItems: "center",
                                                    border: "1px solid #eee"
                                                }}
                                            >
                                                <Tooltip TransitionComponent={Zoom} title={obj[reference].text} followCursor arrow>
                                                    <Typography style={{ fontWeight: 400, fontSize: 14, wordBreak: "break-all" }}>
                                                        <span>
                                                            <b>{reference}. </b>
                                                        </span>
                                                        {obj[reference].text.substring(0, 50) + "..."}
                                                    </Typography>
                                                </Tooltip>
                                                <Typography
                                                    style={{ paddingTop: 5, cursor: "pointer", color: "#0000EE", fontSize: 12 }}
                                                    onClick={() => showDocs(obj[reference].path, obj[reference].page, obj[reference].text)}
                                                >
                                                    {obj[reference].path} : Page {obj[reference].page}
                                                </Typography>
                                            </Grid>
                                        </Grid>
                                    );
                                })}
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                </Stack.Item>
                <Stack.Item grow style={{ marginTop: 10 }}>
                    <IconButton
                        className={styles.actionIcons}
                        iconProps={{ iconName: isCopied ? "Checkmark" : "Copy" }}
                        size={30}
                        onClick={() => {
                            if (answerRef.current) {
                                setIsCopied(true);
                                // format
                                const text = answer[1]?.content.replaceAll(/<cite>\d+<\/cite>/g, "");
                                //@ts-ignore
                                clipboardCopy(text)
                                    .then(() => {
                                        console.log("Text copied to clipboard");
                                    })
                                    .catch((err: Error) => {
                                        console.log(err);
                                    });
                            }
                        }}
                    />

                    <IconButton className={styles.actionIcons} iconProps={{ iconName: "Lightbulb" }} size={30} onClick={() => showThoughts()} />
                    <IconButton className={styles.actionIcons} iconProps={{ iconName: "Like" }} size={30} onClick={() => shareGoodFeedback()} />
                    <IconButton className={styles.actionIcons} iconProps={{ iconName: "Dislike" }} size={30} onClick={() => shareBadFeedback()} />
                    {!isLoading && allAnswersLen - 1 === objIndex ? (
                        <IconButton
                            className={styles.actionIcons}
                            iconProps={{ iconName: "Refresh" }}
                            size={30}
                            onClick={() => callEditedQuery(objIndex, answer[0].content)}
                        />
                    ) : (
                        false
                    )}
                </Stack.Item>
            </Stack>
            <FeedbackDialog feedbackShow={feedbackShow} setFeedbackShow={setFeedbackShow} feedbackStatus={feedbackStatus} />
            <ThoughtsDrawer isOpen={isThoughtsDrawerOpen} setIsOpen={setIsThoughtsDrawerOpen} thoughtsContent={thoughtsContent!} />
        </>
    );
};

export default HTMLFormattedAnswer;
