import * as React from "react";
import { Box, Grid, ToggleButton, Tooltip } from "@mui/material";
import Drawer from "@mui/material/Drawer";
import AppBar from "@mui/material/AppBar";
import CssBaseline from "@mui/material/CssBaseline";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Logo from "../Logo/Logo";
import { ClearChatButton } from "../ClearChatButton";
import styles from "./PolicyChatLayout.module.css";
import { colors } from "../../consts/colors";
import DetailsDrawerAccordion from "./DetailsDrawerAccordion";
import DocumentsDrawer from "./DocumentsDrawer";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import MilestonesVertical from "../ClaimDetails/MilestonesVertical";
import logo from "../../assets/coveredg_logo.png";
// import logoCrawford from "../../assets/logo-crawford-svg.svg";
import AuthContext from "../../context/AuthProvider";
import ClaimOutcomeStepper from "../ClaimDetails/ClaimOutcomesStepper";

const drawerWidth = 240;

interface IComponent {
    children: React.ReactNode;
    detailsData: any[];
    clearHistory: Function;
}

export default function PolicyChatLayout({ children, detailsData, clearHistory }: IComponent) {
    const [open, setOpen] = React.useState(true);
    const [detailDrawerOpen, setDetailDrawerOpen] = React.useState(true);
    const [docsDrawerOpen, setDocsDrawerOpen] = React.useState(true);
    const [mobileDetailsDrawerOpen, setMobileDetailsDrawerOpen] = React.useState<boolean>(false);
    const [mobileDocsDrawerOpen, setMobileDocsDrawerOpen] = React.useState<boolean>(false);
    const [screenSize, setScreenSize] = React.useState(getCurrentDimension());
    const [alignment, setAlignment] = React.useState("Documents");

    React.useEffect(() => {
        const updateDimension = () => {
            setScreenSize(getCurrentDimension());
        };
        window.addEventListener("resize", updateDimension);
        // closing already opened drawer
        if (screenSize.width < 1080) {
            setOpen(false);
        } else {
            setOpen(true);
        }
        return () => {
            window.removeEventListener("resize", updateDimension);
        };
    }, [screenSize]);

    const handleDetailsDrawer = (state: boolean) => {
        if (screenSize.width >= 1080) {
            setDetailDrawerOpen(state);
        } else {
            setMobileDetailsDrawerOpen(state);
        }
    };

    const handleDocsDrawer = (state: boolean) => {
        if (screenSize.width >= 1080) {
            setDocsDrawerOpen(state);
        } else {
            setMobileDocsDrawerOpen(state);
        }
    };

    function getCurrentDimension() {
        return {
            width: window.innerWidth,
            height: window.innerHeight
        };
    }

    const handleChange = (event: React.MouseEvent<HTMLElement>, newAlignment: string) => {
        setAlignment(newAlignment);
    };

    return (
        <Box sx={{ display: "flex" }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ zIndex: theme => theme.zIndex.drawer + 1, backgroundColor: colors.primaryDark }}>
                <Toolbar>
                    <Grid container>
                        <Grid item xs={4} style={{ display: "flex", justifyContent: "flex-start", alignItems: "center" }}>
                            <Logo />
                            {/* <img
                                src={logo}
                                width={"auto"}
                                height={45}
                                style={{
                                    cursor: "pointer"
                                }}
                            /> */}
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                            <Typography variant="h6" component="div">
                                AI for claim coverage assistance
                            </Typography>
                        </Grid>
                        <Grid item xs={4} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            <div className={styles.commandsContainer}>
                                <ClearChatButton onClick={() => clearHistory(true)} className={styles.commandButton} />
                            </div>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>

            {/* // Desktop view drawer */}
            {open && detailDrawerOpen && (
                <Drawer
                    variant="permanent"
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }
                    }}
                >
                    <Toolbar />
                    <Box sx={{ overflow: "auto" }}>
                        <div style={{ display: "flex", justifyContent: "right" }}>
                            <Tooltip title="Details">
                                <ToggleButton
                                    value="list"
                                    aria-label="list"
                                    size="small"
                                    style={{ margin: 10, height: 30, width: 30 }}
                                    onClick={() => handleDetailsDrawer(false)}
                                >
                                    <ArrowForwardIosIcon style={{ transform: "rotate(180deg)", fontSize: 16 }} />
                                </ToggleButton>
                            </Tooltip>
                        </div>
                        <DetailsDrawerAccordion data={detailsData} />
                    </Box>
                </Drawer>
            )}

            {/* // Mobile view drawer */}
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }
                }}
                anchor={"left"}
                open={mobileDetailsDrawerOpen}
                onClose={() => setMobileDetailsDrawerOpen(false)}
            >
                <Toolbar />
                <Box sx={{ overflow: "auto" }}>
                    <DetailsDrawerAccordion data={detailsData} />
                </Box>
            </Drawer>
            {!detailDrawerOpen && screenSize.width >= 1080 && (
                <div style={{ background: "#fff" }}>
                    <Toolbar />
                    <Tooltip title="Details">
                        <ToggleButton
                            value="list"
                            aria-label="list"
                            size="small"
                            style={{ margin: 10, height: 30, width: 30 }}
                            onClick={() => handleDetailsDrawer(true)}
                        >
                            <ArrowForwardIosIcon style={{ fontSize: 16 }} />
                        </ToggleButton>
                    </Tooltip>
                </div>
            )}
            {screenSize.width < 1080 && (
                <div style={{ background: "#fff" }}>
                    <Toolbar />
                    <Tooltip title="Details">
                        <ToggleButton
                            value="list"
                            aria-label="list"
                            size="small"
                            style={{ margin: 10, height: 30, width: 30 }}
                            onClick={() => handleDetailsDrawer(true)}
                        >
                            <ArrowForwardIosIcon style={{ fontSize: 16 }} />
                        </ToggleButton>
                    </Tooltip>
                </div>
            )}
            <Box component="main" sx={{ flexGrow: 1 }}>
                <Toolbar />
                {children}
            </Box>
            {!docsDrawerOpen && screenSize.width >= 1080 && (
                <div style={{ background: "#fff" }}>
                    <Toolbar />
                    <Tooltip title="Documents">
                        <ToggleButton
                            value="list"
                            aria-label="list"
                            size="small"
                            style={{ margin: 10, height: 30, width: 30 }}
                            onClick={() => handleDocsDrawer(true)}
                        >
                            <ArrowForwardIosIcon style={{ transform: "rotate(180deg)", fontSize: 16 }} />
                        </ToggleButton>
                    </Tooltip>
                </div>
            )}

            {screenSize.width < 1080 && (
                <div style={{ background: "#fff" }}>
                    <Toolbar />
                    <Tooltip title="Documents">
                        <ToggleButton
                            value="list"
                            aria-label="list"
                            size="small"
                            style={{ margin: 10, height: 30, width: 30 }}
                            onClick={() => handleDocsDrawer(true)}
                        >
                            <ArrowForwardIosIcon style={{ transform: "rotate(180deg)", fontSize: 16 }} />
                        </ToggleButton>
                    </Tooltip>
                </div>
            )}

            {/* // Desktop view drawer document */}
            {open && docsDrawerOpen && (
                <Drawer
                    variant={"permanent"}
                    sx={{
                        width: drawerWidth,
                        flexShrink: 0,
                        [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }
                    }}
                    anchor="right"
                >
                    <Toolbar />
                    <Box sx={{ overflow: "auto" }}>
                        <Tooltip title="Documents">
                            <ToggleButton
                                value="list"
                                aria-label="list"
                                size="small"
                                style={{ margin: 10, height: 30, width: 30 }}
                                onClick={() => handleDocsDrawer(false)}
                            >
                                <ArrowForwardIosIcon style={{ fontSize: 16 }} />
                            </ToggleButton>
                        </Tooltip>
                        <ToggleButtonGroup size="small" color="primary" value={alignment} exclusive onChange={handleChange} aria-label="Platform">
                            <ToggleButton style={{ textTransform: "capitalize" }} value="Documents">
                                Documents
                            </ToggleButton>
                            <ToggleButton style={{ textTransform: "capitalize" }} value="Milestones">
                                Outcomes
                            </ToggleButton>
                        </ToggleButtonGroup>
                        {alignment === "Documents" ? <DocumentsDrawer data={detailsData} /> : <ClaimOutcomeStepper />}
                    </Box>
                </Drawer>
            )}

            {/* // Mobile view drawer */}
            <Drawer
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: "border-box" }
                }}
                anchor={"right"}
                open={mobileDocsDrawerOpen}
                onClose={() => setMobileDocsDrawerOpen(false)}
            >
                <Toolbar />
                <Box sx={{ overflow: "auto", paddingTop: 2 }}>
                    <ToggleButtonGroup
                        style={{ display: "flex", justifyContent: "center", marginBottom: 10 }}
                        size="small"
                        color="primary"
                        value={alignment}
                        exclusive
                        onChange={handleChange}
                        aria-label="Platform"
                    >
                        <ToggleButton style={{ textTransform: "capitalize" }} value="Documents">
                            Documents
                        </ToggleButton>
                        <ToggleButton style={{ textTransform: "capitalize" }} value="Milestones">
                            Outcomes
                        </ToggleButton>
                    </ToggleButtonGroup>
                    {alignment === "Documents" ? <DocumentsDrawer data={detailsData} /> : <ClaimOutcomeStepper />}
                </Box>
            </Drawer>
        </Box>
    );
}
