import { useNavigate } from "react-router-dom";
// import coveredg_logo from "../../../assets/versionB/coveredg-logo.svg";
// import coveredg_logo_blue from "../../../assets/versionB/coveredg-logo-blue.svg";
import coveredg_logo_blue from "../../../assets/versionB/coverage_ai_blue.svg";
import coveredg_logo_white from "../../../assets/versionB/coverage_ai_white.svg";
const CoveredLogo = ({ bg, width }: { bg: string; width: string }) => {
    const navigate = useNavigate();
    return (
        <img
            src={bg === "light" ? coveredg_logo_white : coveredg_logo_blue}
            width={width}
            height={"auto"}
            onClick={() => navigate("/")}
            style={{
                cursor: "pointer",
                display: "inline-block"
            }}
        />
    );
};

export default CoveredLogo;
