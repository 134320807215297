import { useNavigate } from "react-router-dom";
import logo from "../../../assets/versionB/logo-crawford.svg";
import logoDark from "../../../assets/versionB/crawford-logo-dark.svg";

const Logo = ({ color, width }: { color: string; width: number | string }) => {
    const navigate = useNavigate();
    return (
        <img
            src={color === "dark" ? logoDark : logo}
            width={width}
            height={"auto"}
            onClick={() => navigate("/")}
            style={{
                cursor: "pointer"
            }}
        />
    );
};

export default Logo;
