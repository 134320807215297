import { useEffect, useRef, useState, Fragment } from "react";
import Header from "../../components/Header/Header";
import { Box, Grid, Typography } from "@mui/material";
import Logo from "../../components/Logo/Logo";
import { InputField } from "../../components/CodexUI/InputField";
import QuesAnsBox from "../../components/CodexUI/QuesAnsBox";
import { colors } from "../../consts/colors";
import styles from "./CodexChat.module.css";
import { Approaches, chatWithCodex } from "../../api";
import { SparkleFilled, WindowDatabase24Regular } from "@fluentui/react-icons";
import AbortControllerUI from "../../components/Common/AbortController";
import { UserChatMessage } from "../../components/UserChatMessage";
import { AnswerLoading } from "../../components/Common/AnswerLoading";
import DatabaseSelect from "../../components/CodexUI/DatabaseSelect";

import StorageIcon from "@mui/icons-material/Storage";
import { Text } from "@fluentui/react";
import ErrorSnackbar from "../../components/ErrorSnackbar/ErrorSnackbar";
import { SelectButton } from "../../components/SelectButton/SelectButton";

interface Item {
    question: string;
    selectedDB: string;
    response: string;
    thoughts: string;
}

const CodexChat = () => {
    const abortController = useRef<any>(null);
    const lastQueryRef = useRef<string>("");
    const messagesEndRef = useRef<any>(null);
    const [userTextBox, setUserTextBox] = useState<string>("");
    const [isLoading, setIsLoading] = useState(false);
    const [chats, setChats] = useState<Item[]>([]);
    const [openDatabaseDialog, setOpenDatabaseDialog] = useState<boolean>(false);
    const [selectedDB, setSelectedDB] = useState<string>("edl_pub_glld");
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    useEffect(() => {
        isLoading && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [isLoading]);

    const developChatTree = (question: string, response: string, thoughts: string) => {
        const tempChat = [...chats];
        tempChat.push({
            question,
            selectedDB,
            response,
            thoughts
        });

        setChats(tempChat);
    };

    const formatThoughts = async (thoughts: string) => {
        let removedNewLine = await thoughts.replace(/\n/g, "<br />");
        let removedSpace = await removedNewLine.replace(/\t/g, "&emsp;");
        return removedSpace;
    };
    const callApiRequest = async (question: string) => {
        lastQueryRef.current = question;

        setIsLoading(true);

        // Setting abort request
        abortController.current = new AbortController();
        const abortSignal = abortController.current.signal;
        try {
            const request = {
                approach: Approaches.DatabaseChat,
                question: question,
                db_name: selectedDB
            };
            const result = await chatWithCodex(request, abortSignal);
            const formattedThoughts: string = await formatThoughts(result.thoughts);
            developChatTree(question, result.answer, formattedThoughts);
            setIsLoading(false);
            setUserTextBox("");
        } catch (e) {
            setIsLoading(false);
            setShowErrorAlert(true);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <Fragment>
            <div
                style={{
                    display: "flex",
                    flexDirection: "column",
                    height: "100%"
                }}
            >
                <div className={styles.container}>
                    <div className={styles.topHeader}>
                        <Header>
                            <Grid container>
                                <Grid item xs={12} sm={4}>
                                    <Logo />
                                </Grid>
                                <Grid item xs={6} sm={4} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Typography variant="h6" component="div" style={{ textAlign: "center" }}>
                                        Codex Chat
                                    </Typography>
                                </Grid>
                                <Grid item xs={6} sm={4} className={styles.headerOptions}>
                                    <SelectButton
                                        icon={<WindowDatabase24Regular />}
                                        btnText="Select database"
                                        className={styles.commandButton}
                                        onClick={() => setOpenDatabaseDialog(true)}
                                        disabled={false}
                                    />
                                </Grid>
                            </Grid>
                        </Header>
                    </div>
                    <div className={styles.chatRoot}>
                        <div className={styles.chatContainer}>
                            {!chats[0] && !isLoading && (
                                <div className={styles.chatEmptyState}>
                                    <SparkleFilled fontSize={"120px"} primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Chat logo" />
                                    <h1 className={styles.chatEmptyStateTitle}>Chat with your data</h1>
                                </div>
                            )}
                            <div className={styles.chatMessageStream}>
                                {chats.map((item: Item) => {
                                    return <QuesAnsBox item={item} />;
                                })}
                                {isLoading && (
                                    <>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "auto",
                                                margin: "10px 0px 10px 0px"
                                            }}
                                        >
                                            <div style={{ marginLeft: 10, marginRight: 10 }}>
                                                <UserChatMessage message={lastQueryRef.current} />
                                                <div className={styles.chatMessageGptMinWidth}>
                                                    <AnswerLoading />
                                                </div>
                                            </div>
                                        </Box>
                                        <AbortControllerUI abortController={abortController} />
                                    </>
                                )}
                                <span ref={messagesEndRef}></span>
                            </div>
                            <div className={styles.questionInputContainer}>
                                <InputField
                                    clearOnSend
                                    placeholder={
                                        !chats[0] && !isLoading
                                            ? "Type a query (e.g. What peril / cause of loss is the most prevalent in January 2022 in USA?)"
                                            : ""
                                    }
                                    disabled={isLoading}
                                    onSend={query => callApiRequest(query)}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DatabaseSelect
                setOpenDatabaseDialog={setOpenDatabaseDialog}
                openDatabaseDialog={openDatabaseDialog}
                setSelectedDB={setSelectedDB}
                selectedDb={selectedDB}
            />

            {/* // Error handler */}
            <ErrorSnackbar showErrorAlert={showErrorAlert} setShowErrorAlert={setShowErrorAlert} />
        </Fragment>
    );
};

export default CodexChat;
