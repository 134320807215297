import { AskRequest, AskResponse, ChatRequest, DocumentMatrixChat } from "./models";

export async function askApi(options: AskRequest): Promise<AskResponse> {
    const response = await fetch("/ask", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            question: options.question,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatApi(options: ChatRequest): Promise<AskResponse> {
    const response = await fetch("/chat", {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: options.approach,
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}
export async function chatWithGPTApi(options: ChatRequest, signal: any): Promise<AskResponse> {
    const response = await fetch("/chatwithgpt", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            history: options.history,
            approach: "rcr",
            overrides: {
                semantic_ranker: options.overrides?.semanticRanker,
                semantic_captions: options.overrides?.semanticCaptions,
                top: options.overrides?.top,
                temperature: options.overrides?.temperature,
                prompt_template: options.overrides?.promptTemplate,
                prompt_template_prefix: options.overrides?.promptTemplatePrefix,
                prompt_template_suffix: options.overrides?.promptTemplateSuffix,
                exclude_category: options.overrides?.excludeCategory,
                suggest_followup_questions: options.overrides?.suggestFollowupQuestions
            }
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function chatWithCodex(options: { approach: string; question: string; db_name: string }, signal: any): Promise<any> {
    const response = await fetch("/dbc", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            question: options.question,
            db_name: options.db_name
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function queryWithLangchain(options: { approach: string; query: string }, signal: any): Promise<any> {
    const response = await fetch("/pdfLangchain", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            query: options.query
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function documentQueryBetaAPI(options: { approach: string; query: string; history: DocumentMatrixChat }, signal: any): Promise<any> {
    const response = await fetch("/TheMatrixChat", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            query: options.query,
            history: options.history
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function policyChatAPI(options: { approach: string; query: string; history: Object[]; policy: string }, signal: any): Promise<any> {
    const response = await fetch("/policygpt", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            query: options.query,
            history: options.history,
            policy: options.policy
        })
    });

    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export async function jsonChatAPI(options: { approach: string; query: string; history: Object[]; policy: string }, signal: any): Promise<any> {
    const response = await fetch("/json", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            query: options.query,
            history: options.history,
            policy: options.policy
        })
    });
    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function ragChatAPI(options: { approach: string; query: string; history: Object[]; policy: string }, signal: any): Promise<any> {
    const response = await fetch("/rag", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            query: options.query,
            history: options.history,
            policy: options.policy
        })
    });
    const parsedResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }
    return parsedResponse;
}

export async function queryWithCSV(options: { approach: string; query: string }, signal: any): Promise<any> {
    const response = await fetch("/csv", {
        method: "POST",
        signal,
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify({
            approach: options.approach,
            question: options.query
        })
    });

    const parsedResponse: AskResponse = await response.json();
    if (response.status > 299 || !response.ok) {
        throw Error(parsedResponse.error || "Unknown error");
    }

    return parsedResponse;
}

export function getCitationFilePath(citation: string): string {
    return `/content/${citation}`;
}
