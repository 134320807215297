import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import InputLabel from "@mui/material/InputLabel";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import {Snackbar, Alert} from "@mui/material";
import Select, { SelectChangeEvent } from "@mui/material/Select";

interface IProps {
    setOpenDatabaseDialog: Function;
    openDatabaseDialog: boolean;
    setSelectedDB: Function;
    selectedDb: string;
}
export default function DatabaseSelect(props: IProps) {
    const [selectedOption, setSelectedOption] = React.useState<string>("");
    const [showSuccessAlert, setShowSuccessAlert] = React.useState<boolean>(false);

    React.useEffect(() => {
        setSelectedOption(props.selectedDb);
    }, [props.openDatabaseDialog]);

    const handleChange = (event: SelectChangeEvent<string>) => {
        setSelectedOption(event.target.value);
    };

    const activateDatabase = () => {
        props.setSelectedDB(selectedOption);
        props.setOpenDatabaseDialog(false);
        setShowSuccessAlert(true)
    };

    return (
        <div>
            <Dialog disableEscapeKeyDown open={props.openDatabaseDialog} onClose={() => props.setOpenDatabaseDialog(false)}>
                <DialogTitle>Select Database</DialogTitle>
                <DialogContent>
                    <Box component="form" sx={{ display: "flex", flexWrap: "wrap" }}>
                        <FormControl sx={{ m: 1, width: 400 }}>
                            <InputLabel id="select-box">Database</InputLabel>
                            <Select
                                labelId="select-box"
                                id="dialog-select"
                                value={selectedOption}
                                onChange={handleChange}
                                input={<OutlinedInput label="Database" />}
                            >
                                <MenuItem value={"edl_global_analytical_models"}>edl_global_analytical_models</MenuItem>
                                <MenuItem value={"edl_pub_glld"}>edl_pub_glld</MenuItem>
                            </Select>
                        </FormControl>
                    </Box>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.setOpenDatabaseDialog(false)}>Cancel</Button>
                    <Button onClick={() => activateDatabase()}>Activate</Button>
                </DialogActions>
            </Dialog>

            {/* // On success */}
            <Snackbar
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                open={showSuccessAlert}
                autoHideDuration={5000}
                onClose={() => setShowSuccessAlert(false)}
            >
                <Alert onClose={() => setShowSuccessAlert(false)} severity="success" sx={{ width: "100%" }}>
                    {selectedOption} database activated.
                </Alert>
            </Snackbar>
        </div>
    );
}
