import { ReactNode, createContext, useState, useContext, useEffect } from "react";
interface IClaimOutcome {
    selectedClaim: number;
    setSelectedClaim: Function;
}

export const ClaimContext = createContext<IClaimOutcome | undefined>(undefined);

const ClaimOutcomeProvider = ({ children }: { children: ReactNode }) => {
    const [selectedClaim, setSelectedClaim] = useState<number>(0);

    return <ClaimContext.Provider value={{ selectedClaim, setSelectedClaim }}>{children}</ClaimContext.Provider>;
};

export default ClaimOutcomeProvider;
