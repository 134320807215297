import { IClaimData, all_claims, berkley_claims, canada_claims, zurich_claims, chubb_claims, openly_claims } from "./data";
import { dummy_outcomes, dummy_policies_data, searched_claims } from "./dummy_data";
import { ClaimDetailObj, OutcomeObj, SearchedClaim, DisclaimerObj, IPolicies, IClaimDocument } from "./models";

export async function searchClaim(options: { selectedOption: string; value: string }): Promise<SearchedClaim[]> {
    // const response = await fetch("/api/Claim/SearchClaim", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //         selectedOption: options.selectedOption,
    //         value: options.value
    //     })
    // });

    // const parsedResponse: SearchResponse = await response.json();
    // return parsedResponse;
    return searched_claims;
}

export async function getOutcomes(): Promise<OutcomeObj[]> {
    // const response = await fetch("/api/Claim/GetOutComeDetails", {
    //     method: "GET",
    //     headers: { "Content-Type": "application/json" },
    // });
    // const parsedResponse: SearchResponse = await response.json();
    // return parsedResponse;
    return dummy_outcomes;
}

export async function getClaimDetails(options: { claimNo: string; policyValue: string }): Promise<IClaimData> {
    // const response = await fetch("/api/Claim/ClaimDetails", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //         claimNo: options.claimNo
    //     })
    // });

    // const parsedResponse: ClaimDetailObj = await response.json();
    // return parsedResponse;
    const claimDetails = (
        options.policyValue === "Kemper"
            ? all_claims
            : options.policyValue === "Canada"
            ? canada_claims
            : options.policyValue === "Berkley"
            ? berkley_claims
            : options.policyValue === "Zurich"
            ? zurich_claims
            : options.policyValue === "Chubb"
            ? chubb_claims
            : options.policyValue === "Openly"
            ? openly_claims
            : []
    ).filter((item: IClaimData) => item.claimNo === options.claimNo);
    return claimDetails[0];
}

export async function getClaimDocuments(options: { claimNo: string; policyValue: string }): Promise<IClaimDocument> {
    // const response = await fetch("/api/Claim/ClaimDocumentsDetails", {
    //     method: "POST",
    //     headers: { "Content-Type": "application/json" },
    //     body: JSON.stringify({
    //         claimNo: options.claimNo
    //     })
    // });
    // const parsedResponse: ClaimDetailObj = await response.json();
    // return parsedResponse;
    return {
        documentName: "",
        documentPath: "",
        category: "",
        uploadedAt: "",
        processedAt: "",
        status: ""
    };
}

export async function getDisclaimerNote(): Promise<DisclaimerObj> {
    // const response = await fetch("/disclaimer", {
    //     method: "GET",
    //     headers: { "Content-Type": "application/json" },
    // });
    // const parsedResponse: DisclaimerObj = await response.json();
    // return parsedResponse;
    return {
        html: "This AI-generated response are intended to assist in the decision-making process. It is imperative to meticulously review and validate the information provided to ensure its accuracy and applicability to each unique case.   <br><br> Always align insights with detailed policy wording, pertinent legal guidelines, and specific client instructions to guarantee precision and accuracy in coverage decisions."
    };
}

export async function getDatabaseForCodexChat(): Promise<string[]> {
    // const response = await fetch("/dbs", {
    //     method: "GET",
    //     headers: { "Content-Type": "application/json" },
    // });
    // const parsedResponse: String[] = await response.json();
    // return parsedResponse;
    return ["edl_global_analytical_models", "edl_pub_glld"];
}

export async function getAllPoliciesAPI(): Promise<IPolicies[]> {
    // const response = await fetch("/api/Claim/GetPolicies", {
    //     method: "GET",
    //     headers: { "Content-Type": "application/json" }
    // });
    // const parsedResponse: IPolicies[] = await response.json();
    // return parsedResponse;
    return dummy_policies_data;
}
