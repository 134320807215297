import { useNavigate } from "react-router-dom";
import logo from "../../assets/logo-crawford.svg";

const Logo = () => {
    const navigate = useNavigate();
    return (
        <img
            src={logo}
            width={160}
            height={50}
            onClick={() => navigate("/apps")}
            style={{
                cursor: "pointer"
            }}
        />
    );
};

export default Logo;
