import { useEffect, useRef, useState } from "react";
import clipboardCopy from "clipboard-copy";
import { AccordionDetails, Grid, Tooltip, Typography, Card, Input, Zoom } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Box from "@mui/material/Box";
import { IconButton, Stack } from "@fluentui/react";

import styles from "./QuesAnsBox.module.css";
import CitationDrawer from "./CitationDrawer";
import FeedbackDialog from "../Common/FeedbackDialog";
import coveredgIcon from "../../assets/ai-logo.svg";
import ThoughtsDrawer from ".//ThoughtsDrawer";

interface IProps {
    item: [{ role: string; content: string }, { role: string; content: string; references: IAllReferences; thought?: any }];
    objIndex: number;
    allAnswersLen: number;
    callEditedQuery: (query: string) => void;
    isLoading: boolean;
    operator: string;
}

interface IReference {
    path: string;
    page: number;
    text: string;
}

interface IAllReferences {
    [key: string]: {
        path: string;
        page: number;
        text: string;
    };
}

const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
        borderBottom: 0
    },
    "&:before": {
        display: "none"
    }
}));

const AccordionSummary = styled((props: AccordionSummaryProps) => (
    <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
))(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
    flexDirection: "row-reverse",
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
        transform: "rotate(90deg)"
    },
    "& .MuiAccordionSummary-content": {
        marginLeft: theme.spacing(1)
    }
}));

export default function QuesAnsBox({ item, objIndex, allAnswersLen, callEditedQuery, isLoading, operator }: IProps) {
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const answerRef = useRef(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [content, setContent] = useState<string>("");
    const [isThoughtsDrawerOpen, setIsThoughtsDrawerOpen] = useState<boolean>(false);
    const [thoughtsContent, setThoughtsContent] = useState<string>("");
    const [feedbackShow, setFeedbackShow] = useState<boolean>(false);
    const [feedbackStatus, setFeedbackStatus] = useState<string>("");
    const [citationMeta, setCitationMeta] = useState({
        path: "",
        page: 0
    });

    const openPdfWithRef = (data: IReference) => {
        showDocument(data.path, data.page);
    };

    useEffect(() => {
        window.openPdfWithRef = openPdfWithRef;
    }, []);

    useEffect(() => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, [item]);

    const manipulateData = (content: string, references: IAllReferences) => {
        const match = content.match(/<cite>(.*?)<\/cite>/g);
        let uniqArr = [...new Set(match)].sort();
        let generatedObj = uniqArr.map((string: string, index: number) => {
            const match = string.match(/<cite>(.*?)<\/cite>/);
            const referenceKey = Object.keys(references).filter(item => match && item === match[1])[0];
            const passingValue = JSON.stringify(references[referenceKey]);
            return {
                actualVal: match && `<cite>${match[1]}</cite>`,
                addedVal: `<span id='test' onclick='window.openPdfWithRef(${passingValue})' style='background:#d3d3d3; margin-right:2px; font-size:10px; padding:2px; white-space:nowrap;'> ${
                    index + 1
                }</span>`,
                newRefNum: index + 1
            };
        });

        let allActualVal: string[] = [];
        generatedObj.map((item: { actualVal: string | null; addedVal: string; newRefNum: number }) => allActualVal.push(item.actualVal!));

        let allNewVal: string[] = [];
        generatedObj.map(item => allNewVal.push(item.addedVal));

        for (let i = 0; i < allActualVal.length; i++) {
            content = content.replaceAll(allActualVal[i], allNewVal[i]);
        }

        // manuplating references keys
        let updatedReferences: any = {};
        for (const key in references) {
            if (references.hasOwnProperty(key)) {
                const extractKey = generatedObj.filter(item => item.actualVal === `<cite>${key}</cite>`);

                const newKey = extractKey[0]?.newRefNum;
                updatedReferences[newKey] = references[key];
            }
        }

        return [content, updatedReferences];
    };

    const handleHTMLContent = () => {
        if (item[1].content?.includes("<table>")) {
            return <div dangerouslySetInnerHTML={{ __html: manipulateData(item[1].content, item[1].references)[0] }} style={{ color: "#000" }} />;
        } else if (item[1].content?.includes("\n") || item[1].content?.includes("<cite>")) {
            let removedNewLine = item[1].content?.replace(/\n/g, "<br />");
            return (
                <div
                    dangerouslySetInnerHTML={{ __html: manipulateData(removedNewLine, item[1].references)[0] }}
                    style={{ fontWeight: "600", color: "#000", wordBreak: "break-word" }}
                />
            );
        } else {
            return <p style={{ fontWeight: "600", color: "#000" }}>{item[1].content}</p>;
        }
    };

    const showDocument = (path: string, page: number) => {
        setCitationMeta({
            path,
            page
        });

        setIsOpen(true);
    };

    const showThoughts = () => {
        setThoughtsContent(item[1]?.thought);
        setIsThoughtsDrawerOpen(true);
    };

    const shareGoodFeedback = () => {
        setFeedbackStatus("good");
        setFeedbackShow(true);
    };

    const shareBadFeedback = () => {
        setFeedbackStatus("bad");
        setFeedbackShow(true);
    };

    // Editing Content
    const editContent = (query: string) => {
        setContent(query);
        setIsEditable(true);
    };

    const cancelEditing = () => {
        setContent("");
        setIsEditable(false);
    };

    const updateContent = () => {
        setIsEditable(false);
        callEditedQuery(content);
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                margin: "10px 0px 10px 0px"
            }}
        >
            {/* // Ques */}
            <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        {allAnswersLen - 1 === objIndex ? (
                            <div className={styles.quesContainer}>
                                {!isLoading && isEditable ? (
                                    <div className={styles.message} style={{ width: "100%" }}>
                                        <Input
                                            placeholder="Placeholder"
                                            value={content}
                                            style={{ width: "100%" }}
                                            onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                if (event.key === "Enter") {
                                                    updateContent();
                                                }
                                            }}
                                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => setContent(event.target.value)}
                                        />
                                    </div>
                                ) : (
                                    <div className={styles.message}>{item[0].content}</div>
                                )}
                                {!isLoading && isEditable ? (
                                    <>
                                        <Tooltip title={"Done"} onClick={() => updateContent()}>
                                            <div className={styles.editIconWrapper}>
                                                <IconButton style={{ color: "black" }} iconProps={{ iconName: "CheckMark" }} size={30} />
                                            </div>
                                        </Tooltip>

                                        <Tooltip title={"Cancel"} onClick={() => cancelEditing()}>
                                            <div className={styles.editIconWrapper}>
                                                <IconButton style={{ color: "black" }} iconProps={{ iconName: "Cancel" }} size={30} />
                                            </div>
                                        </Tooltip>
                                    </>
                                ) : (
                                    !isLoading && (
                                        <Tooltip title={"Edit"} onClick={() => editContent(item[0].content)}>
                                            <div className={styles.editIconWrapper}>
                                                <IconButton style={{ color: "black" }} iconProps={{ iconName: "Edit" }} size={30} />
                                            </div>
                                        </Tooltip>
                                    )
                                )}
                            </div>
                        ) : (
                            <div className={styles.quesContainer}>
                                <div className={styles.message}>{item[0].content}</div>
                            </div>
                        )}
                        {/* <div className={styles.quesContainer}>
                            <div className={styles.message}>{item[0].content}</div>
                        </div> */}
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.chatMessageGpt} ref={answerRef}>
                            <Stack className={styles.answerContainer} verticalAlign="space-between">
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign="space-between">
                                        {/* <AnswerIcon /> */}
                                        <img src={coveredgIcon} width={"auto"} height={30} />
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Tooltip title="copy">
                                                    <div
                                                        className={styles.iconCs}
                                                        style={{ marginRight: 10 }}
                                                        onClick={() => {
                                                            if (answerRef.current) {
                                                                //@ts-ignore
                                                                clipboardCopy(item[1]?.content)
                                                                    .then(() => {
                                                                        console.log("Text copied to clipboard");
                                                                    })
                                                                    .catch((err: Error) => {
                                                                        console.log(err);
                                                                    });
                                                            }
                                                        }}
                                                    >
                                                        <IconButton
                                                            style={{ color: "black" }}
                                                            iconProps={{ iconName: "Copy" }}
                                                            title="copy"
                                                            ariaLabel="copy"
                                                            size={30}
                                                        />
                                                    </div>
                                                </Tooltip>
                                                {operator === "json" || operator === "rag" ? (
                                                    <Tooltip title="thoughts">
                                                        <div
                                                            onClick={() => showThoughts()}
                                                            style={{
                                                                marginRight: 10,
                                                                cursor: "pointer",
                                                                width: 40,
                                                                height: 40,
                                                                backgroundColor: "#efefef",
                                                                borderRadius: 8,
                                                                justifyContent: "center",
                                                                alignItems: "center",
                                                                display: "flex"
                                                            }}
                                                        >
                                                            <IconButton
                                                                style={{ color: "black" }}
                                                                iconProps={{ iconName: "Lightbulb" }}
                                                                title="copy"
                                                                ariaLabel="copy"
                                                                size={30}
                                                            />
                                                        </div>
                                                    </Tooltip>
                                                ) : (
                                                    false
                                                )}
                                                <Tooltip title="Better">
                                                    <div onClick={() => shareGoodFeedback()} className={styles.iconCs} style={{ marginRight: 10 }}>
                                                        <IconButton style={{ color: "black" }} iconProps={{ iconName: "Like" }} size={30} />
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="Worse">
                                                    <div onClick={() => shareBadFeedback()} className={styles.iconCs}>
                                                        <IconButton style={{ color: "black" }} iconProps={{ iconName: "Dislike" }} size={30} />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item grow>{handleHTMLContent()}</Stack.Item>
                                <Stack.Item grow style={{ marginTop: 10 }}>
                                    <Accordion expanded={expanded} onChange={() => setExpanded(!expanded)}>
                                        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
                                            <Typography>Sources</Typography>
                                        </AccordionSummary>
                                        <AccordionDetails>
                                            <Grid container spacing={2}>
                                                {Object.keys(manipulateData(item[1].content, item[1].references)[1]).map((reference: string, index: number) => {
                                                    let obj: IAllReferences = manipulateData(item[1].content, item[1].references)[1];
                                                    return (
                                                        <Grid item lg={4} md={6} sm={12} xs={12} key={index.toString()}>
                                                            <Grid
                                                                sx={{ py: 1, px: 1 }}
                                                                container
                                                                style={{
                                                                    display: "flex",

                                                                    alignItems: "center",
                                                                    border: "1px solid #eee"
                                                                }}
                                                            >
                                                                <Tooltip TransitionComponent={Zoom} title={obj[reference].text} followCursor arrow>
                                                                    <Typography style={{ fontWeight: 400, fontSize: 14 }}>
                                                                        <span>
                                                                            <b>{reference}. </b>
                                                                        </span>
                                                                        {obj[reference].text.substring(0, 50) + "..."}
                                                                    </Typography>
                                                                </Tooltip>
                                                                <Typography
                                                                    style={{ paddingTop: 5, cursor: "pointer", color: "#0000EE", fontSize: 12 }}
                                                                    onClick={() => showDocument(obj[reference].path, obj[reference].page)}
                                                                >
                                                                    {obj[reference].path} : Page {obj[reference].page}
                                                                </Typography>
                                                            </Grid>
                                                        </Grid>
                                                    );
                                                })}
                                            </Grid>
                                        </AccordionDetails>
                                    </Accordion>
                                </Stack.Item>
                            </Stack>
                        </div>
                    </Grid>
                </Grid>
            </div>
            {/* // Auto scroll to end */}
            <span ref={messagesEndRef}></span>

            {/* // Citation Drawer */}
            <CitationDrawer isOpen={isOpen} setIsOpen={setIsOpen} fileName={citationMeta.path} page={citationMeta.page} />

            {/* // Feedback Dialog */}
            <FeedbackDialog feedbackShow={feedbackShow} setFeedbackShow={setFeedbackShow} feedbackStatus={feedbackStatus} />

            {/* // Thoughts Drawer */}
            <ThoughtsDrawer isOpen={isThoughtsDrawerOpen} setIsOpen={setIsThoughtsDrawerOpen} thoughtsContent={thoughtsContent} />
        </Box>
    );
}
