type TermFrequency = { [term: string]: number };

function termFrequencyMap(str: string): TermFrequency {
    const termFreq: TermFrequency = {};
    str.split("").forEach(char => {
        termFreq[char] = (termFreq[char] || 0) + 1;
    });
    return termFreq;
}

function dotProduct(vecA: TermFrequency, vecB: TermFrequency): number {
    let product: number = 0;
    for (const key in vecA) {
        if (vecA.hasOwnProperty(key) && vecB.hasOwnProperty(key)) {
            product += vecA[key] * vecB[key];
        }
    }
    return product;
}

function vectorMagnitude(vec: TermFrequency): number {
    let sum: number = 0;
    for (const key in vec) {
        sum += vec[key] ** 2;
    }
    return Math.sqrt(sum);
}

export const cosineSimilarity = (strA: string, strB: string): number => {
    const termFreqA: TermFrequency = termFrequencyMap(strA);
    const termFreqB: TermFrequency = termFrequencyMap(strB);

    const dotProd: number = dotProduct(termFreqA, termFreqB);
    const magnitudeA: number = vectorMagnitude(termFreqA);
    const magnitudeB: number = vectorMagnitude(termFreqB);

    if (magnitudeA && magnitudeB) {
        return dotProd / (magnitudeA * magnitudeB);
    } else {
        return 0;
    }
};

export const cosineSearchData = (searchData: any, searchString: string) => {
    if (searchData.length > 0) {
        let arr: any = [];
        searchData.map((item: any) => {
            arr.push({
                item: { text: item.text, startSpan: item.startSpan, endSpan: item.endSpan, startWordIdx: item.startWordIdx, endWordIdx: item.endWordIdx },
                score: cosineSimilarity(item.text, searchString)
            });
        });
        return arr;
    }
};
