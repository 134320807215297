import * as React from "react";
import { Slide, Dialog, DialogTitle, styled, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import DeleteIcon from "@mui/icons-material/Delete";
import { TransitionProps } from "@mui/material/transitions";
import { createTheme, ThemeProvider, Theme, useTheme } from "@mui/material/styles";
import MuiAlert, { AlertProps } from "@mui/material/Alert";

import styles from "./ArchivedChatsDialog.module.css";
import { theme } from "../../../consts/versionB/consts/theme";
import CommonDialog from "./CommonDialog";
import { HistoryItem } from "../../../api/versionB/api";
import { history_items } from "../../../api/versionB/api/dummy_data";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    "& .MuiDialogContent-root": {
        padding: theme.spacing(2)
    },
    "& .MuiDialogActions-root": {
        padding: theme.spacing(1)
    }
}));

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement<any, any>;
    },
    ref: React.Ref<unknown>
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

interface IProps {
    setArchivedDialogShow: Function;
    archivedDialogShow: boolean;
}

const customTheme = (outerTheme: Theme) =>
    createTheme({
        palette: {
            mode: outerTheme.palette.mode
        },
        components: {
            MuiTextField: {
                styleOverrides: {
                    root: {
                        "--TextField-brandBorderColor": "#eee",
                        "--TextField-brandBorderHoverColor": "#eee",
                        "--TextField-brandBorderFocusedColor": "#888",
                        "& label.Mui-focused": {
                            color: "var(--TextField-brandBorderFocusedColor)"
                        }
                    }
                }
            },

            MuiFilledInput: {
                styleOverrides: {
                    root: {
                        "&:before, &:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderColor)"
                        },
                        "&:hover:not(.Mui-disabled, .Mui-error):before": {
                            borderBottom: "2px solid var(--TextField-brandBorderHoverColor)"
                        },
                        "&.Mui-focused:after": {
                            borderBottom: "2px solid var(--TextField-brandBorderFocusedColor)"
                        }
                    }
                }
            }
        }
    });

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} style={{ backgroundColor: "#d2f4d3", color: "#1a7f64", fontWeight: "bold" }} />;
});

const ArchivedChatsDialog = (props: IProps) => {
    const outerTheme = useTheme();
    const [isDeleteAlertVisible, setIsDeleteAlertVisible] = React.useState(false);
    const [archivedData, setArchivedData] = React.useState<HistoryItem[]>([]);

    React.useEffect(() => {
        const archivedChats = history_items.filter(item => item.isArchived === true);
        setArchivedData(archivedChats);
    }, []);

    const formatDate = (date: string | Date): string => {
        let updateddate = new Date(date);
        const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        const month = months[updateddate.getMonth()];
        const day = updateddate.getDate();
        const year = updateddate.getFullYear();

        return `${month} ${day}, ${year}`;
    };

    return (
        <>
            <BootstrapDialog
                PaperProps={{
                    style: {
                        borderRadius: theme.border.scardBorder
                    }
                }}
                keepMounted
                TransitionComponent={Transition}
                onClose={() => props.setArchivedDialogShow(false)}
                aria-labelledby="feedback-form"
                open={props.archivedDialogShow}
                fullWidth
                maxWidth="lg"
            >
                <DialogTitle sx={{ m: 0, px: 2, py: 0.5 }} id="feedback-form">
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                        <p className={styles.title}>Archived Chats</p>
                        <IconButton
                            aria-label="close"
                            onClick={() => props.setArchivedDialogShow(false)}
                            sx={{
                                color: theme => theme.palette.grey[500]
                            }}
                        >
                            <CloseIcon />
                        </IconButton>
                    </div>
                </DialogTitle>

                <DialogContent dividers>
                    <ThemeProvider theme={customTheme(outerTheme)}>
                        <table className={styles.table}>
                            <thead>
                                <tr>
                                    <th className={styles.tableHead}>Name</th>
                                    <th className={styles.tableHead}>Date created</th>
                                    <th className={styles.tableHead}></th>
                                </tr>
                            </thead>
                            <tbody>
                                {archivedData.map(item => (
                                    <tr key={item.id}>
                                        <td className={styles.tableBody}>
                                            <div className={styles.tableBodyDiv}>
                                                <a href="#" rel="noreferrer" className={styles.anchor}>
                                                    {item.title}
                                                </a>
                                            </div>
                                        </td>
                                        <td className={styles.tableBody}>
                                            <div className={styles.tableBodyDiv}>{formatDate(item.create_time)}</div>
                                        </td>
                                        <td className={styles.tableBody}>
                                            <div className={styles.tableBodyDiv}>
                                                <div className={styles.iconsGrp}>
                                                    <span className="" data-state="closed">
                                                        <UnarchiveIcon sx={{ color: theme.palette.primaryGray, fontSize: 18, cursor: "pointer" }} />
                                                    </span>
                                                    <span className="" data-state="closed" onClick={() => setIsDeleteAlertVisible(true)}>
                                                        <DeleteIcon sx={{ color: theme.palette.primaryGray, fontSize: 18, cursor: "pointer" }} />
                                                    </span>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </ThemeProvider>
                </DialogContent>
            </BootstrapDialog>

            {/* // Delete Dialog */}
            <CommonDialog
                isAlertVisible={isDeleteAlertVisible}
                setIsAlertVisible={setIsDeleteAlertVisible}
                alertTitle="Delete Chat"
                alertDescription={`This will delete`}
                boldDescription={`what is the final amount?`}
                alertHandler={() => false}
                buttonText={["Cancel", "Delete"]}
            />
        </>
    );
};

export default ArchivedChatsDialog;
