import React from "react";
import { AppBar, Toolbar, IconButton, Button, Typography, Box, Card, Grid, styled, Stack, Container } from "@mui/material";
import Header from "../../components/Header/Header";
import FlipableCard from "../../components/FlipableCard/FlipableCard";
import { useNavigate } from "react-router-dom";
import Logo from "../../components/Logo/Logo";
import { useAuth } from "../../context/AuthProvider";
import { ArrowExit20Regular } from "@fluentui/react-icons";
import { Text } from "@fluentui/react";

const desc = {
    first_card: "Intelligent and efficient retrieval of information from Crawford datasets.",
    second_card: "Your in house Chat GPT",
    third_card: "Intelligent and efficient retrieval of information from Crawford Documents.",
    fourth_card: "Answering your claims questions for your policy."
};

const CardContainer = styled(Box)(({}) => ({
    position: "absolute",
    top: "67%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    height: "100%"
}));

const AppSelection = ({}) => {
    const [isLoading, setIsLoading] = React.useState(false);

    const { logout } = useAuth();
    const navigate = useNavigate();
    const [today, setDate] = React.useState(new Date());
    React.useEffect(() => {
        const timer = setInterval(() => {
            setDate(new Date());
        }, 60 * 10);
        return () => {
            clearInterval(timer);
        };
    }, []);

    const handleLogout = () => {
        logout();
    };

    const switchToNew = () => {
        setIsLoading(true);
        // localStorage.setItem("appVersion", "new");
        setTimeout(() => {
            setIsLoading(false);
            navigate("/policy-chat/version-2");
        }, 1000);
    };

    return (
        <Box>
            <Header>
                <Box flexGrow={1}>
                    <Logo />
                </Box>
                <Box flexGrow={1} style={{ display: "flex", justifyContent: "center" }}>
                    <Typography variant="h6" component="div">
                        Crawford Azure AI
                    </Typography>
                </Box>

                <Box flexGrow={1}>
                    <div style={{ display: "flex", justifyContent: "end" }}>
                        <Button onClick={() => switchToNew()} variant="contained" sx={{ textTransform: "capitalize", mr: 2 }}>
                            {isLoading ? "Redirecting..." : "Try New Version"}
                        </Button>
                        <div style={{ display: "flex", alignItems: "center", gap: "6px", cursor: "pointer" }} onClick={() => handleLogout()}>
                            <ArrowExit20Regular />
                            <Text style={{ color: "#efefef" }}>{"Logout"}</Text>
                        </div>
                    </div>
                    {/* <p style={{ fontWeight: "normal", textAlign: "right" }}>
                        <ArrowExit20Regular /> Logout
                    </p> */}
                    {/* <p style={{ fontWeight: "normal", textAlign: "right", marginBottom: "-15px" }}>
                        {today.toLocaleDateString([], {
                            year: "numeric",
                            month: "2-digit",
                            day: "2-digit"
                        })}
                    </p>

                    <p style={{ fontWeight: "normal", textAlign: "right" }}>
                        {today.toLocaleTimeString([], {
                            hour: "2-digit",
                            minute: "2-digit",
                            second: "2-digit"
                        })}
                    </p> */}
                </Box>
            </Header>
            <Container maxWidth="xl">
                <Grid container spacing={4} style={{ marginTop: 50, marginBottom: 50 }}>
                    <Grid item xs={12} sm={6} md={4} lg={3} mt={1} mb={1}>
                        <FlipableCard description={desc.third_card} title="Document Query" onPageVisit={() => navigate("/langchain")} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} mt={1} mb={1}>
                        <FlipableCard description={desc.first_card} title="JSON Policy Chat" onPageVisit={() => navigate("/details?approach=json")} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} mt={1} mb={1}>
                        <FlipableCard description={desc.first_card} title="Rag Policy Chat" onPageVisit={() => navigate("/details?approach=rag")} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} mt={1} mb={1}>
                        <FlipableCard description={desc.fourth_card} title="CoverageAI" onPageVisit={() => navigate("/details?approach=coveredg")} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} mt={1} mb={1}>
                        <FlipableCard description={desc.first_card} title="Data Chat" onPageVisit={() => navigate("/codex")} />
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3} mt={1} mb={1}>
                        <FlipableCard description={desc.second_card} title="Generic Data" onPageVisit={() => navigate("/general")} />
                    </Grid>
                </Grid>
            </Container>
        </Box>
    );
};

export default AppSelection;
