import { Card, CardContent, styled, CardHeader, CardActions, Grid, Box } from "@mui/material";
// import cardBg from "../../assets/card-bg.webp";
import dataChatBG from "../../assets/img_bg/data_chat.png";
import documentChatBG from "../../assets/img_bg/document_chat.png";
import genericChatBG from "../../assets/img_bg/generic_chat.png";
import langchainChatBG from "../../assets/img_bg/langchain_chat.png";
import csvChatBG from "../../assets/img_bg/csv_query_chat.png";
import dataChatSVG from "../../assets/icons/data_chat.svg";
import documentChatSVG from "../../assets/icons/document_chat.svg";
import genericChatSVG from "../../assets/icons/generic_chat.svg";
import langchainChatSVG from "../../assets/icons/langchain.svg";
import csvChatSVG from "../../assets/icons/csv_chat.svg";
import { colors } from "../../consts/colors";

const CustomCard = styled(Card)(({ elevation }) => ({
    width: 300,
    height: 400,
    boxShadow: "0px 2px 3px rgba(0, 0, 0, 0.2)",
    transition: "all 0.3s",
    position: "relative",
    "&:hover": {
        elevation: 4,
        cursor: "pointer",
        boxShadow: "0px 3px 5px rgba(0, 0, 0, 0.2)",
        transform: "scale(1.1)",
        zIndex: 99
    }
}));

// const ButtonContainer = styled(Box)(() => ({
//     // position: "absolute",
//     // left: "50%",
//     // transform: "translateX(-50%)"
// }));
interface FlipableCard {
    title: string;
    onPageVisit: () => void;
    description: string;
}
const FlipableCard = ({ title, onPageVisit, description }: FlipableCard) => {
    return (
        <CustomCard sx={{ width: "100%", height: 420 }} onClick={onPageVisit}>
            <CardHeader
                title={title}
                sx={{
                    backgroundColor: colors.primaryLight,
                    color: "#fff",
                    "& .MuiCardHeader-title": {
                        fontSize: 16,
                        fontWeight: 600
                    }
                }}
            />
            <Grid>
                <CardContent
                    sx={{
                        height: 130,
                        backgroundImage: `url(${
                            title === "Data Chat"
                                ? dataChatBG
                                : title === "Document Query"
                                ? documentChatBG
                                : title === "Generic Data"
                                ? genericChatBG
                                : title === "JSON Policy Chat" || title === "Rag Policy Chat"
                                ? langchainChatBG
                                : csvChatBG
                        })`,
                        backgroundPosition: "center",
                        backgroundSize: "cover",
                        backgroundRepeat: "no-repeat"
                    }}
                ></CardContent>
                <CardContent sx={{ height: "auto" }}>{description}</CardContent>
            </Grid>
            <CardActions style={{ position: "absolute", bottom: 0, marginBottom: 30, display: "flex", width: "100%", justifyContent: "center" }}>
                <img
                    src={
                        title === "Data Chat"
                            ? dataChatSVG
                            : title === "Document Query"
                            ? documentChatSVG
                            : title === "Generic Data"
                            ? genericChatSVG
                            : title === "JSON Policy Chat" || title === "Rag Policy Chat"
                            ? langchainChatSVG
                            : csvChatSVG
                    }
                    alt="Your SVG"
                    style={{ width: 70, height: 70 }}
                />
            </CardActions>
        </CustomCard>
    );
};

export default FlipableCard;
