import { ReactNode, createContext, useState, useContext, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
interface IAuthContext {
    isAuthenticated: boolean;
    isLoading: boolean;
    loginWithRedirect: () => void;
    logout: () => void;
    user: any;
}

const AuthContext = createContext<IAuthContext | undefined>(undefined);

const AuthProvider = ({ children }: { children: ReactNode }) => {
    const { isAuthenticated, isLoading, loginWithRedirect, logout, user } = useAuth0();
    const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

    useEffect(() => {
        const storedIsAuthenticated = localStorage.getItem("isMainAuth") === "true";
        setIsLoggedIn(storedIsAuthenticated);
    }, []);

    useEffect(() => {
        if (isAuthenticated !== isLoggedIn) {
            setIsLoggedIn(isAuthenticated);
            localStorage.setItem("isMainAuth", String(isAuthenticated));
        }
    }, [isAuthenticated, isLoggedIn]);

    return <AuthContext.Provider value={{ isAuthenticated, isLoading, loginWithRedirect, logout, user }}>{children}</AuthContext.Provider>;
};

export default AuthProvider;

export const useAuth = () => {
    const context = useContext(AuthContext);
    if (context === undefined) {
        throw new Error("Something went wrong. Please try again");
    }

    return context;
};
