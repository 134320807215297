import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import Menu, { MenuProps } from "@mui/material/Menu";
import InputBase from "@mui/material/InputBase";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import Autocomplete from "@mui/material/Autocomplete";
import { IClaimData, all_claims, json_approach_claims, rag_approach_claims } from "../../api/data";
import { Button, MenuItem } from "@mui/material";
import FilterList from "@mui/icons-material/FilterList";
import { colors } from "../../consts/colors";
import styles from "./UniversalSearch.module.css";
import AuthContext from "../../context/AuthProvider";
import { useLocation } from "react-router-dom";
interface IProps {
    claimNum: string;
    id: string;
}

interface IParentProps {
    setSelectedClaimDetails: Function;
    setselectedOption: Function;
    selectedOption: string;
    approach: string | null;
}

export default function UniversalSearch({ setSelectedClaimDetails, setselectedOption, selectedOption, approach }: IParentProps) {
    const location = useLocation();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [filteredData, setFilteredData] = React.useState<IClaimData[]>([]);
    const [registeredRole, setRegisteredRole] = React.useState<string | null>(null);
    const open = Boolean(anchorEl);

    React.useEffect(() => {
        const userRole: string | null = localStorage.getItem("role");
        setRegisteredRole(userRole);
    }, []);

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const selectPolicy = (value: string) => {
        setselectedOption(value);
        setFilteredData([]);
        handleClose();
    };

    const showSuggestion = (value: string) => {
        if (value === "") {
            setFilteredData([]);
        } else {
            const filteredData: IClaimData[] =
                selectedOption === "Claim"
                    ? (approach === "json"
                          ? json_approach_claims
                          : approach === "rag"
                          ? rag_approach_claims
                          : approach === "coveredg"
                          ? all_claims
                          : []
                      ).filter(item => item.claimNo.toLowerCase().includes(value.toLowerCase()))
                    : (approach === "json"
                          ? json_approach_claims
                          : approach === "rag"
                          ? rag_approach_claims
                          : approach === "coveredg"
                          ? all_claims
                          : []
                      ).filter(item => item.programNumber.toLowerCase().includes(value.toLowerCase()));
            setFilteredData([...filteredData]);
        }
    };

    const customRenderOptions = (
        props: JSX.IntrinsicAttributes & React.ClassAttributes<HTMLLIElement> & React.LiHTMLAttributes<HTMLLIElement>,
        option: IClaimData
    ) => {
        return (
            <li {...props} className={styles.tableLi} key={option.claimNo}>
                <table className={styles.tableBox}>
                    <tbody className={styles.tableBody}>
                        <tr className={styles.tableRow} style={{ cursor: "pointer" }}>
                            <td className={styles.tablebodyvalue}>{option.programNumber}</td>
                            <td className={styles.tablebodyvalue}>{option.claimNo}</td>
                            <td className={styles.tablebodyvalue}>{new Date(option.policyStartDate).toLocaleDateString()}</td>
                            <td className={styles.tablebodyvalue}>{new Date(option.policyExpiryDate).toLocaleDateString()}</td>
                        </tr>
                    </tbody>
                </table>
            </li>
        );
    };

    return (
        <React.Fragment>
            <div style={{ width: "100%" }}>
                <Paper component="form" sx={{ p: "1px 2px", display: "flex", alignItems: "center" }}>
                    <Autocomplete
                        freeSolo
                        sx={{ flex: 1 }}
                        id="custom-input-demo"
                        onBlur={() => setFilteredData([])}
                        options={filteredData}
                        getOptionLabel={(option: any) => option[selectedOption === "Claim" ? "claimNo" : "programNumber"]}
                        PaperComponent={({ children }) => {
                            return (
                                <Paper style={{ marginTop: 12 }}>
                                    <table className={styles.tableBox}>
                                        <thead>
                                            <tr className={styles.tableRow}>
                                                <th className={styles.tablehead}>Program</th>
                                                <th className={styles.tablehead}>Claim #</th>
                                                <th className={styles.tablehead}>Policy Start</th>
                                                <th className={styles.tablehead}>Policy Expiry</th>
                                            </tr>
                                        </thead>
                                    </table>
                                    {children}
                                </Paper>
                            );
                        }}
                        onChange={(event, newValue: any) => setSelectedClaimDetails(newValue)}
                        renderInput={params => (
                            <InputBase
                                onChange={e => showSuggestion(e.target.value)}
                                onKeyDown={event => {
                                    if (event.key === "Enter") {
                                        event.preventDefault();
                                        event.stopPropagation();
                                    }
                                }}
                                fullWidth
                                inputProps={params.inputProps}
                                sx={{ pl: 1, flex: 1 }}
                                {...params.InputProps}
                                placeholder={`Search by ${selectedOption}`}
                            />
                        )}
                        renderOption={customRenderOptions}
                    />

                    <IconButton type="button" sx={{ p: "10px" }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                    {registeredRole === "carrier" && (
                        <Button
                            id="demo-customized-button"
                            aria-controls={open ? "demo-customized-menu" : undefined}
                            aria-haspopup="true"
                            aria-expanded={open ? "true" : undefined}
                            disableElevation
                            onClick={handleClick}
                            endIcon={<FilterList />}
                        >
                            {selectedOption === null ? "Select" : selectedOption}
                        </Button>
                    )}

                    {registeredRole === "carrier" && (
                        <Menu
                            id="basic-menu"
                            anchorEl={anchorEl}
                            open={open}
                            onClose={handleClose}
                            MenuListProps={{
                                "aria-labelledby": "basic-button"
                            }}
                        >
                            <MenuItem onClick={() => selectPolicy("Claim")}>Claim</MenuItem>
                            <MenuItem onClick={() => selectPolicy("Program")}>Program</MenuItem>
                        </Menu>
                    )}
                </Paper>
            </div>
        </React.Fragment>
    );
}
