import { Text } from "@fluentui/react";
import { Delete24Regular } from "@fluentui/react-icons";

import styles from "./SelectButton.module.css";

interface Props {
    icon: JSX.Element;
    className?: string;
    onClick: () => void;
    disabled?: boolean;
    btnText: string;
}

export const SelectButton = ({ icon, className, disabled, onClick, btnText }: Props) => {
    return (
        <div className={`${styles.container} ${className ?? ""} ${disabled && styles.disabled}`} onClick={onClick}>
            {icon}
            <Text style={{ color: "#efefef" }}>{btnText}</Text>
        </div>
    );
};
