// interface
export interface IClaimData {
    claimNo: string | null;
    receivedDate: any;
    perilTypeID: string;
    carrierName: string;
    remark: null;
    bucketStatus: string;
    modifiedDate: Date | string;
    assignee: string;
    assigneeID: null;
    policyNo: string;
    policyStartDate: any;
    policyExpiryDate: any;
    claimSourceTypeID: string;
    sourceReference: null;
    isDuplicateClaim: boolean;
    lossDate: any;
    catCode: string;
    claimRefNo: string;
    clientGroup: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    carrierReferenceNumber: null;
    service: string;
    assignor: string;
    policyHolderName: string;
    policyHolderPhone: string;
    policyHolderAddress: string;
    policyHolderCity: string;
    policyHolderState: string;
    policyHolderCountry: string;
    policyHolderZip: string;
    reason: string;
    latitude: string;
    longitude: string;
    lastModifiedBy: string;
    isMyClaim: boolean;
    programNumber: string;
    isMyteam: boolean;
    propertyTypeID: string;
    pendingReason: any[];
    isXactClaimUpdate: boolean;
    xactClaimUpdateCount: null;
    xactClaimLastUpdatedDateTime: null;
}

export const data = [
    { claimNum: "123132312", id: "123132312" },
    { claimNum: "6453738457", id: "6453738457" },
    { claimNum: "CC_00366374", id: "CC_00366374" },
    { claimNum: "54628746", id: "54628746" },
    { claimNum: "7454735243", id: "7454735243" },
    { claimNum: "846472834", id: "846472834" }
];

export const all_claims = [
    {
        claimNo: "123132312",
        receivedDate: "2023-02-17T13:48:54.077",
        perilTypeID: "PTWR",
        carrierName: "UK-TPA-Client",
        remark: null,
        bucketStatus: "BINS",
        modifiedDate: "2023-10-20T11:29:07.047",
        assignee: "Derry James",
        assigneeID: null,
        policyNo: "PL_UK_TPA_CLIENT_001",
        policyStartDate: "2022-02-16T00:00:00",
        policyExpiryDate: "2024-06-24T00:00:00",
        claimSourceTypeID: "CSML",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "2023-02-12T00:00:00",
        catCode: "CAT-0001",
        claimRefNo: "034A5414-088B-48C7-89D2-8390010EAA16",
        clientGroup: "UK-TPA-Client Insurance",
        address1: "1305 AFTERNOON SUN RD MATTHEWS ",
        address2: "",
        city: "Duluth",
        state: "US_NC",
        country: "US",
        zip: "28104-0001",
        carrierReferenceNumber: null,
        service: "SCCC,SCCM,SCCC,SCCC,SCCM,SCRF,SCCC,SCCC,SCCC,SCCC",
        assignor: "Derry James",
        policyHolderName: "Catherine Hoyo Mu",
        policyHolderPhone: "404-298-4545",
        policyHolderAddress: "1305 AFTERNOON SUN RD MATTHEWS ",
        policyHolderCity: "Duluth",
        policyHolderState: "US_NC",
        policyHolderCountry: "US",
        policyHolderZip: "28104-0001",
        reason: "SLA Delay in Assessment",
        latitude: "34.02336",
        longitude: "-84.15634",
        lastModifiedBy: "Derry James",
        isMyClaim: true,
        programNumber: "UK-TPA-Client-0001",
        isMyteam: false,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: false,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "6453738457",
        receivedDate: "2023-08-11T14:34:03.583",
        perilTypeID: "PTWR",
        carrierName: "UK-TPA-Client",
        remark: null,
        bucketStatus: "BINS",
        modifiedDate: "2023-10-09T12:14:36.567",
        assignee: "Derry James",
        assigneeID: null,
        policyNo: "DDPolicyRoofing-001",
        policyStartDate: "2022-11-09T00:00:00",
        policyExpiryDate: "2024-01-29T00:00:00",
        claimSourceTypeID: "CSML",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "2023-08-08T00:00:00",
        catCode: "CAT-0001",
        claimRefNo: "8A0A94A5-E295-40EC-9E22-BDE785539CAF",
        clientGroup: "UK-TPA-Client Insurance",
        address1: "3061 ",
        address2: "",
        city: "Duluth",
        state: "US_GA",
        country: "US",
        zip: "41011-2710",
        carrierReferenceNumber: null,
        service: "SCCC,SCRF,SCRF,SCRF,SCCM,SCRF,SCCC,SCCC,SCCM,SCRF,SCCM,SCCM,SCCC,SCCC",
        assignor: "Derry James",
        policyHolderName: "Adam Smith",
        policyHolderPhone: "404-259-3156",
        policyHolderAddress: "3061",
        policyHolderCity: "Duluth",
        policyHolderState: "US_GA",
        policyHolderCountry: "US",
        policyHolderZip: "41011-2710",
        reason: "SLA Delay in Assessment",
        latitude: "46.78667",
        longitude: "-92.10049",
        lastModifiedBy: "Derry James",
        isMyClaim: true,
        programNumber: "UK-TPA-Client-0001",
        isMyteam: false,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: false,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "CC_00366374",
        receivedDate: "2023-06-22T16:31:32.273",
        perilTypeID: "PTWR",
        carrierName: "UK-TPA-Client",
        remark: null,
        bucketStatus: "BINS",
        modifiedDate: "2023-06-22T16:31:54.947",
        assignee: "Derry James",
        assigneeID: null,
        policyNo: "DD_policy00102",
        policyStartDate: "2022-12-14T00:00:00",
        policyExpiryDate: "2024-03-25T00:00:00",
        claimSourceTypeID: "CSML",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "2023-06-20T00:00:00",
        catCode: "CAT-001",
        claimRefNo: "19005960-898D-402E-B550-9BA1F63F66FF",
        clientGroup: "UK-TPA-Client Insurance",
        address1: "3061 Peachtree Industrial Blvd",
        address2: "",
        city: "Duluth",
        state: "US_GA",
        country: "US",
        zip: "30097",
        carrierReferenceNumber: null,
        service: "SCCC,SCCM,SCRF,SCCC,SCRF,SCCC",
        assignor: "Derry James",
        policyHolderName: "David Henry",
        policyHolderPhone: "404-259-3156",
        policyHolderAddress: "3061 Peachtree Industrial Blvd",
        policyHolderCity: "Duluth",
        policyHolderState: "US_GA",
        policyHolderCountry: "US",
        policyHolderZip: "30097",
        reason: "SLA Delay in Assessment",
        latitude: "34.02214",
        longitude: "-84.13415",
        lastModifiedBy: "Derry James",
        isMyClaim: true,
        programNumber: "UK-TPA-Client-0001",
        isMyteam: false,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: false,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "54628746",
        receivedDate: "2023-02-23T08:23:28.903",
        perilTypeID: "PTWD",
        carrierName: "US-Client",
        remark: null,
        bucketStatus: "BINS",
        modifiedDate: "2023-08-11T14:50:55.92",
        assignee: "Derry James",
        assigneeID: null,
        policyNo: "DDPLUSClient_CC",
        policyStartDate: "2022-02-15T00:00:00",
        policyExpiryDate: "2023-12-18T00:00:00",
        claimSourceTypeID: "CSML",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "2023-02-20T00:00:00",
        catCode: "CAT-0001",
        claimRefNo: "57354FB1-EB09-496B-A202-CC668DE6C84F",
        clientGroup: "US-Client Insurance",
        address1: "12009 WILD BILL CT",
        address2: "",
        city: "NEWARK",
        state: "US_TX",
        country: "US",
        zip: "30097",
        carrierReferenceNumber: null,
        service: "SCCM,SCCM,SCRF,SCRF",
        assignor: "Derry James",
        policyHolderName: "Dean Holding",
        policyHolderPhone: "404-259-3156",
        policyHolderAddress: "12009 WILD BILL CT",
        policyHolderCity: "NEWARK",
        policyHolderState: "US_TX",
        policyHolderCountry: "US",
        policyHolderZip: "32256",
        reason: "SLA Delay in Assessment",
        latitude: "33.00124",
        longitude: "-97.48447",
        lastModifiedBy: "Derry James",
        isMyClaim: true,
        programNumber: "US-Client-0001",
        isMyteam: false,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: false,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "7454735243",
        receivedDate: "2023-06-23T11:03:45.31",
        perilTypeID: "PTWR",
        carrierName: "US-Client",
        remark: null,
        bucketStatus: "BINS",
        modifiedDate: "2023-06-23T11:04:38.64",
        assignee: "Derry James",
        assigneeID: null,
        policyNo: "DDDTestPL-100",
        policyStartDate: "2023-02-01T00:00:00",
        policyExpiryDate: "2024-02-01T00:00:00",
        claimSourceTypeID: "CSML",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "2023-06-22T00:00:00",
        catCode: "",
        claimRefNo: "9D1FD700-CAC5-4BA6-BA8E-8B09419EB873",
        clientGroup: "US-Client Insurance",
        address1: "3061 Peachtree Industrial Blvd",
        address2: "",
        city: "Duluth",
        state: "US_GA",
        country: "US",
        zip: "30097",
        carrierReferenceNumber: null,
        service: "SCCC,SCCM,SCCC,SCRF",
        assignor: "Derry James",
        policyHolderName: "Terry Martin",
        policyHolderPhone: "404-259-3156",
        policyHolderAddress: "3061 Peachtree Industrial Blvd",
        policyHolderCity: "Duluth",
        policyHolderState: "US_GA",
        policyHolderCountry: "US",
        policyHolderZip: "30097",
        reason: "SLA Delay in Assessment",
        latitude: "34.02214",
        longitude: "-84.13415",
        lastModifiedBy: "Derry James",
        isMyClaim: true,
        programNumber: "US-Client-0001",
        isMyteam: false,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: false,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "846472834",
        receivedDate: "2023-02-17T14:15:42.39",
        perilTypeID: "PTWD",
        carrierName: "US-Client",
        remark: null,
        bucketStatus: "BINS",
        modifiedDate: "2023-02-17T14:16:28.73",
        assignee: "Derry James",
        assigneeID: null,
        policyNo: "plp_USClient398789",
        policyStartDate: "2022-04-20T00:00:00",
        policyExpiryDate: "2024-06-25T00:00:00",
        claimSourceTypeID: "CSML",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "2023-02-16T00:00:00",
        catCode: "CAT-0001",
        claimRefNo: "B101B739-3E7A-4961-8964-064FA13E3632",
        clientGroup: "US-Client Insurance",
        address1: "8112 SANDHILL CRANE DR",
        address2: "",
        city: "FORT WORTH",
        state: "US_TX",
        country: "US",
        zip: "76118-4506",
        carrierReferenceNumber: null,
        service: "SCCC,SCCM",
        assignor: "Derry James",
        policyHolderName: "Rama Krishna Mulpuri",
        policyHolderPhone: "404-259-2329",
        policyHolderAddress: "8112 SANDHILL CRANE DR",
        policyHolderCity: "FORT WORTH",
        policyHolderState: "US_TX",
        policyHolderCountry: "US",
        policyHolderZip: "76118-4506",
        reason: "SLA Delay in Assessment",
        latitude: "32.80116",
        longitude: "-97.20217",
        lastModifiedBy: "Derry James",
        isMyClaim: true,
        programNumber: "US-Client-0001",
        isMyteam: false,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: false,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    }
];

export const json_approach_claims = [
    {
        claimNo: "098098098",
        receivedDate: "2023-02-17T13:48:54.077",
        perilTypeID: "PTWR",
        carrierName: "UK-TPA-Client",
        remark: null,
        bucketStatus: "BINS",
        modifiedDate: "2023-10-20T11:29:07.047",
        assignee: "Derry James",
        assigneeID: null,
        policyNo: "PL_UK_TPA_CLIENT_001",
        policyStartDate: "2022-02-16T00:00:00",
        policyExpiryDate: "2024-06-24T00:00:00",
        claimSourceTypeID: "CSML",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "2023-02-12T00:00:00",
        catCode: "CAT-0001",
        claimRefNo: "034A5414-088B-48C7-89D2-8390010EAA16",
        clientGroup: "UK-TPA-Client Insurance",
        address1: "1305 AFTERNOON SUN RD MATTHEWS ",
        address2: "",
        city: "Duluth",
        state: "US_NC",
        country: "US",
        zip: "28104-0001",
        carrierReferenceNumber: null,
        service: "SCCC,SCCM,SCCC,SCCC,SCCM,SCRF,SCCC,SCCC,SCCC,SCCC",
        assignor: "Derry James",
        policyHolderName: "Catherine Hoyo Mu",
        policyHolderPhone: "404-298-4545",
        policyHolderAddress: "1305 AFTERNOON SUN RD MATTHEWS ",
        policyHolderCity: "Duluth",
        policyHolderState: "US_NC",
        policyHolderCountry: "US",
        policyHolderZip: "28104-0001",
        reason: "SLA Delay in Assessment",
        latitude: "34.02336",
        longitude: "-84.15634",
        lastModifiedBy: "Derry James",
        isMyClaim: true,
        programNumber: "JSON-Client-001",
        isMyteam: false,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: false,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    }
];

export const rag_approach_claims = [
    {
        claimNo: "2108460802",
        receivedDate: "04/08/2022",
        perilTypeID: "PTWD",
        carrierName: "KEMPER INDEPENDENCE INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2108460802",
        policyStartDate: "04/08/2022",
        policyExpiryDate: "04/08/2023",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "KEMPER INDEPENDENCE INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "JANE DARRIS",
        policyHolderPhone: "",
        policyHolderAddress: "5308 BAKER STREET DR",
        policyHolderCity: "FRIVE WORTH",
        policyHolderState: "TX",
        policyHolderCountry: "US",
        policyHolderZip: "76244-6236",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0001",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "2281733013",
        receivedDate: "07/17/2023",
        perilTypeID: "PTWD",
        carrierName: "KEMPER INDEPENDENCE INSURANCE  COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "October 17, 2023 at 12:01 a.m.",
        assignee: "",
        assigneeID: null,
        policyNo: "2281733013",
        policyStartDate: "07/17/2023",
        policyExpiryDate: "06/17/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "KEMPER INDEPENDENCE INSURANCE COMPANY",
        address1: "12926 Green Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "Jack Maa",
        policyHolderPhone: "",
        policyHolderAddress: "1320 AVE ARCADIA ",
        policyHolderCity: "Austin",
        policyHolderState: "TX (Texas)",
        policyHolderCountry: "US",
        policyHolderZip: "89868-4116",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0002",
        isMyteam: false,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: false,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "2259614402",
        receivedDate: "08/04/2023",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2259614402",
        policyStartDate: "08/04/2023",
        policyExpiryDate: "08/04/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "Ben King",
        policyHolderPhone: "",
        policyHolderAddress: " 818 BRODWAY LN SE",
        policyHolderCity: "ISANTI",
        policyHolderState: "MN",
        policyHolderCountry: "US",
        policyHolderZip: "55040-3202",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0003",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "EG 132646",
        receivedDate: "10-04-2022",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN PREFERRED Insurance Company",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "EG 132646",
        policyStartDate: "10-04-2022",
        policyExpiryDate: "10-04-2023",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN PREFERRED Insurance Company",
        address1: "12926 Gran Bay Pkwy W",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "LADY KATY",
        policyHolderPhone: "",
        policyHolderAddress: "342 VAN ROWE",
        policyHolderCity: "DUNCANVILLE",
        policyHolderState: "TX ",
        policyHolderCountry: "US",
        policyHolderZip: "75116",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0004",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "2108497502",
        receivedDate: "05/23/2023",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2108497502",
        policyStartDate: "05/23/2023",
        policyExpiryDate: "05/23/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "DEAN JOHNSON",
        policyHolderPhone: "",
        policyHolderAddress: "1607 BUFF DR ",
        policyHolderCity: "SQUARE ROCK",
        policyHolderState: "TX",
        policyHolderCountry: "US",
        policyHolderZip: "78681",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0005",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "2160501502",
        receivedDate: "05/20/2023",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2160501502",
        policyStartDate: "05/20/2023",
        policyExpiryDate: "05/20/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "JOHNY DEPP",
        policyHolderPhone: "",
        policyHolderAddress: "23936 IRVING BUFF DR",
        policyHolderCity: "AUSTIN",
        policyHolderState: "TX",
        policyHolderCountry: "US",
        policyHolderZip: "78727",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0006",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "2160340402",
        receivedDate: "08/23/2022",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN AUTO AND HOME INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2160340402",
        policyStartDate: "08/23/2022",
        policyExpiryDate: "08/23/2023",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN AUTO AND HOME INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "KEVIN SPACEY",
        policyHolderPhone: "",
        policyHolderAddress: "1026 19TH AVE NE",
        policyHolderCity: "MINNEAPOLIS",
        policyHolderState: "MN",
        policyHolderCountry: "US",
        policyHolderZip: "55406",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0007",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "7Z9654846",
        receivedDate: "01/07/2023",
        perilTypeID: "PTWD",
        carrierName: "Test Broker",
        remark: null,
        bucketStatus: "",
        modifiedDate: "2023-10-20T11:29:07.047",
        assignee: "",
        assigneeID: null,
        policyNo: "7Z9654846",
        policyStartDate: "01/07/2023",
        policyExpiryDate: "01/07/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "Test Broker",
        address1: "205 MAIN STREET EAST",
        address2: "",
        city: "MILTON",
        state: "ONTARIO",
        country: "US",
        zip: "L9T 1N7",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "WILSON, KEVIN & AMANDA",
        policyHolderPhone: "",
        policyHolderAddress: "38 ACREDALE DR",
        policyHolderCity: "CARLISLE",
        policyHolderState: "ON",
        policyHolderCountry: "US",
        policyHolderZip: "L0R 1H2",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Canada_0001",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    }
];

export const claimInfo = [
    {
        key: "claimNo",
        label: "Claim Number"
    },
    {
        key: "receivedDate",
        label: "Received Date"
    },
    {
        key: "carrierName",
        label: "Carrier Name"
    },
    {
        key: "programNumber",
        label: "Program #"
    },
    { key: "carrierReferenceNumber", label: "Carrier Reference Number" },
    { key: "catCode", label: "CAT" }
];

export const lossInfo = [
    { key: "lossDate", label: "Loss Date" },
    { key: "perilTypeID", label: "Peril Type" },
    { key: "propertyTypeID", label: "Property Type" },
    { key: "reason", label: "Reason" }
];

export const policyInfo = [
    { key: "policyHolderName", label: "Policy Holder's Name" },
    { key: "policyNo", label: "Policy #" },
    { key: "policyStartDate", label: "Policy Start Date" },
    { key: "policyExpiryDate", label: "Policy Expiry Date" },
    { key: "policyHolderAddress", label: "Policy Holder's Address" },
    { key: "policyHolderPhone", label: "Policy Holder's Phone" }
];
