import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { theme } from "../../../consts/versionB/consts/theme";
import React from "react";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";

const HelpFab = () => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };
    return (
        <>
            <div
                onClick={handleClick}
                style={{
                    cursor: "pointer",
                    position: "absolute",
                    border: `1px solid ${theme.palette.lightGray}`,
                    color: theme.palette.primaryGray,
                    background: theme.palette.lightGray2,
                    bottom: 0,
                    right: 0,
                    zIndex: 1205,
                    width: 25,
                    height: 25,
                    padding: 10,
                    borderRadius: "100%",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    marginRight: 6,
                    marginBottom: 6,
                    fontSize: 12,
                    fontWeight: 600
                }}
            >
                ?
            </div>
            <Menu
                sx={{ top: "-20px" }}
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
                transformOrigin={{
                    vertical: "top",
                    horizontal: "left"
                }}
            >
                <MenuItem onClick={handleClose} sx={{ fontSize: 12 }}>
                    <OpenInNewIcon sx={{ fontSize: 12, mr: 2 }} />
                    Help & FAQ
                </MenuItem>
                <MenuItem onClick={handleClose} sx={{ fontSize: 12 }}>
                    <OpenInNewIcon sx={{ fontSize: 12, mr: 2 }} />
                    Release Notes
                </MenuItem>
            </Menu>
        </>
    );
};

export default HelpFab;
