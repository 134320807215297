import { useEffect, useRef, useState } from "react";
import clipboardCopy from "clipboard-copy";
import { AccordionDetails, Grid, Tooltip, Typography, Card, Input, Zoom, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import MuiAccordion, { AccordionProps } from "@mui/material/Accordion";
import MuiAccordionSummary, { AccordionSummaryProps } from "@mui/material/AccordionSummary";
import ArrowForwardIosSharpIcon from "@mui/icons-material/ArrowForwardIosSharp";
import Box from "@mui/material/Box";
import { IconButton, Stack } from "@fluentui/react";
import styles from "./QuesAnsBox.module.css";
import CitationDrawer from "./CitationDrawer";
import FeedbackDialog from "../Common/FeedbackDialog";
import coveredgIcon from "../../../assets/ai-logo.svg";
import ThoughtsDrawer from "./ThoughtsDrawer";
import { theme } from "../../../consts/versionB/consts/theme";
import { usePdfFocus } from "../../../context/versionB/Pdf";
import debounce from "lodash.debounce";
import HTMLFormattedAnswer from "./HTMLFormattedAnswer";

interface IProps {
    item: [{ role: string; content: string }, { role: string; content: string; references: IAllReferences; thoughts?: string }];
    objIndex: number;
    allAnswersLen: number;
    callEditedQuery: (index: number, query: string) => void;
    isLoading: boolean;
    isOpen: boolean;
    setIsOpen: Function;
    citationMeta: {
        path: string;
        page: number;
        text: string;
    };
    setCitationMeta: Function;
    user: string;
}

interface IReference {
    path: string;
    page: number;
    text: string;
}

interface IAllReferences {
    [key: string]: {
        path: string;
        page: number;
        text: string;
    };
}

export default function QuesAnsBox({
    item,
    objIndex,
    allAnswersLen,
    isOpen,
    setIsOpen,
    setCitationMeta,
    citationMeta,
    callEditedQuery,
    isLoading,
    user
}: IProps) {
    const { setPdfFocusState } = usePdfFocus();
    // const [isOpen, setIsOpen] = useState<boolean>(false);
    const [expanded, setExpanded] = useState<boolean>(false);
    const answerRef = useRef(null);
    const messagesEndRef = useRef<HTMLDivElement>(null);
    const [isEditable, setIsEditable] = useState<boolean>(false);
    const [showEditPen, setShowEditPen] = useState(false);
    const [isCopied, setIsCopied] = useState<boolean>(false);
    const [content, setContent] = useState<string>("");

    useEffect(() => {
        if (isOpen) {
            setPdfFocusState({
                documentId: "1",
                pageNumber: citationMeta.page,
                citation: {
                    path: citationMeta.path,
                    page: citationMeta.page,
                    text: citationMeta.text
                }
            });
        } else {
            setPdfFocusState({
                documentId: "0",
                pageNumber: 0,
                citation: undefined
            });
        }
    }, [isOpen]);

    useEffect(() => {
        messagesEndRef?.current?.scrollIntoView({ behavior: "smooth" });
    }, [item]);

    useEffect(() => {
        const timeOut = setTimeout(() => {
            if (isCopied) {
                setIsCopied(false);
            }
        }, 2000);

        return () => {
            clearTimeout(timeOut);
        };
    }, [isCopied]);

    const showDocument = (path: string, page: number, text: string) => {
        setIsOpen(true);
        setCitationMeta({
            path,
            page,
            text
        });
    };

    // Editing Content
    const editContent = (query: string) => {
        setContent(query);
        setIsEditable(true);
    };

    const cancelEditing = () => {
        setContent("");
        setIsEditable(false);
    };

    const updateContent = (index: number) => {
        setIsEditable(false);
        callEditedQuery(index, content);
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                margin: "10px 0px 10px 0px"
            }}
        >
            {/* // Ques */}
            <div>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={1} sm={0.6}>
                                <div
                                    className={styles.userAvatar}
                                    style={{
                                        background: theme.palette.primaryDark,
                                        color: theme.palette.white
                                    }}
                                >
                                    {user[0]}
                                </div>
                            </Grid>
                            <Grid item xs={11} sm={11.4}>
                                <Typography variant="body1" component={"p"} sx={{ fontWeight: "bold" }}>
                                    You
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={0.6}></Grid>
                            <Grid item xs={11} sm={11.4} onMouseEnter={() => setShowEditPen(true)} onMouseLeave={() => setShowEditPen(false)}>
                                {/* // {allAnswersLen - 1 === objIndex ? ( */}
                                <div className={styles.quesContainer}>
                                    {!isLoading && isEditable ? (
                                        <div className={styles.message} style={{ width: "100%" }}>
                                            <Input
                                                multiline
                                                placeholder=""
                                                value={content}
                                                style={{ width: "100%", borderBottom: "none" }}
                                                disableUnderline
                                                onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
                                                    if (event.key === "Enter" && !event.shiftKey) {
                                                        event.preventDefault();
                                                        updateContent(objIndex);
                                                    }
                                                }}
                                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => setContent(event.target.value)}
                                            />
                                        </div>
                                    ) : (
                                        <div className={styles.message}>
                                            <div style={(theme.typography.chat.question, { whiteSpace: "pre-line" })}>{item[0].content}</div>
                                        </div>
                                    )}
                                </div>

                                {!isLoading && isEditable ? (
                                    <Stack horizontal horizontalAlign="center">
                                        <Button
                                            onClick={() => updateContent(objIndex)}
                                            variant="contained"
                                            sx={{
                                                mr: 1,
                                                textTransform: "capitalize",
                                                background: theme.palette.primaryLight,
                                                color: theme.palette.white,
                                                "&:hover": {
                                                    backgroundColor: theme.palette.primaryLight
                                                }
                                            }}
                                        >
                                            Save & Submit
                                        </Button>
                                        <Button
                                            onClick={() => cancelEditing()}
                                            variant="outlined"
                                            sx={{
                                                textTransform: "capitalize",
                                                borderColor: theme.palette.lightGray,
                                                color: theme.palette.black,
                                                "&:hover": {
                                                    borderColor: theme.palette.lightGray
                                                }
                                            }}
                                        >
                                            Cancel
                                        </Button>
                                    </Stack>
                                ) : (
                                    <IconButton
                                        style={{ visibility: showEditPen ? "visible" : "hidden" }}
                                        className={styles.actionIcons}
                                        iconProps={{ iconName: "Edit" }}
                                        size={30}
                                        onClick={() => editContent(item[0].content)}
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container>
                            <Grid item xs={1} sm={0.6}>
                                <img src={coveredgIcon} width={"auto"} height={22} />
                            </Grid>
                            <Grid item xs={11} sm={11.4}>
                                <Typography variant="body1" component={"p"} sx={{ fontWeight: "bold" }}>
                                    CoverageAI
                                </Typography>
                            </Grid>
                            <Grid item xs={1} sm={0.6}></Grid>
                            <Grid item xs={11} sm={11.4}>
                                <HTMLFormattedAnswer
                                    answer={item}
                                    showDocs={showDocument}
                                    isLoading={isLoading}
                                    allAnswersLen={allAnswersLen}
                                    objIndex={objIndex}
                                    callEditedQuery={callEditedQuery}
                                    answerRef={answerRef}
                                />
                            </Grid>
                            <Grid item xs={11} sm={11.4}>
                                <div className={styles.chatMessageGpt} ref={answerRef}></div>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </div>
            {/* // Auto scroll to end */}
            <span ref={messagesEndRef}></span>
        </Box>
    );
}
