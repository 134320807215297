import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { FormGroup, FormControlLabel, Checkbox, Skeleton } from "@mui/material";
import { theme } from "../../../consts/versionB/consts/theme";
import { OutcomeObj } from "../../../api/versionB/api";
import { getOutcomes } from "../../../api/versionB/api/api";

export default function ClaimOutcomeStepper({ disabled }: { disabled: boolean }) {
    const [allOutcomes, setAllOutcomes] = React.useState<OutcomeObj[]>([]);
    const [selectedOutcome, setSelectedOutcome] = React.useState<number>();
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    React.useEffect(() => {
        getAllOutcomes();
    }, []);

    const getAllOutcomes = async () => {
        try {
            setIsLoading(true);
            const result: OutcomeObj[] = await getOutcomes();
            setAllOutcomes(result);
            setIsLoading(false);
        } catch (e) {
            console.log(e);
            setIsLoading(false);
            setAllOutcomes([]);
        }
    };

    return (
        <Box sx={{ width: "100%", paddingRight: 1, paddingLeft: 1 }}>
            {isLoading && (
                <>
                    <Skeleton variant="rounded" width={"100%"} height={100} sx={{ my: 1 }} />
                    <Skeleton variant="rounded" width={"100%"} height={100} sx={{ my: 1 }} />
                    <Skeleton variant="rounded" width={"100%"} height={100} sx={{ my: 1 }} />
                </>
            )}
            {!isLoading && !allOutcomes[0] && (
                <Typography variant="body1" component={"p"} sx={{ fontSize: 13, color: theme.palette.darkGray, mb: 1 }}>
                    No outcomes available
                </Typography>
            )}
            {!isLoading &&
                allOutcomes.map((step, index) => (
                    <Box sx={{ mb: 4 }} key={step.title}>
                        <FormGroup>
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        disabled={disabled}
                                        sx={{
                                            "&.Mui-checked": {
                                                color: theme.palette.primaryLight
                                            }
                                        }}
                                        onClick={() => setSelectedOutcome(index)}
                                        checked={selectedOutcome === index}
                                        size="small"
                                    />
                                }
                                labelPlacement="end"
                                label={
                                    <Typography sx={{ fontSize: 14, fontWeight: "bold", color: theme.palette.primaryGray, mb: "inherit" }}>
                                        {step.title}
                                    </Typography>
                                }
                            />
                            <Typography sx={{ fontSize: 12, color: theme.palette.primaryGray, pl: 3.5 }}>{step.description}</Typography>
                            <small
                                style={{
                                    paddingTop: "10px",
                                    paddingLeft: "30px",
                                    fontSize: 12,
                                    color: theme.palette.primaryLight,
                                    fontWeight: "bold",
                                    textDecoration: "underline"
                                }}
                            >
                                Documentation
                            </small>
                        </FormGroup>
                    </Box>
                ))}
        </Box>
    );
}
