import { Auth0Provider } from "@auth0/auth0-react";
import React, { ReactNode } from "react";

const Auth0ProviderWithChildren = ({ children }: { children: ReactNode }) => {
    const auth0clientId = import.meta.env.VITE_AUTH0_CLIENT_ID;
    const authDomain = import.meta.env.VITE_AUTH0_DOMAIN;

    return (
        <Auth0Provider
            domain={authDomain}
            clientId={auth0clientId}
            authorizationParams={{
                redirect_uri: `${window.location.origin}/#/callback`
            }}
        >
            {children}
        </Auth0Provider>
    );
};

export default Auth0ProviderWithChildren;
