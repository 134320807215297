import { Button } from "@mui/material";
import React from "react";
import { theme } from "../../../consts/versionB/consts/theme";
import { makeStyles } from "@mui/material";

interface IProps {
    btnHandler: Function;
    text: string;
    btnType: string;
}

const CommonButton = ({ btnHandler, text, btnType }: IProps) => {
    const combinedStyle = btnType === "default" ? btnStyle : dangerBtnStyle;

    return (
        <Button
            sx={{
                ...combinedStyle,
                "&:hover": {
                    opacity: 0.8,
                    backgroundColor: btnType === "default" ? btnStyle : dangerBtnStyle
                }
            }}
            autoFocus
            onClick={() => btnHandler()}
            variant="text"
        >
            {text}
        </Button>
    );
};

const btnStyle = {
    // border: "1px solid #888",
    // color: theme.palette.black,
    textTransform: "capitalize",
    fontWeight: "bold",
    borderWidth: 1,
    borderColor: theme.palette.lightGray
};

const dangerBtnStyle = {
    border: "1px solid #888",
    color: theme.palette.white,
    textTransform: "capitalize",
    fontWeight: "bold",
    background: theme.palette.btnRed
};
export default CommonButton;
