import { useEffect, useRef, useState, Fragment } from "react";
import { Box, Skeleton, Typography, useMediaQuery, useTheme } from "@mui/material";
import QuesAnsBox from "../../../components/versionB/PolicyChat/QuesAnsBox";
import styles from "./PolicyChat.module.css";
import { QuestionInputField } from "../../../components/versionB/PolicyChat/QuestionInputField";
import ErrorSnackbar from "../../../components/versionB/ErrorSnackbar/ErrorSnackbar";
import CoverageAINote from "../../../components/versionB/Notes/CoverageAINote";
import PolicyChatLayout from "../../../components/versionB/PolicyChat/PolicyChatLayout";
import lens_coveredg from "../../../assets/lens_coveredg.png";
import { Approaches, ClaimDetailObj, HistoryItem, jsonChatAPI, policyChatAPI, ragChatAPI } from "../../../api/versionB/api";
import UserQueryGenerating from "../../../components/versionB/PolicyChat/UserQueryGenerating";
import { useAuth } from "../../../context/AuthProvider";
import HelpFab from "../../../components/versionB/Common/HelpFab";
import { getClaimDetails } from "../../../api/versionB/api/api";
import CommonDialog from "../../../components/versionB/Common/CommonDialog";
import { theme } from "../../../consts/versionB/consts/theme";
import { useClientAndClaimOptions } from "../../../context/versionB/ClientAndClaimProvider";
import { Height } from "@mui/icons-material";
import { IClaimData } from "../../../api/versionB/api/data";
import { PdfFocusProvider } from "../../../context/versionB/Pdf";
import CitationDrawer from "../../../components/versionB/PolicyChat/CitationDrawer";

interface IChat {
    role: string;
    content: string;
    references: Object;
}

interface IRequest {
    approach: string;
    query: string;
    history: Object[];
    policy: string;
    optimizationVersion: string;
}

const PolicyChatNewer = () => {
    const { user } = useAuth();
    const { selectedClaimDetails, selectedClient } = useClientAndClaimOptions();
    const abortController = useRef<any>(null);
    const lastQueryRef = useRef<string>("");
    const messagesEndRef = useRef<any>(null);
    const [isClaimDetailLoading, setIsClaimDetailLoading] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [chats, setChats] = useState<Array<IChat[]>>([]);
    const [isQueryEdited, setIsQueryEdited] = useState({ isEdited: false, query: "" });
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
    const [filteredData, setFilteredData] = useState<any[]>([]);
    const [selectedPolicy, setSelectedPolicy] = useState<string>("");
    const [operator, setOperator] = useState<string>("rag");
    const [firstQues, setFirstQues] = useState<HistoryItem>();
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [allPdf, setAllPdf] = useState<string[]>([]);
    const [citationMeta, setCitationMeta] = useState({
        path: "",
        page: 0,
        text: ""
    });

    // styling
    const muiTheme = useTheme();

    const matchesSm = useMediaQuery(muiTheme.breakpoints.down("sm"));
    const matchesMd = useMediaQuery(muiTheme.breakpoints.between("sm", "md"));
    // Run only if first query hit
    useEffect(() => {
        if (chats.length === 1) {
            setFirstQues({
                id: "1",
                title: chats[0][0].content,
                create_time: "2024-02-06T12:00:00.000000+00:00",
                isArchived: false,
                updatetime: "2024-02-06T12:00:00.000000+00:00"
            });
        }
    }, [chats]);
    // condition before rendering
    useEffect(() => {
        setChats([]);
        getClaimDetailsAPI();
    }, [selectedClient, selectedClaimDetails]);

    useEffect(() => {
        isLoading && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [isLoading]);

    // update response
    useEffect(() => {
        if (isQueryEdited.isEdited) {
            operator === "json"
                ? callApiRequestJSONApproach(isQueryEdited.query)
                : operator === "rag"
                ? callApiRequestRagApproach(isQueryEdited.query)
                : callApiRequest(isQueryEdited.query);
            setIsQueryEdited({ isEdited: false, query: "" });
        }
    }, [isQueryEdited]);

    useEffect(() => {
        filterDocuments(filteredData);
    }, [filteredData]);

    // get Claim Details
    const getClaimDetailsAPI = async () => {
        try {
            if (selectedClaimDetails) {
                setIsClaimDetailLoading(true);
                const result: IClaimData = await getClaimDetails({ claimNo: selectedClaimDetails.claimNo, policyValue: selectedClient! });
                setFilteredData([result]);
                setSelectedPolicy(selectedClaimDetails.programNumber);
                setIsClaimDetailLoading(false);
            }
        } catch (e) {
            setFilteredData([]);
            setIsClaimDetailLoading(false);
        }
    };

    const filterDocuments = (data: any) => {
        const policiesDocuments: string[] =
            data[0] && data[0]["programNumber"] === "US-Client-0001"
                ? ["US-Client.pdf", "HO0003 (05-11).pdf", "EH1026 (05-17).pdf", "EH1092TX (05-17).pdf"]
                : data[0] && data[0]["programNumber"] === "UK-TPA-Client-0001"
                ? ["UK-TPA-Client.pdf"]
                : data[0] && data[0]["programNumber"] === "Kemper_0001"
                ? ["Declarations SAMPLE TX.pdf", "HO0003 (05-11).pdf"]
                : data[0] && data[0]["programNumber"] === "Kemper_0002"
                ? ["Decpage1.pdf", "HO0003 (05-11).pdf"]
                : data[0] && data[0]["programNumber"] === "Kemper_0003"
                ? ["Decpage2.pdf", "HO0003 (05-11).pdf"]
                : data[0] && data[0]["programNumber"] === "Kemper_0004"
                ? ["Decpage3.pdf", "HO0003 (05-11).pdf"]
                : data[0] && data[0]["programNumber"] === "Kemper_0005"
                ? ["Decpage4.pdf", "HO0003 (05-11).pdf"]
                : data[0] && data[0]["programNumber"] === "Kemper_0006"
                ? ["Decpage5.pdf", "HO0003 (05-11).pdf"]
                : data[0] && data[0]["programNumber"] === "Kemper_0007"
                ? ["Decpage6.pdf", "HO0003 (05-11).pdf"]
                : data[0] && data[0]["programNumber"] === "Canada_0001"
                ? ["HOMEOWNERS COMPREHENSIVE FORM.pdf", "Insurance contract.pdf"]
                : data[0] && data[0]["programNumber"] === "Berkley_0001"
                ? ["Berkley_Base.pdf", "Berkley_endo.pdf", "Berkley_Dec.pdf"]
                : data[0] && data[0]["programNumber"] === "Zurich_0001"
                ? ["Policy Wording4.pdf", "Policy Schedule4.pdf"]
                : data[0] && data[0]["programNumber"] === "Chubb_0001"
                ? ["Policy Wording (2).pdf", "Policy Schedule (2).pdf"]
                : data[0] && data[0]["programNumber"] === "Openly_0001"
                ? ["Openly Base Policy.pdf", "Declaration Openly.pdf", "1503.pdf", "1504.pdf", "1505.pdf", "1506.pdf", "1507.pdf", "1508.pdf", "1509.pdf", "1510.pdf", "1511.pdf", "1512.pdf", "1513.pdf", "1514.pdf", "1515.pdf", "1516.pdf", "1517.pdf", "1518.pdf", "1519.pdf", "1520.pdf", "1521.pdf"]
                : [];

        setAllPdf([...policiesDocuments]);
    };

    const modifyResult = async (userQuery: string, assistantResponse: IChat) => {
        let userQues = { role: "user", content: userQuery };
        let assistantAns = await assistantResponse;
        return [userQues, assistantAns];
    };

    const developChatTree = (chatBlock: IChat[]) => {
        const tempChat = [...chats];
        tempChat.push(chatBlock);
        setChats(tempChat);
    };

    const callApiRequest = async (query: string) => {
        lastQueryRef.current = query;

        setIsLoading(true);

        // Setting abort request
        abortController.current = new AbortController();
        const abortSignal = abortController.current.signal;
        try {
            const request: IRequest = {
                approach: Approaches.PolicyGPT,
                query: query,
                history: chats.flat(),
                policy: selectedPolicy,
                optimizationVersion: "base"
            };
            const result: IChat = await policyChatAPI(request, abortSignal);
            let block: any = await modifyResult(query, result);
            developChatTree(block);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setShowErrorAlert(true);
        }
    };

    const callApiRequestJSONApproach = async (query: string) => {
        lastQueryRef.current = query;

        setIsLoading(true);

        // Setting abort request
        abortController.current = new AbortController();
        const abortSignal = abortController.current.signal;
        try {
            const request: IRequest = {
                approach: Approaches.JSONChat,
                query: query,
                history: chats.flat(),
                policy: selectedPolicy,
                optimizationVersion: "base"
            };
            const result: IChat = await jsonChatAPI(request, abortSignal);
            let block: any = await modifyResult(query, result);
            developChatTree(block);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setShowErrorAlert(true);
        }
    };

    const callApiRequestRagApproach = async (query: string) => {
        lastQueryRef.current = query;
        let activeOptimVal = localStorage.getItem("active_optamization_version");
        setIsLoading(true);

        // Setting abort request
        abortController.current = new AbortController();
        const abortSignal = abortController.current.signal;
        try {
            const request: IRequest = {
                approach: Approaches.RAGChat,
                query: query,
                history: chats.flat(),
                policy: selectedPolicy,
                optimizationVersion: activeOptimVal ? activeOptimVal : "base"
            };
            const result: IChat = await ragChatAPI(request, abortSignal);
            let block: any = await modifyResult(query, result);
            developChatTree(block);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setShowErrorAlert(true);
        }
    };

    const clearChatHistory = () => {
        setChats([]);
    };

    // Poping and calling API
    const callEditedQuery = (index: number, query: string) => {
        setChats(prevChats => {
            const chatsArr = prevChats.slice(0, index);
            return [...chatsArr];
        });
        setIsQueryEdited({ isEdited: true, query });
    };

    // Abort request
    const cancelRequest = () => {
        abortController.current && abortController.current.abort();
        setIsLoading(false);
    };

    return (
        <Fragment>
            <PdfFocusProvider>
                <HelpFab />
                <PolicyChatLayout
                    selectedClaimDetails={selectedClaimDetails}
                    detailsData={filteredData}
                    clearHistory={setIsAlertVisible}
                    isClaimDetailLoading={isClaimDetailLoading}
                    clearChatHistory={clearChatHistory}
                    firstQues={firstQues}
                >
                    {!isClaimDetailLoading ? (
                        <div
                            className={styles.chatRoot}
                            style={{ borderTopLeftRadius: theme.border.bcardBorder, borderTopRightRadius: theme.border.bcardBorder }}
                        >
                            <Typography
                                sx={{
                                    py: 1.5,
                                    fontSize: 14,
                                    color: theme.palette.primaryGray,
                                    fontWeight: "bold",
                                    pl: 3,
                                    background: theme.palette.lightWhite,
                                    borderTopLeftRadius: theme.border.bcardBorder,
                                    borderTopRightRadius: theme.border.bcardBorder
                                }}
                            >
                                {selectedClaimDetails ? `Claim #: ${filteredData[0]?.claimNo}` : "No Claim Selected"}
                            </Typography>
                            <div className={styles.chatContainer}>
                                {!chats[0] && !isLoading && (
                                    <div className={styles.chatEmptyState}>
                                        <img src={lens_coveredg} width={"auto"} height={150} />
                                    </div>
                                )}
                                <div className={styles.chatMessageStream}>
                                    {chats.map((item: any, index: number) => {
                                        return (
                                            <QuesAnsBox
                                                item={item}
                                                key={index.toString()}
                                                objIndex={index}
                                                allAnswersLen={chats.length}
                                                callEditedQuery={callEditedQuery}
                                                isLoading={isLoading}
                                                user={user.given_name}
                                                isOpen={isOpen}
                                                setIsOpen={setIsOpen}
                                                setCitationMeta={setCitationMeta}
                                                citationMeta={citationMeta}
                                            />
                                        );
                                    })}

                                    <>
                                        <Box
                                            sx={{
                                                width: "100%",
                                                height: "auto",
                                                margin: "10px 0px 10px 0px",
                                                display: isLoading ? "block" : "none"
                                            }}
                                        >
                                            <UserQueryGenerating message={lastQueryRef.current} user={user.given_name} />
                                        </Box>
                                    </>
                                    <span ref={messagesEndRef}></span>
                                </div>
                            </div>
                            <div className={styles.questionInputContainer}>
                                <QuestionInputField
                                    selectedClaimDetails={selectedClaimDetails}
                                    clearOnSend
                                    placeholder={`Message CoverageAI...`}
                                    disabled={isLoading}
                                    onSend={query =>
                                        operator === "json"
                                            ? callApiRequestJSONApproach(query)
                                            : operator === "rag"
                                            ? callApiRequestRagApproach(query)
                                            : callApiRequest(query)
                                    }
                                    cancelRequest={cancelRequest}
                                />
                                <CoverageAINote />
                            </div>
                        </div>
                    ) : (
                        <Skeleton sx={{ height: "88vh" }} animation="wave" variant="rectangular" />
                    )}
                </PolicyChatLayout>

                {/* Info Dialog */}
                <CommonDialog
                    isAlertVisible={isAlertVisible}
                    setIsAlertVisible={setIsAlertVisible}
                    alertTitle="Clear History"
                    alertDescription="Are you sure you want to clear chat history ?"
                    alertHandler={() => clearChatHistory()}
                    buttonText={["Cancel", "Clear"]}
                />

                {/* // Error handler */}
                <ErrorSnackbar showErrorAlert={showErrorAlert} setShowErrorAlert={setShowErrorAlert} />
                {/* // citation drawer */}
                <CitationDrawer allPdfs={allPdf} isOpen={isOpen} setIsOpen={setIsOpen} fileName={citationMeta.path} pgNum={Number(citationMeta.page)} />
            </PdfFocusProvider>
        </Fragment>
    );
};

export default PolicyChatNewer;
