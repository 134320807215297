import React, { Fragment, ReactNode } from "react";
import { AppBar, Avatar, Box, Divider, Grid, IconButton, ListItemIcon, Menu, MenuItem, Toolbar, Tooltip, Typography } from "@mui/material";
import Logo from "../Logo/Logo";
import { theme } from "../../../consts/versionB/consts/theme";
import { Text } from "@fluentui/react";
import PersonAdd from "@mui/icons-material/PersonAdd";
import Settings from "@mui/icons-material/Settings";
import Logout from "@mui/icons-material/Logout";
import ClaimSearch from "../ClaimSearch/ClaimSearch";
import CoveredLogo from "../Logo/CoveredLogo";
import { useAuth } from "../../../context/AuthProvider";
import SettingsDialog from "./SettingsDialog";
interface IProps {
    position: "fixed" | "absolute" | "sticky" | "static" | "relative" | undefined;
    title: string;
    icon: ReactNode;
    functionTitle: string;
    func: Function;
    showSearchClaim: boolean;
}

const CommonAppBar = ({ position, title, icon, functionTitle, func, showSearchClaim }: IProps) => {
    const { user, logout } = useAuth();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [settingDialogShow, setSettingDialogShow] = React.useState(false);
    const open = Boolean(anchorEl);
    const handleClick = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const userLogout = () => {
        handleClose();
        logout();
    };

    const loadSettings = () => {
        handleClose();
        setSettingDialogShow(true);
    };
    return (
        <Fragment>
            <AppBar position={position} elevation={0} sx={{ zIndex: theme => theme.zIndex.drawer + 1, backgroundColor: "transparent" }}>
                <Toolbar>
                    <Grid container>
                        <Grid
                            item
                            xs={0}
                            sm={0}
                            md={3}
                            lg={3}
                            sx={{ display: { xs: "none", sm: "none", md: "flex", lg: "flex" }, justifyContent: "flex-start", alignItems: "center" }}
                        >
                            <Logo color={"dark"} width={"35%"} />
                            <Box
                                style={{ border: `1px solid ${theme.palette.textGray}`, width: "1px", height: "100%", marginLeft: "10px", marginRight: "10px" }}
                            />
                            <CoveredLogo bg={"dark"} width={"30%"} />
                        </Grid>
                        <Grid
                            item
                            xs={11}
                            sm={11}
                            md={6}
                            lg={6}
                            sx={{ display: { xs: "flex", sm: "flex", md: "flex", lg: "flex", justifyContent: "center", alignItems: "center" } }}
                        >
                            {showSearchClaim ? (
                                <ClaimSearch />
                            ) : (
                                <Typography variant="h6" component="div" sx={{ textAlign: "center", color: theme.palette.textGray, fontWeight: "bold" }}>
                                    {title}
                                </Typography>
                            )}
                        </Grid>
                        <Grid item xs={1} sm={1} md={3} lg={3} style={{ display: "flex", justifyContent: "end", alignItems: "center" }}>
                            {/* <div style={{ display: "flex", alignItems: "center", gap: "6px", cursor: "pointer" }} onClick={() => func()}>
                                {icon}
                                <Box sx={{ display: { xs: "none", sm: "none", md: "flex" } }}>
                                    <Text style={{ color: theme.palette.textGray, fontWeight: 500 }}>{functionTitle}</Text>
                                </Box>
                            </div> */}
                            <Box>
                                <IconButton
                                    onClick={handleClick}
                                    size="small"
                                    aria-controls={open ? "account-menu" : undefined}
                                    aria-haspopup="true"
                                    aria-expanded={open ? "true" : undefined}
                                >
                                    <Avatar sx={{ width: 32, height: 32, display: "flex", fontSize: 14 }}>
                                        {user.given_name[0]}
                                        {user?.family_name[0]}
                                    </Avatar>
                                </IconButton>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: "visible",
                                        filter: "drop-shadow(0px 2px 8px rgba(0,0,0,0.32))",
                                        mt: 1.5,
                                        "& .MuiAvatar-root": {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1
                                        },
                                        "&::before": {
                                            content: '""',
                                            display: "block",
                                            position: "absolute",
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: "background.paper",
                                            transform: "translateY(-50%) rotate(45deg)",
                                            zIndex: 0
                                        }
                                    }
                                }}
                                transformOrigin={{ horizontal: "right", vertical: "top" }}
                                anchorOrigin={{ horizontal: "right", vertical: "bottom" }}
                            >
                                <MenuItem onClick={() => loadSettings()}>
                                    <ListItemIcon>
                                        <Settings fontSize="small" sx={{ color: theme.palette.textGray, fontSize: 20 }} />
                                    </ListItemIcon>
                                    <Text style={{ color: theme.palette.textGray, fontWeight: 500 }}>Settings</Text>
                                </MenuItem>
                                <MenuItem onClick={() => userLogout()}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" sx={{ color: theme.palette.textGray, fontSize: 20 }} />
                                    </ListItemIcon>
                                    <Text style={{ color: theme.palette.textGray, fontWeight: 500 }}>Logout</Text>
                                </MenuItem>
                            </Menu>
                        </Grid>
                    </Grid>
                </Toolbar>
            </AppBar>
            {/* {Settings Dialog} */}
            <SettingsDialog setSettingDialogShow={setSettingDialogShow} settingDialogShow={settingDialogShow} />
        </Fragment>
    );
};

export default CommonAppBar;
