import { Fragment } from "react";
import Header from '../../components/Header/Header'
import { Box, Typography } from '@mui/material'
import Logo from '../../components/Logo/Logo'
import QuesAnsBox from '../../components/LangchainUI/QuesAnsBox'
import styles from "./Langchain.module.css";
import { useEffect, useRef, useState } from 'react'
import { Approaches, queryWithLangchain } from '../../api'
import { SparkleFilled } from '@fluentui/react-icons'
import { QuestionInputField } from '../../components/LangchainUI/QuestionInputField'
import AbortControllerUI from '../../components/Common/AbortController';
import { AnswerLoading } from '../../components/Common/AnswerLoading';
import { UserChatMessage } from '../../components/UserChatMessage';
import ErrorSnackbar from "../../components/ErrorSnackbar/ErrorSnackbar";

const Langchain = () => {
    const abortController = useRef<any>(null);
    const lastQueryRef = useRef<string>("");
    const messagesEndRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [chats, setChats] = useState<any>([]);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);

    useEffect(() => {
        isLoading && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [isLoading]);

    const developChatTree = (query: string, response: string, data_points: {
        source: string | null,
        page: string | number | null
    }) => {
        const tempChat = [...chats];
        tempChat.push({
            query,
            response,
            data_points
        });

        setChats(tempChat);
    };

    const callApiRequest = async (query: string) => {
        lastQueryRef.current = query;

        setIsLoading(true);

        // Setting abort request
        abortController.current = new AbortController();
        const abortSignal = abortController.current.signal;
        try {
            const request = {
                approach: Approaches.Langchain,
                query: query
            };
            const result = await queryWithLangchain(request, abortSignal);
            developChatTree(query, result.answer, result.data_points);
            setIsLoading(false);

        } catch (e) {
            setIsLoading(false);
            setShowErrorAlert(true);
        }
        finally {
            setIsLoading(false);
        }
    };

    return (
    <Fragment>
        <div style={{
            display: "flex",
            flexDirection: "column",
            height: "100%"
        }}>
            <div className={styles.container}>
                <div className={styles.topHeader}>
                    <Header >
                        <Box flexGrow={1}>
                            <Logo />
                        </Box>
                        <Box flexGrow={0.5}>
                            <Typography variant="h6" component="div">
                                Langchain Document Query
                            </Typography>
                        </Box>
                        <Box flexGrow={1}></Box>
                    </Header>
                </div>
                <div className={styles.chatRoot}>
                    <div className={styles.chatContainer}>
                        {!chats[0] && !isLoading && <div className={styles.chatEmptyState}>
                            <SparkleFilled fontSize={"120px"} primaryFill={"rgba(115, 118, 225, 1)"} aria-hidden="true" aria-label="Chat logo" />
                            <h1 className={styles.chatEmptyStateTitle}>Chat with your documents</h1>
                        </div>}
                        <div className={styles.chatMessageStream}>
                            {chats.map((item: any) => {
                                return <QuesAnsBox item={item} />;
                            })}
                            {isLoading && (
                                <>
                                    <Box
                                        sx={{
                                            width: "100%",
                                            height: "auto",
                                            margin: "10px 0px 10px 0px",
                                        }}
                                    >
                                        <div style={{ marginLeft: 10, marginRight: 10 }}>
                                            <UserChatMessage message={lastQueryRef.current} />
                                            <div className={styles.chatMessageGptMinWidth}>
                                                <AnswerLoading />
                                            </div>
                                        </div>
                                    </Box>
                                    <AbortControllerUI abortController={abortController} />
                                </>
                            )}
                            <span ref={messagesEndRef}></span>
                        </div>
                        <div className={styles.questionInputContainer}>
                            <QuestionInputField
                                clearOnSend
                                placeholder={!chats[0] && !isLoading ? "Type a query (e.g. Who is the insurer of Specsavers ?)" : ""}
                                disabled={isLoading}
                                onSend={query => callApiRequest(query)} />
                        </div>
                    </div></div>
            </div >
        </div >

         {/* // Error handler */}
         <ErrorSnackbar showErrorAlert={showErrorAlert} setShowErrorAlert={setShowErrorAlert} />
    </Fragment>
    )
}

export default Langchain;