import { Drawer, Box } from "@mui/material";
import { ViewPdf } from "../PdfViewer/ViewPdf";
import { HORIZONTAL_GUTTER_SIZE_PX, PDF_SIDEBAR_SIZE_PX, PDF_WIDTH_PERCENTAGE } from "../PdfViewer/PdfDisplayConstants";
import { useWindowWidth } from "@wojtekmaj/react-hooks";

interface IProps {
    allPdfs: string[];
    isOpen: boolean;
    setIsOpen: Function;
    fileName: string;
    pgNum: number;
}

export default function CitationDrawer({ allPdfs, isOpen, setIsOpen, fileName, pgNum }: IProps) {
    const windowWidth = useWindowWidth();
    const newWidthPx = PDF_WIDTH_PERCENTAGE * 0.01 * (windowWidth || 0) - PDF_SIDEBAR_SIZE_PX - HORIZONTAL_GUTTER_SIZE_PX;

    return (
        <div>
            <Drawer
                ModalProps={{
                    keepMounted: true
                }}
                anchor={"right"}
                open={isOpen}
                onClose={() => setIsOpen(false)}
                sx={{ zIndex: theme => theme.zIndex.drawer + 1 }}
            >
                <Box sx={{ width: newWidthPx + 50, overflowY: "hidden", display: "inline-block" }} role="presentation">
                    <Box>
                        {allPdfs.map(item => {
                            return (
                                <div style={{ display: fileName !== item ? "none" : "block" }}>
                                    <ViewPdf
                                        key={item}
                                        file={{ id: "1", url: `../policy_documents/${item}`, docType: "pdf", fileName: item }}
                                        setIsOpen={setIsOpen}
                                        pgNum={pgNum}
                                    />
                                </div>
                            );
                        })}
                    </Box>
                </Box>
            </Drawer>
        </div>
    );
}
