export const enum Approaches {
    RetrieveThenRead = "rtr",
    ReadRetrieveRead = "rrr",
    ReadDecomposeAsk = "rda",
    ChatReadApproach = "rcr",
    DatabaseChat = "dbc",
    Langchain = "pdflang",
    DocumentQueryBeta = "matrix",
    QueryCSV = "csv",
    PolicyGPT = "wholedoc",
    JSONChat = "json",
    RAGChat = "rag"
}

export type AskRequestOverrides = {
    semanticRanker?: boolean;
    semanticCaptions?: boolean;
    excludeCategory?: string;
    top?: number;
    temperature?: number;
    promptTemplate?: string;
    promptTemplatePrefix?: string;
    promptTemplateSuffix?: string;
    suggestFollowupQuestions?: boolean;
};

export type AskRequest = {
    question: string;
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type AskResponse = {
    answer: string;
    thoughts: string | null;
    data_points: string[];
    error?: string;
};

export type ChatTurn = {
    user: string;
    bot?: string;
};

export type MatrixChat = {
    role: string;
    content: string;
    references?: {
        file?: string;
        page?: string[];
    };
};

export type ChatRequest = {
    history: ChatTurn[];
    approach: Approaches;
    overrides?: AskRequestOverrides;
};

export type DocumentMatrixChat = {
    approach: Approaches;
    query: string;
    history: MatrixChat[];
};
