import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { theme } from "../../../consts/versionB/consts/theme";

interface IProps {
    isAlertVisible: boolean;
    setIsAlertVisible: Function;
}

export default function ShareChatDialog({ isAlertVisible, setIsAlertVisible }: IProps) {
    const handleClose = () => {
        setIsAlertVisible(false);
    };

    const runFunc = () => {
        setIsAlertVisible(false);
    };

    return (
        <div>
            <Dialog
                fullWidth
                maxWidth={"sm"}
                open={isAlertVisible}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle sx={{ fontWeight: "bold", fontSize: 16 }}>Share link to Chat</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description" sx={{ color: theme.palette.black }}>
                       Message you send after creating your link won't be shared. Anyone with the URL will be able to view the shared chat.
                    </DialogContentText>{" "}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => runFunc()}>Copy Link</Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
