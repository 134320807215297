import React, { useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Grid, Typography } from "@mui/material";
// import CitationDrawer from "./CitationDrawer";
import { theme } from "../../../consts/versionB/consts/theme";
import { DocumentMultiple24Regular } from "@fluentui/react-icons";
import { Link } from "react-router-dom";

// const Accordion = styled((props: AccordionProps) => <MuiAccordion disableGutters elevation={0} square {...props} />)(({ theme }) => ({
//     border: `1px solid ${theme.palette.divider}`,
//     "&:not(:last-child)": {
//         borderBottom: 0
//     },
//     "&:before": {
//         display: "none"
//     }
// }));

// const AccordionSummary = styled((props: AccordionSummaryProps) => (
//     <MuiAccordionSummary expandIcon={<ArrowForwardIosSharpIcon sx={{ fontSize: "0.9rem" }} />} {...props} />
// ))(({ theme }) => ({
//     backgroundColor: theme.palette.mode === "dark" ? "rgba(255, 255, 255, .05)" : "rgba(0, 0, 0, .03)",
//     flexDirection: "row-reverse",
//     "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
//         transform: "rotate(90deg)"
//     },
//     "& .MuiAccordionSummary-content": {
//         marginLeft: theme.spacing(1)
//     }
// }));

// const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
//     padding: theme.spacing(2),
//     borderTop: "1px solid rgba(0, 0, 0, .125)"
// }));

interface IProps {
    disabled: boolean;
    data: any[];
}
export default function DocumentsDrawer({ disabled, data }: IProps) {
    const policiesDocuments: string[] =
        data[0] && data[0]["programNumber"] === "US-Client-0001"
            ? ["US-Client.pdf", "HO0003 (05-11).pdf", "EH1026 (05-17).pdf", "EH1092TX (05-17).pdf"]
            : data[0] && data[0]["programNumber"] === "UK-TPA-Client-0001"
            ? ["UK-TPA-Client.pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0001"
            ? ["Declarations SAMPLE TX.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0002"
            ? ["Decpage1.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0003"
            ? ["Decpage2.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0004"
            ? ["Decpage3.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0005"
            ? ["Decpage4.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0006"
            ? ["Decpage5.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Kemper_0007"
            ? ["Decpage6.pdf", "HO0003 (05-11).pdf"]
            : data[0] && data[0]["programNumber"] === "Canada_0001"
            ? ["HOMEOWNERS COMPREHENSIVE FORM.pdf", "Insurance contract.pdf"]
            : data[0] && data[0]["programNumber"] === "Berkley_0001"
            ? ["Berkley_Base.pdf", "Berkley_endo.pdf", "Berkley_Dec.pdf"]
            : data[0] && data[0]["programNumber"] === "Zurich_0001"
            ? ["Policy Wording4.pdf", "Policy Schedule4.pdf"]
            : data[0] && data[0]["programNumber"] === "Chubb_0001"
            ? ["Policy Wording (2).pdf", "Policy Schedule (2).pdf"]
            : data[0] && data[0]["programNumber"] === "Openly_0001"
            ? ["Openly Base Policy.pdf", "Declaration Openly.pdf", "1503.pdf", "1504.pdf", "1505.pdf", "1506.pdf", "1507.pdf", "1508.pdf", "1509.pdf", "1510.pdf", "1511.pdf", "1512.pdf", "1513.pdf", "1514.pdf", "1515.pdf", "1516.pdf", "1517.pdf", "1518.pdf", "1519.pdf", "1520.pdf", "1521.pdf"]
            : [];
    const inspectionDocuments: string[] = [];
    const others: string[] = [];
    const [isOpen, setIsOpen] = useState(false);
    const [expanded, setExpanded] = React.useState<string | false>("");
    const [selectedFile, setSelectedFile] = useState("");

    const handleChange = (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
        setExpanded(newExpanded ? panel : false);
    };

    return (
        <div>
            <Accordion
                sx={{
                    "&.Mui-disabled": {
                        backgroundColor: theme.palette.lightWhite
                    }
                }}
                disabled={disabled}
                expanded={expanded === "panel1"}
                elevation={0}
                style={{ margin: "0px" }}
                onChange={handleChange("panel1")}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <Typography style={{ fontSize: 13, color: theme.palette.primaryGray, fontWeight: "bold" }}>Policies</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ padding: 0, background: "#f5f5f5", overflowY: "auto", height: "auto", maxHeight: 280 }}>
                    {policiesDocuments.map(item => {
                        return (
                            <a href={`/policy_documents/${item}`} target="_blank" style={{ textDecoration: "none" }}>
                                <Grid
                                    container
                                    style={boxCSS}
                                    key={item}
                                    onClick={() => {
                                        setSelectedFile(item);
                                        setIsOpen(true);
                                    }}
                                >
                                    <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <DocumentMultiple24Regular style={{ ...headingCSS, color: theme.palette.primaryLight }} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography style={{ ...headingCSS, color: theme.palette.primaryLight }}>{item}</Typography>
                                    </Grid>
                                </Grid>
                            </a>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion
                sx={{
                    "&.Mui-disabled": {
                        backgroundColor: theme.palette.lightWhite
                    }
                }}
                disabled={disabled}
                expanded={expanded === "panel2"}
                elevation={0}
                style={{ margin: "0px" }}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                    <Typography style={{ fontSize: 13, color: theme.palette.primaryGray, fontWeight: "bold" }}>Inspection Reports</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ background: "#f5f5f5", overflowY: "auto", height: "auto", maxHeight: 280 }}>
                    {inspectionDocuments.map(item => {
                        return (
                            <a href={`/policy_documents/${item}`} target="_blank" style={{ textDecoration: "none" }}>
                                <Grid
                                    container
                                    style={boxCSS}
                                    key={item}
                                    onClick={() => {
                                        setSelectedFile(item);
                                        setIsOpen(true);
                                    }}
                                >
                                    <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <DocumentMultiple24Regular style={{ ...headingCSS, color: theme.palette.primaryLight }} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography style={{ ...headingCSS, color: theme.palette.primaryLight }}>{item}</Typography>
                                    </Grid>
                                </Grid>
                            </a>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion
                sx={{
                    "&.Mui-disabled": {
                        backgroundColor: theme.palette.lightWhite
                    }
                }}
                disabled={disabled}
                expanded={expanded === "panel3"}
                elevation={0}
                style={{ margin: "0px" }}
                onChange={handleChange("panel3")}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                    <Typography style={{ fontSize: 13, color: theme.palette.primaryGray, fontWeight: "bold" }}>Others</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ background: "#f5f5f5", overflowY: "auto", height: "auto", maxHeight: 280 }}>
                    {others.map(item => {
                        return (
                            <a href={`/policy_documents/${item}`} target="_blank" style={{ textDecoration: "none" }}>
                                <Grid
                                    container
                                    style={boxCSS}
                                    key={item}
                                    onClick={() => {
                                        setSelectedFile(item);
                                        setIsOpen(true);
                                    }}
                                >
                                    <Grid item xs={2} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                        <DocumentMultiple24Regular style={{ ...headingCSS, color: theme.palette.primaryLight }} />
                                    </Grid>
                                    <Grid item xs={10}>
                                        <Typography style={{ ...headingCSS, color: theme.palette.primaryLight }}>{item}</Typography>
                                    </Grid>
                                </Grid>
                            </a>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            {/* <CitationDrawer isOpen={isOpen} setIsOpen={setIsOpen} fileName={selectedFile} isPdfNavigating={false} /> */}
        </div>
    );
}

const headingCSS = {
    fontWeight: "600",
    fontSize: "12px"
};

const boxCSS = {
    height: 50,
    width: "100%",
    display: "flex",
    cursor: "pointer",
    alignItems: "center",
    justifyContent: "space-around"
};
