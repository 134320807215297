import { ReactNode, createContext, useState, useContext, useEffect } from "react";
import { SearchedClaim, SearchedClient } from "../../api/versionB/api/models";
interface IClientAndClaim {
    selectedClient: string | null;
    setSelectedClient: Function;
    selectedOption: string;
    setSelectedOption: Function;
    selectedClaimDetails: SearchedClaim | undefined;
    setSelectedClaimDetails: Function;
}

export const ClientAndClaimContext = createContext<IClientAndClaim | undefined>(undefined);

const ClientAndClaimProvider = ({ children }: { children: ReactNode }) => {
    const [selectedClient, setSelectedClient] = useState<string | null>(null);
    const [selectedOption, setSelectedOption] = useState<string>("Claim");
    const [selectedClaimDetails, setSelectedClaimDetails] = useState<SearchedClaim>();

    return (
        <ClientAndClaimContext.Provider
            value={{ selectedClient, setSelectedClient, selectedOption, setSelectedOption, selectedClaimDetails, setSelectedClaimDetails }}
        >
            {children}
        </ClientAndClaimContext.Provider>
    );
};

export default ClientAndClaimProvider;

export const useClientAndClaimOptions = () => {
    const context = useContext(ClientAndClaimContext);
    if (context === undefined) {
        throw new Error("Something went wrong. Please try again");
    }

    return context;
};
