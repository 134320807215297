import type { CSSProperties } from "react";

import React, { useCallback, useState, useEffect, useRef, memo } from "react";
import { forwardRef } from "react";

import { VariableSizeList as List } from "react-window";
import { useWindowWidth, useWindowHeight } from "@wojtekmaj/react-hooks";
import { useInView } from "react-intersection-observer";
import debounce from "lodash.debounce";

import {
    HORIZONTAL_GUTTER_SIZE_PX,
    OBSERVER_THRESHOLD_PERCENTAGE,
    PAGE_HEIGHT,
    PDF_HEADER_SIZE_PX,
    PDF_SIDEBAR_SIZE_PX,
    PDF_WIDTH_PERCENTAGE,
    VERTICAL_GUTTER_SIZE_PX
} from "./PdfDisplayConstants";

import { Document, Page, pdfjs } from "react-pdf";
import "react-pdf/dist/esm/Page/TextLayer.css";
import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import { usePdfFocus } from "../../../context/versionB/Pdf";
import { multiHighlight } from "../../../utils/multi-line-highlight";
const pdfjsOptions = pdfjs.GlobalWorkerOptions;
const pdfjsVersion = pdfjs.version;
pdfjsOptions.workerSrc = "//unpkg.com/pdfjs-dist@" + String(pdfjsVersion) + "/legacy/build/pdf.worker.min.js";

interface PageType {
    getViewport: (arg0: { scale: number }) => { width: number };
}
interface PdfType {
    numPages: number;
    getPage: (val: number) => Promise<PageType>;
}

interface PageRenderer {
    file: any;
    pageNumber: number;
    pgNum: number;
    style: CSSProperties;
    scale: number;
    listWidth: number;
    setPageInView: (n: number) => void;
}
const PageRenderer: React.FC<PageRenderer> = ({ file, pageNumber, pgNum, style, scale, listWidth, setPageInView }) => {
    const { pdfFocusState } = usePdfFocus();
    const [shouldCenter, setShouldCenter] = useState(false);
    const [isHighlighted, setIsHighlighted] = useState(false);

    const { ref: inViewRef, inView } = useInView({
        threshold: OBSERVER_THRESHOLD_PERCENTAGE * Math.min(1 / scale, 1)
    });

    const containerRef = useRef<HTMLDivElement>(null);

    const setRefs = useCallback(
        (node: HTMLDivElement | null | undefined) => {
            (containerRef as React.MutableRefObject<HTMLDivElement | null>).current = node as HTMLDivElement | null;
            inViewRef(node);
        },
        [inViewRef]
    );

    useEffect(() => {
        if (inView) {
            setPageInView(pageNumber);
        }
    }, [inView, pageNumber, setPageInView, inViewRef]);

    const hidePageCanvas = useCallback(() => {
        if (containerRef.current) {
            const canvas = containerRef.current.querySelector("canvas");
            if (canvas) canvas.style.visibility = "hidden";
        }
    }, [containerRef]);

    const showPageCanvas = useCallback(() => {
        if (containerRef.current) {
            const canvas = containerRef.current.querySelector("canvas");
            if (canvas) canvas.style.visibility = "visible";
        }
    }, [containerRef]);

    const onPageLoadSuccess = useCallback(() => {
        hidePageCanvas();
    }, [hidePageCanvas]);

    const onPageRenderError = useCallback(() => {
        showPageCanvas();
    }, [showPageCanvas]);

    const onPageRenderSuccess = useCallback(
        (page: { width: number }) => {
            showPageCanvas();
            // maybeHighlight();
            if (listWidth > page.width) {
                setShouldCenter(true);
            } else {
                setShouldCenter(false);
            }
        },
        [showPageCanvas, listWidth]
    );

    const documentFocused = pdfFocusState.documentId === file.id;

    useEffect(() => {
        maybeHighlight();
    }, [documentFocused, inView]);

    const maybeHighlight = useCallback(
        debounce(() => {
            if (documentFocused && !isHighlighted) {
                if (pageNumber === pgNum) {
                    multiHighlight(pdfFocusState.citation.text, pageNumber);
                    setIsHighlighted(true);
                }
            }
        }, 1500),
        [pdfFocusState.citation?.text, pageNumber, isHighlighted]
    );

    return (
        <div
            key={`${file.id}-${pageNumber}`}
            ref={setRefs}
            style={{
                ...style,
                padding: "10px",
                backgroundColor: "WhiteSmoke",
                display: `${shouldCenter ? "flex" : ""}`,
                justifyContent: "center"
            }}
        >
            <Page
                scale={scale}
                onRenderSuccess={onPageRenderSuccess}
                onLoadSuccess={onPageLoadSuccess}
                onRenderError={onPageRenderError}
                pageIndex={pageNumber}
                renderAnnotationLayer
            />
        </div>
    );
};
interface VirtualizedPDFProps {
    file: any;
    scale: number;
    setIndex: (n: number) => void;
    setScaleFit: (n: number) => void;
    setNumPages: (n: number) => void;
    pgNum: number;
}
export interface PdfFocusHandler {
    scrollToPage: (page: number) => void;
}

const VirtualizedPDF = forwardRef<PdfFocusHandler, VirtualizedPDFProps>(({ file, scale, setIndex, setScaleFit, setNumPages, pgNum }, ref) => {
    const windowWidth = useWindowWidth();
    const windowHeight = useWindowHeight();
    const height = (windowHeight || 0) - PDF_HEADER_SIZE_PX;
    const newWidthPx = PDF_WIDTH_PERCENTAGE * 0.01 * (windowWidth || 0) - PDF_SIDEBAR_SIZE_PX - HORIZONTAL_GUTTER_SIZE_PX;

    const [pdf, setPdf] = useState<PdfType | null>(null);
    const listRef = useRef<List>(null);

    useEffect(() => {
        if (listRef.current) {
            listRef.current.resetAfterIndex(0);
        }
    }, [scale]);

    function onDocumentLoadSuccess(nextPdf: PdfType) {
        setPdf(nextPdf);
    }
    function getPageHeight(): number {
        const actualHeight = (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale;
        return actualHeight;
    }

    useEffect(() => {
        if (!pdf) {
            return;
        }
        async function loadFirstPage() {
            if (pdf) {
                await pdf.getPage(1).then((page: { getViewport: (arg0: { scale: number }) => { width: number } }) => {
                    const pageViewport = page.getViewport({ scale: 1 });
                    const pageWidth = pageViewport.width;
                    const computedScaleFit = newWidthPx / pageWidth;
                    setScaleFit(computedScaleFit);
                });
            }
        }
        loadFirstPage().catch(() => console.log("page load error"));
        setNumPages(pdf.numPages);
    }, [pdf, setNumPages, setScaleFit, newWidthPx]);

    React.useImperativeHandle(ref, () => ({
        scrollToPage: (page: number) => {
            onItemClick({ pageNumber: page });
        }
    }));

    const onItemClick = ({ pageNumber: itemPageNumber }: { pageNumber: number }) => {
        const fixedPosition = itemPageNumber * (PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale;
        if (listRef.current) {
            listRef.current.scrollTo(fixedPosition);
        }
    };

    const loadingDiv = () => {
        return <div style={{ display: "flex", height: "calc(100vh-44px)", width: "100%", justifyContent: "center", alignItems: "center" }}>Loading</div>;
    };

    return (
        <div style={{ position: "relative", height: "calc(100vh-44px)", width: "100%" }}>
            <Document key={file.url} onItemClick={onItemClick} file={file.url} onLoadSuccess={onDocumentLoadSuccess} loading={loadingDiv}>
                {pdf ? (
                    <List
                        ref={listRef}
                        width={newWidthPx + HORIZONTAL_GUTTER_SIZE_PX}
                        height={height}
                        itemCount={pdf.numPages}
                        itemSize={getPageHeight}
                        estimatedItemSize={(PAGE_HEIGHT + VERTICAL_GUTTER_SIZE_PX) * scale}
                    >
                        {({ index, style }) => (
                            <PageRenderer
                                file={file}
                                key={`page-${index}`}
                                pageNumber={index}
                                pgNum={pgNum}
                                style={style}
                                scale={scale}
                                listWidth={newWidthPx}
                                setPageInView={setIndex}
                            />
                        )}
                    </List>
                ) : null}
            </Document>
        </div>
    );
});
const MemoizedVirtualizedPDF = memo(VirtualizedPDF);

MemoizedVirtualizedPDF.displayName = "VirtualizedPDF";

export default MemoizedVirtualizedPDF;
