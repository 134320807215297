import React, { ReactNode } from "react";
import { AppBar, Toolbar } from "@mui/material";
import { colors } from "../../consts/colors";

const Header = ({ children }: { children: ReactNode }) => {
    return (
        <AppBar position="static" sx={{ backgroundColor: colors.primaryDark }}>
            <Toolbar>{children}</Toolbar>
        </AppBar>
    );
};

export default Header;
