import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { colors } from "../../consts/colors";

interface IProps {
    isAlertVisible: boolean;
    setIsAlertVisible: Function;
    alertTitle: string;
    alertDescription: string;
    alertHandler: () => void;
    buttonText: string[];
}

export default function AlertDialog({ isAlertVisible, setIsAlertVisible, alertTitle, alertDescription, alertHandler, buttonText }: IProps) {
    const handleClose = () => {
        setIsAlertVisible(false);
    };

    const runFunc = () => {
        alertHandler();
        setIsAlertVisible(false);
    };

    return (
        <div>
            <Dialog open={isAlertVisible} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title">{alertTitle}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">{alertDescription}</DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>{buttonText[0]}</Button>
                    <Button onClick={() => runFunc()} autoFocus>
                        {buttonText[1]}
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}
