
import { Drawer, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { colors } from '../../consts/colors';

interface IProps {
    isOpen: boolean,
    setIsOpen: Function,
    thoughtsContent: string
}

export default function ThoughtsDrawer({ isOpen, setIsOpen, thoughtsContent }: IProps) {
    return (
        <div>

            <Drawer
                anchor={"right"}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <Box
                    sx={{ width: 600, overflowY: "auto" }}
                    role="presentation"
                >
                    <div style={{ backgroundColor: colors.primaryDark, height: 50, display: "flex", justifyContent: "space-between" }}>
                        <p style={{ color: "#fff", fontWeight: "600", paddingLeft: 20 }}>Thoughts</p>
                        <CancelIcon style={{ cursor: "pointer", color: "#fff", fontSize: "30", marginRight: 20, alignSelf: "center" }} onClick={() => setIsOpen(false)} />
                    </div>
                    <div dangerouslySetInnerHTML={{ __html: thoughtsContent }} style={{ color: "#000", paddingLeft: 20, paddingRight: 20 }} />
                </Box>
            </Drawer>
        </div >
    );
}