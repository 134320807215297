// interface
export interface IClaimData {
    claimNo: string | null;
    receivedDate: any;
    perilTypeID: string;
    carrierName: string;
    remark: null;
    bucketStatus: string;
    modifiedDate: Date | string;
    assignee: string;
    assigneeID: null;
    policyNo: string;
    policyStartDate: any;
    policyExpiryDate: any;
    claimSourceTypeID: string;
    sourceReference: null;
    isDuplicateClaim: boolean;
    lossDate: any;
    catCode: string;
    claimRefNo: string;
    clientGroup: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    country: string;
    zip: string;
    carrierReferenceNumber: null;
    service: string;
    assignor: string;
    policyHolderName: string;
    policyHolderPhone: string;
    policyHolderAddress: string;
    policyHolderCity: string;
    policyHolderState: string;
    policyHolderCountry: string;
    policyHolderZip: string;
    reason: string;
    latitude: string;
    longitude: string;
    lastModifiedBy: string;
    isMyClaim: boolean;
    programNumber: string;
    isMyteam: boolean;
    propertyTypeID: string;
    pendingReason: any[];
    isXactClaimUpdate: boolean;
    xactClaimUpdateCount: null;
    xactClaimLastUpdatedDateTime: null;
}

export const data = [
    { claimNum: "123132312", id: "123132312" },
    { claimNum: "6453738457", id: "6453738457" },
    { claimNum: "CC_00366374", id: "CC_00366374" },
    { claimNum: "54628746", id: "54628746" },
    { claimNum: "7454735243", id: "7454735243" },
    { claimNum: "846472834", id: "846472834" }
];

export const all_claims = [
    {
        claimNo: "23123821310",
        receivedDate: "04/08/2022",
        perilTypeID: "PTWD",
        carrierName: "KEMPER INDEPENDENCE INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2108460802",
        policyStartDate: "04/08/2022",
        policyExpiryDate: "04/08/2023",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "KEMPER INDEPENDENCE INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "JANE DARRIS",
        policyHolderPhone: "",
        policyHolderAddress: "5308 BAKER STREET DR",
        policyHolderCity: "FRIVE WORTH",
        policyHolderState: "TX",
        policyHolderCountry: "US",
        policyHolderZip: "76244-6236",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0001",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "23123822942",
        receivedDate: "07/17/2023",
        perilTypeID: "PTWD",
        carrierName: "KEMPER INDEPENDENCE INSURANCE  COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "October 17, 2023 at 12:01 a.m.",
        assignee: "",
        assigneeID: null,
        policyNo: "2281733013",
        policyStartDate: "07/17/2023",
        policyExpiryDate: "06/17/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "KEMPER INDEPENDENCE INSURANCE COMPANY",
        address1: "12926 Green Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "Jack Maa",
        policyHolderPhone: "",
        policyHolderAddress: "1320 AVE ARCADIA ",
        policyHolderCity: "Austin",
        policyHolderState: "TX (Texas)",
        policyHolderCountry: "US",
        policyHolderZip: "89868-4116",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0002",
        isMyteam: false,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: false,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "23123823906",
        receivedDate: "08/04/2023",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2259614402",
        policyStartDate: "08/04/2023",
        policyExpiryDate: "08/04/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "Ben King",
        policyHolderPhone: "",
        policyHolderAddress: " 818 BRODWAY LN SE",
        policyHolderCity: "ISANTI",
        policyHolderState: "MN",
        policyHolderCountry: "US",
        policyHolderZip: "55040-3202",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0003",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "23123853262",
        receivedDate: "10-04-2022",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN PREFERRED Insurance Company",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "EG 132646",
        policyStartDate: "10-04-2022",
        policyExpiryDate: "10-04-2023",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN PREFERRED Insurance Company",
        address1: "12926 Gran Bay Pkwy W",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "LADY KATY",
        policyHolderPhone: "",
        policyHolderAddress: "342 VAN ROWE",
        policyHolderCity: "DUNCANVILLE",
        policyHolderState: "TX ",
        policyHolderCountry: "US",
        policyHolderZip: "75116",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0004",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "23123902486",
        receivedDate: "05/23/2023",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2108497502",
        policyStartDate: "05/23/2023",
        policyExpiryDate: "05/23/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "DEAN JOHNSON",
        policyHolderPhone: "",
        policyHolderAddress: "1607 BUFF DR ",
        policyHolderCity: "SQUARE ROCK",
        policyHolderState: "TX",
        policyHolderCountry: "US",
        policyHolderZip: "78681",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0005",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "24123459204",
        receivedDate: "05/20/2023",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2160501502",
        policyStartDate: "05/20/2023",
        policyExpiryDate: "05/20/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN SAFEGUARD INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "JOHNY DEPP",
        policyHolderPhone: "",
        policyHolderAddress: "23936 IRVING BUFF DR",
        policyHolderCity: "AUSTIN",
        policyHolderState: "TX",
        policyHolderCountry: "US",
        policyHolderZip: "78727",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0006",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    },
    {
        claimNo: "28787235490",
        receivedDate: "08/23/2022",
        perilTypeID: "PTWD",
        carrierName: "UNITRIN AUTO AND HOME INSURANCE COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "2160340402",
        policyStartDate: "08/23/2022",
        policyExpiryDate: "08/23/2023",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "UNITRIN AUTO AND HOME INSURANCE COMPANY",
        address1: "12926 Gran Bay Parkway West Jacksonville, FL 32258",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32258",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "KEVIN SPACEY",
        policyHolderPhone: "",
        policyHolderAddress: "1026 19TH AVE NE",
        policyHolderCity: "MINNEAPOLIS",
        policyHolderState: "MN",
        policyHolderCountry: "US",
        policyHolderZip: "55406",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Kemper_0007",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    }
];

export const canada_claims = [
    {
        claimNo: "23121287993",
        receivedDate: "01/07/2023",
        perilTypeID: "PTWD",
        carrierName: "Test Broker",
        remark: null,
        bucketStatus: "",
        modifiedDate: "2023-10-20T11:29:07.047",
        assignee: "",
        assigneeID: null,
        policyNo: "7Z9654846",
        policyStartDate: "01/07/2023",
        policyExpiryDate: "01/07/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "Test Broker",
        address1: "205 MAIN STREET EAST",
        address2: "",
        city: "MILTON",
        state: "ONTARIO",
        country: "US",
        zip: "L9T 1N7",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "WILSON, KEVIN & AMANDA",
        policyHolderPhone: "",
        policyHolderAddress: "38 ACREDALE DR",
        policyHolderCity: "CARLISLE",
        policyHolderState: "ON",
        policyHolderCountry: "US",
        policyHolderZip: "L0R 1H2",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Canada_0001",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    }
];

export const berkley_claims = [
    {
        claimNo: "98995455557",
        receivedDate: "08/09/2023",
        perilTypeID: "PTWD",
        carrierName: "BERKLEY INSURANCE COMPANY COMPANY",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "5164318504",
        policyStartDate: "08/09/2023",
        policyExpiryDate: "08/09/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "BERKLEY INSURANCE COMPANY COMPANY",
        address1: "12926 Green Bay Parkway West Jacksonville, FL 32259",
        address2: "",
        city: "Jacksonville",
        state: "FL (Florida)",
        country: "US",
        zip: "32259",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "Rosey Dale",
        policyHolderPhone: "",
        policyHolderAddress: "8026 18TH AVE NE",
        policyHolderCity: "MINNEAPOLIS",
        policyHolderState: "MN",
        policyHolderCountry: "US",
        policyHolderZip: "55407",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Berkley_0001",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    }
];

export const zurich_claims = [
    {
        claimNo: "903120878",
        receivedDate: "01/03/2023",
        perilTypeID: "PTFR",
        carrierName: "Zurich Insurance Company Ltd",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "PC203120",
        policyStartDate: "01/03/2023",
        policyExpiryDate: "02/02/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: " Zurich Insurance Company Ltd ",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "DZA Retailers Ltd.",
        policyHolderPhone: "",
        policyHolderAddress: "Post Office 125-127 The Vale",
        policyHolderCity: "London",
        policyHolderState: "Middlesex",
        policyHolderCountry: "UK",
        policyHolderZip: "W3 7RQ ",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Zurich_0001",
        isMyteam: true,
        propertyTypeID: "PCOM",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    }
];

export const chubb_claims = [
    {
        claimNo: "903120878",
        receivedDate: "01/03/2023",
        perilTypeID: "PTFR",
        carrierName: "Chubb Insurance",
        remark: null,
        bucketStatus: "",
        modifiedDate: "",
        assignee: "",
        assigneeID: null,
        policyNo: "PC203122",
        policyStartDate: "01/03/2023",
        policyExpiryDate: "02/02/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: " Chubb Insurance",
        address1: "",
        address2: "",
        city: "",
        state: "",
        country: "",
        zip: "",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "DZA Retailers Ltd.",
        policyHolderPhone: "",
        policyHolderAddress: "Post Office 125-127 The Vale",
        policyHolderCity: "London",
        policyHolderState: "Middlesex",
        policyHolderCountry: "UK",
        policyHolderZip: "W3 7RQ ",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Chubb_0001",
        isMyteam: true,
        propertyTypeID: "PCOM",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    }
];

export const openly_claims = [
    {
        claimNo: "9814567993",
        receivedDate: "01/07/2023",
        perilTypeID: "PTWD",
        carrierName: "Test Broker",
        remark: null,
        bucketStatus: "",
        modifiedDate: "2023-10-20T11:29:07.047",
        assignee: "",
        assigneeID: null,
        policyNo: "68923491",
        policyStartDate: "01/07/2023",
        policyExpiryDate: "01/07/2024",
        claimSourceTypeID: "",
        sourceReference: null,
        isDuplicateClaim: false,
        lossDate: "N/A",
        catCode: "",
        claimRefNo: "",
        clientGroup: "Test Broker",
        address1: "205 MAIN STREET EAST",
        address2: "",
        city: "MILTON",
        state: "ONTARIO",
        country: "US",
        zip: "L9T 1N7",
        carrierReferenceNumber: null,
        service: "",
        assignor: "",
        policyHolderName: "WILSON, KEVIN & AMANDA",
        policyHolderPhone: "",
        policyHolderAddress: "38 ACREDALE DR",
        policyHolderCity: "CARLISLE",
        policyHolderState: "ON",
        policyHolderCountry: "US",
        policyHolderZip: "L0R 1H2",
        reason: "",
        latitude: "",
        longitude: "",
        lastModifiedBy: "",
        isMyClaim: true,
        programNumber: "Openly_0001",
        isMyteam: true,
        propertyTypeID: "PRES",
        pendingReason: [],
        isXactClaimUpdate: true,
        xactClaimUpdateCount: null,
        xactClaimLastUpdatedDateTime: null
    }
];


export const claimInfo = [
    {
        key: "claimNo",
        label: "Claim Number"
    },
    {
        key: "receivedDate",
        label: "Received Date"
    },
    {
        key: "carrierName",
        label: "Carrier Name"
    },
    {
        key: "programNumber",
        label: "Program #"
    },
    { key: "carrierReferenceNumber", label: "Carrier Reference Number" },
    { key: "catCode", label: "CAT" }
];

export const lossInfo = [
    { key: "lossDate", label: "Loss Date" },
    { key: "perilTypeID", label: "Peril Type" },
    { key: "propertyTypeID", label: "Property Type" },
    { key: "reason", label: "Reason" }
];

export const policyInfo = [
    { key: "policyHolderName", label: "Policy Holder's Name" },
    { key: "policyNo", label: "Policy #" },
    { key: "policyStartDate", label: "Policy Start Date" },
    { key: "policyExpiryDate", label: "Policy Expiry Date" },
    { key: "policyHolderAddress", label: "Policy Holder's Address" },
    { key: "policyHolderPhone", label: "Policy Holder's Phone" }
];
