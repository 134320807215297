import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";

interface IProps {
    setAllChecks: Function;
    allChecks: {
        isHarmfulOrUnsafe: boolean;
        isTrue: boolean;
        isHelpful: boolean;
    };
}
export default function FeedbackDialogChecks({ setAllChecks, allChecks }: IProps) {
    return (
        <FormGroup>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={allChecks.isHarmfulOrUnsafe}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setAllChecks((prevState: any) => ({
                                ...prevState,
                                isHarmfulOrUnsafe: event.target.checked
                            }))
                        }
                    />
                }
                label="This is harmful / unsafe"
                sx={{ mb: -2 }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={allChecks.isTrue}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setAllChecks((prevState: any) => ({
                                ...prevState,
                                isTrue: event.target.checked
                            }))
                        }
                    />
                }
                label="This isn't true"
                sx={{ mb: -2 }}
            />
            <FormControlLabel
                control={
                    <Checkbox
                        checked={allChecks.isHelpful}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                            setAllChecks((prevState: any) => ({
                                ...prevState,
                                isHelpful: event.target.checked
                            }))
                        }
                    />
                }
                label="This isn't helpful"
                sx={{ mb: -2 }}
            />
        </FormGroup>
    );
}
