import React from "react";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import maintenanceImage from "./maintenance-image.png";
import { Container } from "@mui/material";
import { theme } from "../consts/versionB/consts/theme";
import maintainImg from "../assets/maintainance.png";
const Maintainance = () => {
    return (
        <Container maxWidth="lg" sx={{ display: "flex", alignItems: "center", height: "100%" }}>
            <Grid container spacing={2}>
                <Grid item xs={12} md={5}>
                    <Typography variant="h4" component="h1" gutterBottom style={{ marginBottom: 20, color: theme.palette.textGray, fontWeight: "bold" }}>
                        We are <br />
                        Under Maintenance
                    </Typography>
                    <Typography variant="body1" gutterBottom sx={{ color: theme.palette.textGray }}>
                        We're currently enhancing your experience! Our application is undergoing maintenance to bring you exciting new features and
                        improvements. We apologize for any inconvenience caused and appreciate your patience. Please check back soon to explore the upgraded
                        version of our application.
                    </Typography>
                </Grid>
                <Grid item xs={12} md={1}></Grid>
                <Grid item xs={12} md={6}>
                    <img src={maintainImg} alt="Under Maintenance" style={{ width: "100%", height: "auto" }} />
                </Grid>
            </Grid>
        </Container>
    );
};

export default Maintainance;
