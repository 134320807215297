import { useState } from "react";
import { Grid, Paper, styled, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { IconButton, Stack } from "@fluentui/react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styles from "./QuesAnsBox.module.css";
import { useEffect, useRef } from 'react';
import { AnswerIcon } from "../Answer/AnswerIcon";
import clipboardCopy from "clipboard-copy";
import ThoughtsDrawer from "./ThoughtsDrawer";

interface IProps {
    item: {
        question: string,
        selectedDB: string,
        response: string,
        thoughts: string
    }
}

export default function QuesAnsBox({ item }: IProps) {
    const answerRef = useRef(null);
    const messagesEndRef = useRef<any>(null)
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [thoughtsContent, setThoughtsContent] = useState<string>("")

    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [item]);

    const handleHTMLContent = () => {
        if (item?.response?.includes('<table>')) {
            return <div dangerouslySetInnerHTML={{ __html: item?.response }} style={{ color: "#000" }} />;
        }
        return (
            <p style={{ fontWeight: "600", color: "#000" }}>
                {item?.response}
            </p >
        );
    };


    const showThoughts = () => {
        setThoughtsContent(item.thoughts);
        setIsOpen(true)
    }
    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                margin: "10px 0px 10px 0px",
            }}
        >
            {/* // Ques */}
            <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.quesContainer}>
                            <div className={styles.message}>{item.question}
                                <div className={styles.helperText}>
                                    Database: {item.selectedDB}
                                </div>
                            </div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.chatMessageGpt} ref={answerRef}>
                            <Stack className={styles.answerContainer} verticalAlign="space-between">
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign="space-between">
                                        <AnswerIcon />
                                            <div style={{ display: "flex", justifyContent: "flex-end", }}>
                                                <Tooltip title="copy">
                                                    <div onClick={() => {
                                                        if (answerRef.current) {
                                                            console.log(answerRef.current)
                                                            //@ts-ignore
                                                            clipboardCopy(item?.response)
                                                                .then(() => {
                                                                    console.log("Text copied to clipboard");
                                                                })
                                                                .catch((err: any) => {
                                                                    console.log(err);
                                                                });
                                                        }
                                                    }} style={{ marginRight: 10, cursor: "pointer", width: 40, height: 40, backgroundColor: "#efefef", borderRadius: 8, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                        <IconButton
                                                            style={{ color: "black" }}
                                                            iconProps={{ iconName: "Copy" }}
                                                            title="copy"
                                                            ariaLabel="copy"
                                                            size={30}
                                                        />
                                                    </div>
                                                </Tooltip>
                                                <Tooltip title="thoughts">
                                                    <div onClick={() => showThoughts()} style={{ cursor: "pointer", width: 40, height: 40, backgroundColor: "#efefef", borderRadius: 8, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                        <IconButton
                                                            style={{ color: "black" }}
                                                            iconProps={{ iconName: "Lightbulb" }}
                                                            title="copy"
                                                            ariaLabel="copy"
                                                            size={30}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item grow>
                                    {handleHTMLContent()}
                                </Stack.Item>
                                <Stack.Item>
                                    <div className={styles.helperText}>
                                        Database: {item.selectedDB}
                                    </div>
                                </Stack.Item>
                            </Stack>
                        </div>

                    </Grid>
                </Grid>
            </div>
            {/* // Auto scroll to end */}
            <span ref={messagesEndRef}></span>
            <div className='hr_container'>
                <hr />
            </div>
            {/* // Thoughts Drawer */}
            <ThoughtsDrawer isOpen={isOpen} setIsOpen={setIsOpen} thoughtsContent={thoughtsContent} />
        </Box>
    );
}