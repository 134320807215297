import { useEffect, useRef, useState, Fragment } from "react";
import { Box } from "@mui/material";
import QuesAnsBox from "../../components/PolicyChat/QuesAnsBox";
import styles from "./PolicyChat.module.css";
import { Approaches, jsonChatAPI, policyChatAPI, ragChatAPI } from "../../api";
import { QuestionInputField } from "../../components/PolicyChat/QuestionInputField";
import AbortControllerUI from "../../components/Common/AbortController";
import { AnswerLoading } from "../../components/Common/AnswerLoading";
import { UserChatMessage } from "../../components/UserChatMessage";
import ErrorSnackbar from "../../components/ErrorSnackbar/ErrorSnackbar";
import AlertDialog from "../../components/Common/AlertDialog";
import CoverageAINote from "../../components/Notes/CoverageAINote";
import { useLocation, useNavigate } from "react-router-dom";
import PolicyChatLayout from "../../components/PolicyChat/PolicyChatLayout";
import { IClaimData, all_claims, json_approach_claims, rag_approach_claims } from "../../api/data";
import lens_coveredg from "../../assets/lens_coveredg.png";
import ActiveMode from "../../components/PolicyChat/ActiveMode";

interface IChat {
    role: string;
    content: string;
    references: Object;
}

interface IRequest {
    approach: string;
    query: string;
    history: Object[];
    policy: string;
}

const PolicyChat = () => {
    const { state } = useLocation();
    const navigate = useNavigate();
    const abortController = useRef<any>(null);
    const lastQueryRef = useRef<string>("");
    const messagesEndRef = useRef<any>(null);
    const [isLoading, setIsLoading] = useState(false);
    const [chats, setChats] = useState<Array<IChat[]>>([]);
    const [showErrorAlert, setShowErrorAlert] = useState<boolean>(false);
    const [isAlertVisible, setIsAlertVisible] = useState<boolean>(false);
    // const [openPolicyDialog, setOpenPolicyDialog] = useState<boolean>(false);
    const [filteredData, setFilteredData] = useState<IClaimData[]>([]);
    const [selectedPolicy, setSelectedPolicy] = useState<string>("");
    // const kemperDocs = ["HO0003 (05-11)", "Declarations SAMPLE TX"];
    // const kemperDocs = ["Acme HO0003 (05-11)", "Acme Declarations"];
    // const kemperEndoDocs = ["HO0003 (05-11)", "Declarations SAMPLE TX", "EH1026 (05-17)", "EH1092TX (05-17)"];
    // const kemperEndoDocs = ["Acme HO0003 (05-11)", "Acme Declarations", "Acme EH1026 (05-17)", "Acme EH1092TX (05-17)"];
    // const getSafe = ["GetSafe"];

    // Getting history from browser storage
    // useEffect(() => {
    //     function getData() {
    //         const getDataFromStore = localStorage.getItem("chat_history");
    //         const parsedData = getDataFromStore !== null ? JSON.parse(getDataFromStore) : [];
    //         setChats(parsedData);
    //         localStorage.setItem("chat_history", JSON.stringify(parsedData));
    //     }

    //     getData();
    // }, []);

    // filter data
    useEffect(() => {
        if (state) {
            const newData: IClaimData[] = (
                state.operator === "json"
                    ? json_approach_claims
                    : state.operator === "rag"
                    ? rag_approach_claims
                    : state.operator === "coveredg"
                    ? all_claims
                    : []
            ).filter(item => item.claimNo === state.selectedClaimNumber);
            setFilteredData(newData);
            setSelectedPolicy(state.selectedProgram);
        } else {
            navigate("/apps");
        }
    }, [state]);

    useEffect(() => {
        isLoading && messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [isLoading]);

    // get selected policy
    useEffect(() => {
        if (state === null) {
            navigate("/apps");
        }
    }, [state]);

    const modifyResult = async (userQuery: string, assistantResponse: IChat) => {
        let userQues = { role: "user", content: userQuery };
        let assistantAns = await assistantResponse;
        return [userQues, assistantAns];
    };

    const developChatTree = (chatBlock: IChat[]) => {
        const tempChat = [...chats];
        tempChat.push(chatBlock);
        setChats(tempChat);
        // localStorage.setItem("chat_history", JSON.stringify(tempChat));
    };

    const callApiRequest = async (query: string) => {
        lastQueryRef.current = query;

        setIsLoading(true);

        // Setting abort request
        abortController.current = new AbortController();
        const abortSignal = abortController.current.signal;
        try {
            const request: IRequest = {
                approach: Approaches.PolicyGPT,
                query: query,
                history: chats.flat(),
                policy: selectedPolicy
            };
            const result: IChat = await policyChatAPI(request, abortSignal);
            let block: any = await modifyResult(query, result);
            developChatTree(block);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setShowErrorAlert(true);
        }
    };

    const callApiRequestJSONApproach = async (query: string) => {
        lastQueryRef.current = query;

        setIsLoading(true);

        // Setting abort request
        abortController.current = new AbortController();
        const abortSignal = abortController.current.signal;
        try {
            const request: IRequest = {
                approach: Approaches.JSONChat,
                query: query,
                history: chats.flat(),
                policy: selectedPolicy
            };
            const result: IChat = await jsonChatAPI(request, abortSignal);
            let block: any = await modifyResult(query, result);
            developChatTree(block);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setShowErrorAlert(true);
        }
    };

    const callApiRequestRagApproach = async (query: string) => {
        lastQueryRef.current = query;

        setIsLoading(true);

        // Setting abort request
        abortController.current = new AbortController();
        const abortSignal = abortController.current.signal;
        try {
            const request: IRequest = {
                approach: Approaches.RAGChat,
                query: query,
                history: chats.flat(),
                policy: selectedPolicy
            };
            const result: IChat = await ragChatAPI(request, abortSignal);
            let block: any = await modifyResult(query, result);
            developChatTree(block);
            setIsLoading(false);
        } catch (e) {
            setIsLoading(false);
            setShowErrorAlert(true);
        }
    };

    const clearChatHistory = () => {
        setChats([]);
        // localStorage.removeItem("chat_history");
    };

    const clearHistory = () => {
        setChats([]);
        // localStorage.removeItem("chat_history");
    };

    // Poping and calling API
    const callEditedQuery = (query: string) => {
        chats.pop();
        setChats([...chats]);
        state.operator === "json" ? callApiRequestJSONApproach(query) : state.operator === "rag" ? callApiRequestRagApproach(query) : callApiRequest(query);
    };

    return (
        <Fragment>
            <PolicyChatLayout detailsData={filteredData} clearHistory={setIsAlertVisible}>
                <div className={styles.chatRoot}>
                    <div className={styles.chatContainer}>
                        {!chats[0] && !isLoading && (
                            <div className={styles.chatEmptyState}>
                                <img
                                    src={lens_coveredg}
                                    width={"auto"}
                                    height={250}
                                    // style={{
                                    //     cursor: "pointer",
                                    //     marginBottom: 50
                                    // }}
                                />
                            </div>
                        )}
                        <div className={styles.chatMessageStream}>
                            {chats.map((item: any, index: number) => {
                                return (
                                    <QuesAnsBox
                                        item={item}
                                        key={index.toString()}
                                        objIndex={index}
                                        allAnswersLen={chats.length}
                                        callEditedQuery={callEditedQuery}
                                        isLoading={isLoading}
                                        operator={state.operator}
                                    />
                                );
                            })}
                            <>
                                <Box
                                    sx={{
                                        width: "100%",
                                        height: "auto",
                                        margin: "10px 0px 10px 0px",
                                        display: isLoading ? "block" : "none"
                                    }}
                                >
                                    <div style={{ marginLeft: 10, marginRight: 10 }}>
                                        <UserChatMessage message={lastQueryRef.current} />
                                        <div className={styles.chatMessageGptMinWidth}>
                                            <AnswerLoading />
                                        </div>
                                    </div>
                                </Box>
                                <AbortControllerUI abortController={abortController} />
                            </>
                            <span ref={messagesEndRef}></span>
                        </div>
                    </div>
                    <div className={styles.questionInputContainer}>
                        <ActiveMode operator={state.operator === "json" ? "JSON Approach" : state.operator === "rag" ? "Rag Approach" : "CoverageAI"} />
                        <QuestionInputField
                            clearOnSend
                            placeholder={
                                !chats[0] && !isLoading
                                    ? "Ask me your claim coverage questions eg: What are the policy limits and deductibles specific to hail damage claims ?"
                                    : ""
                            }
                            disabled={isLoading}
                            onSend={query =>
                                state.operator === "json"
                                    ? callApiRequestJSONApproach(query)
                                    : state.operator === "rag"
                                    ? callApiRequestRagApproach(query)
                                    : callApiRequest(query)
                            }
                        />
                        <CoverageAINote />
                    </div>
                </div>
            </PolicyChatLayout>

            {/* Info Dialog */}
            <AlertDialog
                isAlertVisible={isAlertVisible}
                setIsAlertVisible={setIsAlertVisible}
                alertTitle="Clear History"
                alertDescription="Are you sure you want to clear chat history ?"
                alertHandler={() => clearChatHistory()}
                buttonText={["Cancel", "Clear"]}
            />

            {/* // Error handler */}
            <ErrorSnackbar showErrorAlert={showErrorAlert} setShowErrorAlert={setShowErrorAlert} />
        </Fragment>
    );
};

export default PolicyChat;
