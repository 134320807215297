import { Snackbar, Alert } from "@mui/material";

interface IProps {
    showErrorAlert: boolean;
    setShowErrorAlert: Function;
}
const ErrorSnackbar = ({ showErrorAlert, setShowErrorAlert }: IProps) => {
    return (
        <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "right" }}
            open={showErrorAlert}
            autoHideDuration={5000}
            onClose={() => setShowErrorAlert(false)}
        >
            <Alert onClose={() => setShowErrorAlert(false)} severity="error" sx={{ width: "100%" }}>
                Hmm.... Looks like this approach is not working right now.
            </Alert>
        </Snackbar>
    );
};

export default ErrorSnackbar;
