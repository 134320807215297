import { Typography, Stack, styled, Box } from "@mui/material";
import CancelScheduleSendIcon from '@mui/icons-material/CancelScheduleSend';
import { colors } from '../../consts/colors';

const AbortCotrollerContainer = styled(Box)(() => ({
    padding: "0px 0px 0px 0px",
    marginTop: "0px",
    marginLeft: "10px"
}));

const AbortControllerUI = ({ abortController }: any) => {
    const cancelRequest = () => abortController.current && abortController.current.abort()
    return (
        <AbortCotrollerContainer>
            <Stack
                direction="row"
                onClick={() => cancelRequest()}
                sx={{
                    border: `1px solid ${colors.actionColor}`,
                    height: 30,
                    color: colors.primaryGray,
                    p: 1,
                    width: "fit-content",
                    marginTop: 1,
                    borderRadius: "20px",
                    justifyContent: "center",
                    backgroundColor: colors.actionColor,
                    alignItems: "center",
                    "&:hover": {
                        cursor: "pointer",
                    }
                }}
            >
                <Typography sx={{ mr: 1, fontSize: 12, color: "#fff", fontWeight: "bold" }}>Abort Request</Typography>
                <CancelScheduleSendIcon
                    sx={{
                        color: "#fff",
                        fontSize: 16
                    }}
                />
            </Stack>
        </AbortCotrollerContainer >

    )
}

export default AbortControllerUI