import { Box, Button, Grid, Paper, Link, CssBaseline, Typography, ThemeProvider, createTheme, styled, ButtonProps } from "@mui/material";
import { useNavigate } from "react-router-dom";
import BG from "../../assets/img_bg/login_bg.png";
import logo from "../../assets/logo-crawford.svg";

import styles from "./Authentication.module.css";
import { useAuth } from "../../context/AuthProvider";
import { theme } from "../../consts/versionB/consts/theme";
import CoveredLogo from "../../components/versionB/Logo/CoveredLogo";
import Logo from "../../components/versionB/Logo/Logo";

function Copyright(props: any) {
    return (
        <Typography variant="body2" color="text.secondary" align="center" {...props} style={{ fontSize: 12, color: theme.palette.white }}>
            {`© ${new Date().getFullYear()} Crawford & Company`}
            <br />
            All Rights Reserved.
        </Typography>
    );
}

const defaultTheme = createTheme();

const MainLogin = () => {
    const { loginWithRedirect, user } = useAuth();
    const navigate = useNavigate();

    const ColorButton = styled(Button)<ButtonProps>(({ theme: definedTheme }) => ({
        backgroundColor: theme.palette.primaryLight,
        width: "60%",
        fontWeight: "bold",
        "&:hover": {
            backgroundColor: theme.palette.primaryLight
        }
    }));
    return (
        <ThemeProvider theme={defaultTheme}>
            <Grid container component="main" sx={{ height: "100vh" }}>
                <CssBaseline />
                <Grid
                    item
                    xs={false}
                    sm={4}
                    md={7}
                    sx={{
                        backgroundImage: `url(${BG})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: t => (t.palette.mode === "light" ? t.palette.grey[50] : t.palette.grey[900]),
                        backgroundSize: "cover",
                        backgroundPosition: "center"
                    }}
                />
                <Grid
                    style={{ background: theme.palette.primaryDark, display: "flex", alignItems: "center" }}
                    item
                    xs={12}
                    sm={8}
                    md={5}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                        sx={{
                            mx: 4,
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "center",
                            height: "100%",
                            width: "100%"
                        }}
                    >
                        <Box sx={{ flex: 1, display: "flex", justifyContent: "center" }}>
                            <CoveredLogo bg="light" width={"60%"} />
                        </Box>
                        <Box component="form" noValidate sx={{ flex: 2, width: "100%" }}>
                            <Typography className={styles.formTitle} style={{ color: theme.palette.white }}>
                                Login
                            </Typography>
                            <Box className={styles.btnWrapper}>
                                <ColorButton
                                    type="button"
                                    onClick={() => loginWithRedirect()}
                                    variant="contained"
                                    sx={{ mb: 4, color: theme.palette.white, fontWeight: "bold" }}
                                >
                                    <p className={styles.btnText}>Carrier</p>
                                </ColorButton>
                                <ColorButton
                                    type="button"
                                    onClick={() => loginWithRedirect()}
                                    variant="contained"
                                    sx={{ mb: 1, color: theme.palette.white, fontWeight: "bold" }}
                                >
                                    <p className={styles.btnText}>Policy Holder</p>
                                </ColorButton>
                            </Box>
                            <div className={styles.linkWrapper}>
                                <Link href="https://crawco.com/legal/terms-of-use" target="_blank" className={styles.linkTC}>
                                    {"Terms & Conditions"}
                                </Link>
                            </div>
                        </Box>

                        <Box style={{ flex: 1, display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "end" }}>
                            {/* <img
                                src={logo}
                                width={"40%"}
                                height={"auto"}
                                onClick={() => navigate("/")}
                                style={{
                                    cursor: "pointer",
                                    background: theme.palette.primaryDark,
                                    display: "inline-block"
                                }}
                            /> */}
                            <Logo color={"light"} width={"40%"} />

                            <Copyright sx={{ py: 3 }} />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default MainLogin;
