import usePDFViewer from "../../../hooks/usePdfViewer";
import { PDFOptionsBar } from "./PdfOptionsBar";
import React from "react";
import MemoizedVirtualizedPDF from "./VirtualizedPdf";

interface ViewPdfProps {
    file: any;
    setIsOpen: Function;
    pgNum: number;
}

export const ViewPdf: React.FC<ViewPdfProps> = ({ file, setIsOpen, pgNum }) => {
    const {
        scrolledIndex,
        setCurrentPageNumber,
        scale,
        setScaleFit,
        numPages,
        setNumPages,
        handleZoomIn,
        handleZoomOut,
        nextPage,
        prevPage,
        scaleText,
        pdfFocusRef,
        goToPage,
        setZoomLevel,
        zoomInEnabled,
        zoomOutEnabled
    } = usePDFViewer(file);

    return (
        <div className="relative">
            {scaleText && (
                <PDFOptionsBar
                    file={file}
                    scrolledIndex={scrolledIndex}
                    numPages={numPages}
                    scaleText={scaleText}
                    nextPage={nextPage}
                    prevPage={prevPage}
                    handleZoomIn={handleZoomIn}
                    handleZoomOut={handleZoomOut}
                    goToPage={goToPage}
                    setZoomLevel={setZoomLevel}
                    zoomInEnabled={zoomInEnabled}
                    zoomOutEnabled={zoomOutEnabled}
                    setIsOpen={setIsOpen}
                />
            )}

            <MemoizedVirtualizedPDF
                key={`${file.id}`}
                ref={pdfFocusRef}
                file={file}
                setIndex={setCurrentPageNumber}
                scale={scale}
                setScaleFit={setScaleFit}
                setNumPages={setNumPages}
                pgNum={pgNum}
            />
        </div>
    );
};
