import * as React from "react";
import { claimInfo, lossInfo, policyInfo } from "../../../api/versionB/api/data";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { Typography } from "@mui/material";
import { theme } from "../../../consts/versionB/consts/theme";

interface IProps {
    disabled: boolean;
    data: any[];
}
export default function DetailsDrawerAccordion({ disabled, data }: IProps) {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    React.useEffect(() => {
        if (!disabled) {
            setExpanded("panel1");
        } else {
            setExpanded(false);
        }
    }, [disabled]);

    const handleChange = (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
        setExpanded(isExpanded ? panel : false);
    };
    return (
        <div>
            <Accordion
                sx={{
                    "&.Mui-disabled": {
                        backgroundColor: theme.palette.lightWhite
                    }
                }}
                disabled={disabled}
                expanded={expanded === "panel1"}
                elevation={0}
                style={{ margin: "0px" }}
                onChange={handleChange("panel1")}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel1-content" id="panel1-header">
                    <Typography style={{ fontSize: 12, color: theme.palette.primaryGray, fontWeight: "bold" }}>Claim Information</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ background: "#f5f5f5", overflowY: "auto", height: "auto", maxHeight: 280 }}>
                    {claimInfo.map(item => {
                        return (
                            <div style={{ marginBottom: 4, padding: 5 }} key={item.key}>
                                <Typography style={{ ...headingCSS, color: theme.palette.primaryGray }}>{item.label}</Typography>
                                <Typography style={{ ...valueCSS, color: theme.palette.primaryGray, paddingBottom: 10 }}>
                                    {data[0] && data[0][item.key]
                                        ? item.key === "receivedDate" ||
                                          item.key === "policyStartDate" ||
                                          item.key === "lossDate" ||
                                          item.key === "policyExpiryDate"
                                            ? new Date(data[0][item.key]).toLocaleDateString()
                                            : data[0][item.key]
                                        : "N/A"}
                                </Typography>
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion
                sx={{
                    "&.Mui-disabled": {
                        backgroundColor: theme.palette.lightWhite
                    }
                }}
                disabled={disabled}
                expanded={expanded === "panel2"}
                elevation={0}
                style={{ margin: "0px" }}
                onChange={handleChange("panel2")}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel2-content" id="panel2-header">
                    <Typography style={{ fontSize: 12, color: theme.palette.primaryGray, fontWeight: "bold" }}>Loss Information</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ background: "#f5f5f5", overflowY: "auto", height: "auto", maxHeight: 280 }}>
                    {lossInfo.map(item => {
                        return (
                            <div style={{ marginBottom: 4, padding: 5 }} key={item.key}>
                                <Typography style={{ ...headingCSS, color: theme.palette.primaryGray }}>{item.label}</Typography>
                                <Typography style={{ ...valueCSS, color: theme.palette.primaryGray, paddingBottom: 10 }}>
                                    {data[0] && data[0][item.key]
                                        ? item.key === "receivedDate" ||
                                          item.key === "policyStartDate" ||
                                          item.key === "lossDate" ||
                                          item.key === "policyExpiryDate"
                                            ? data[0][item.key] !== "N/A"
                                                ? new Date(data[0][item.key]).toLocaleDateString()
                                                : "N/A"
                                            : item.key === "perilTypeID"
                                            ? data[0][item.key] === "PTWR"
                                                ? "Water"
                                                : data[0][item.key] === "PTFR"
                                                ? "Fire"
                                                : data[0][item.key] === "PTFL"
                                                ? "Flood"
                                                : data[0][item.key] === "PTTF"
                                                ? "Theft"
                                                : data[0][item.key] === "PTVL"
                                                ? "Vehicle"
                                                : data[0][item.key] === "PTWD"
                                                ? "Wind/Hail"
                                                : "N/A"
                                            : item.key === "propertyTypeID"
                                            ? data[0][item.key] === "PCOM"
                                                ? "Commercial"
                                                : "Residential"
                                            : data[0][item.key]
                                        : "N/A"}
                                </Typography>
                            </div>
                        );
                    })}
                </AccordionDetails>
            </Accordion>
            <Accordion
                sx={{
                    "&.Mui-disabled": {
                        backgroundColor: theme.palette.lightWhite
                    }
                }}
                disabled={disabled}
                expanded={expanded === "panel3"}
                elevation={0}
                style={{ margin: "0px" }}
                onChange={handleChange("panel3")}
            >
                <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls="panel3-content" id="panel3-header">
                    <Typography style={{ fontSize: 12, color: theme.palette.primaryGray, fontWeight: "bold" }}>Policy Information</Typography>
                </AccordionSummary>
                <AccordionDetails sx={{ background: "#f5f5f5", overflowY: "auto", height: "auto", maxHeight: 270 }}>
                    {policyInfo.map(item => {
                        return (
                            <div style={{ marginBottom: 4, padding: 5 }} key={item.key}>
                                <Typography style={{ ...headingCSS, color: theme.palette.primaryGray }}>{item.label}</Typography>
                                <Typography style={{ ...valueCSS, color: theme.palette.primaryGray, paddingBottom: 10 }}>
                                    {data[0] && data[0][item.key]
                                        ? item.key === "receivedDate" ||
                                          item.key === "policyStartDate" ||
                                          item.key === "lossDate" ||
                                          item.key === "policyExpiryDate"
                                            ? new Date(data[0][item.key]).toLocaleDateString()
                                            : data[0][item.key]
                                        : "N/A"}
                                </Typography>
                            </div>
                        );
                    })}{" "}
                </AccordionDetails>
            </Accordion>
        </div>
    );
}

const headingCSS = {
    fontWeight: "600",
    width: "100%",
    fontSize: "11px"
};

const valueCSS = {
    width: "100%",
    fontSize: "14px"
};
