
import { Drawer, Box } from '@mui/material';
import CancelIcon from '@mui/icons-material/Cancel';
import { colors } from '../../consts/colors';

interface IProps {
    isOpen: boolean,
    setIsOpen: Function,
    fileName: string | null,
    page: string | number | null
}

export default function CitationDrawer({ isOpen, setIsOpen, fileName, page }: IProps) {
    return (
        <div>

            <Drawer
                anchor={"right"}
                open={isOpen}
                onClose={() => setIsOpen(false)}
            >
                <Box
                    sx={{ width: 600, overflowY: "hidden" }}
                    role="presentation"
                >
                    <div style={{ backgroundColor: colors.primaryDark, height: 50, display: "flex", justifyContent: "space-between" }}>
                        <p style={{ color: "#fff", fontWeight: "600", paddingLeft: 20 }}>{fileName}</p>
                        <CancelIcon style={{ cursor: "pointer", color: "#fff", fontSize: "30", marginRight: 20, alignSelf: "center" }} onClick={() => setIsOpen(false)} />
                    </div>
                    <div style={{ height: "calc(100vh - 50px)" }}>
                        <iframe title="Citation" src={`../data/${fileName}#page=${page}`} width="100%" height="100%" />
                    </div>
                </Box>
            </Drawer>
        </div >
    );
}