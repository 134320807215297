import { useState } from "react";
import { Grid, Paper, styled, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import { IconButton, Stack } from "@fluentui/react";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import styles from "./QuesAnsBox.module.css";
import { useEffect, useRef } from 'react';
import { AnswerIcon } from "../Answer/AnswerIcon";
import clipboardCopy from "clipboard-copy";
import CitationDrawer from "./CitationDrawer";

interface IProps {
    item: {
        query: string,
        response: string,
        data_points: {
            source: string | null,
            page: string | number | null
        }
    }
}

export default function QuesAnsBox({ item }: IProps) {
    const answerRef = useRef(null);
    const messagesEndRef = useRef<any>(null);
    const [isOpen, setIsOpen] = useState<boolean>(false);

    useEffect(() => {
        messagesEndRef.current.scrollIntoView({ behavior: "smooth" });
    }, [item]);

    const handleHTMLContent = () => {
        if (item?.response?.includes('<table>')) {
            return <div dangerouslySetInnerHTML={{ __html: item?.response }} style={{ color: "#000" }} />;
        }
        return (
            <p style={{ fontWeight: "600", color: "#000" }}>
                {item?.response}
            </p >
        );
    };

    return (
        <Box
            sx={{
                width: "100%",
                height: "auto",
                margin: "10px 0px 10px 0px",
            }}
        >
            {/* // Ques */}
            <div style={{ marginLeft: 10, marginRight: 10 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <div className={styles.quesContainer}>
                            <div className={styles.message}>{item.query}</div>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <div className={styles.chatMessageGpt} ref={answerRef}>
                            <Stack className={styles.answerContainer} verticalAlign="space-between">
                                <Stack.Item>
                                    <Stack horizontal horizontalAlign="space-between">
                                        <AnswerIcon />
                                        <div>
                                            <div style={{ display: "flex", justifyContent: "flex-end" }}>
                                                <Tooltip title="copy">
                                                    <div onClick={() => {
                                                        if (answerRef.current) {
                                                            console.log(answerRef.current)
                                                            //@ts-ignore
                                                            clipboardCopy(item?.response)
                                                                .then(() => {
                                                                    console.log("Text copied to clipboard");
                                                                })
                                                                .catch((err: any) => {
                                                                    console.log(err);
                                                                });
                                                        }
                                                    }} style={{ cursor: "pointer", width: 40, height: 40, backgroundColor: "#efefef", borderRadius: 8, justifyContent: "center", alignItems: "center", display: "flex" }}>
                                                        <IconButton
                                                            style={{ color: "black" }}
                                                            iconProps={{ iconName: "Copy" }}
                                                            title="copy"
                                                            ariaLabel="copy"
                                                            size={30}
                                                        />
                                                    </div>
                                                </Tooltip>
                                            </div>
                                        </div>
                                    </Stack>
                                </Stack.Item>
                                <Stack.Item grow>
                                    {handleHTMLContent()}
                                </Stack.Item>
                                {item.data_points.source !== null && item.data_points.source && (
                                    <Stack.Item>
                                        <Stack horizontal wrap tokens={{ childrenGap: 5 }}>
                                            <span className={styles.citationLearnMore}>Citations:</span>
                                            <a className={styles.citation} title={item.data_points.source} onClick={() => setIsOpen(!isOpen)}>
                                                {item.data_points.source} (Page: {item.data_points.page})
                                            </a>

                                        </Stack>
                                    </Stack.Item>
                                )}
                            </Stack>
                        </div>

                    </Grid>
                </Grid>
            </div>
            {/* // Auto scroll to end */}
            <span ref={messagesEndRef}></span>
            <div className='hr_container'>
                <hr />
            </div>

            {/* // Thoughts Drawer */}
            <CitationDrawer isOpen={isOpen} setIsOpen={setIsOpen} fileName={item.data_points.source} page={item.data_points.page} />
        </Box>
    );
}