import React, { useEffect } from "react";
import { Box, Button, Grid, Menu, MenuItem, MenuProps, Typography, alpha, styled } from "@mui/material";
import { theme } from "../../../consts/versionB/consts/theme";
import edit_icon from "../../../assets/versionB/icons/edit_icon.svg";
// import { ProfileButton } from "../Common/ProfileButton";
// import { history_items } from "../../../api/versionB/api/dummy_data";
import FilteredChatHistory from "./FilteredChatHistory";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { HistoryItem } from "../../../api/versionB/api";

const StyledMenu = styled((props: MenuProps) => (
    <Menu
        elevation={0}
        anchorOrigin={{
            vertical: "bottom",
            horizontal: "center"
        }}
        transformOrigin={{
            vertical: "top",
            horizontal: "center"
        }}
        {...props}
    />
))(({ theme }) => ({
    "& .MuiPaper-root": {
        borderRadius: 6,
        marginTop: theme.spacing(-0.1),
        color: theme.palette.mode === "light" ? "rgb(55, 65, 81)" : theme.palette.grey[300],
        boxShadow:
            "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
        "& .MuiMenu-list": {
            padding: "4px 0"
        },
        "& .MuiMenuItem-root": {
            "& .MuiSvgIcon-root": {
                fontSize: 18,
                color: theme.palette.text.secondary,
                marginRight: theme.spacing(1.5)
            },
            "&:active": {
                backgroundColor: alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity)
            }
        }
    }
}));

export const ChatHistory = ({ firstQues, clearChatHistory }: { firstQues: HistoryItem | undefined; clearChatHistory: Function }) => {
    const [historyItems, setHistoryItems] = React.useState<HistoryItem[]>([
        {
            id: "1",
            title: "Steps to ensure claim processed efficiently ?",
            create_time: "2024-02-06T12:00:00.000000+00:00",
            isArchived: false,
            updatetime: "2024-02-06T12:00:00.000000+00:00"
        }
    ]);

    useEffect(() => {
        const allData = [...historyItems];
        if (firstQues) {
            allData.push({ ...firstQues, title: firstQues.title.substring(0, 40) });
            setHistoryItems([...allData]);
        }
    }, [firstQues]);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [selectedFilter, setSelectedFilter] = React.useState("Today");
    const open = Boolean(anchorEl);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    const setFilter = (filter: string) => {
        setSelectedFilter(filter);
        handleClose();
    };

    return (
        <>
            <Box sx={{ display: "flex", flexDirection: "column", height: "100%", overflow: "hidden" }}>
                <Box
                    sx={{
                        width: "100%",
                        position: "sticky",
                        top: 0,
                        zIndex: 1,
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center"
                    }}
                >
                    <Button
                        onClick={() => clearChatHistory()}
                        variant="text"
                        endIcon={<img src={edit_icon} style={{ width: 12, marginRight: "8px" }} />}
                        sx={{
                            borderRadius: theme.border.scardBorder,
                            width: "100%",
                            justifyContent: "space-between",
                            textTransform: "capitalize",
                            background: theme.palette.white,
                            height: 50
                        }}
                    >
                        <Box sx={{ display: "flex", alignItems: "center" }}>
                            <Typography
                                component={"p"}
                                variant="caption"
                                sx={{ color: theme.palette.primaryLight, fontWeight: "bold", paddingLeft: "8px", fontSize: 12 }}
                            >
                                New Chat
                            </Typography>
                        </Box>
                    </Button>
                </Box>

                <Box
                    sx={{
                        flexGrow: 1,
                        overflowX: "hidden",
                        background: theme.palette.white,
                        borderRadius: theme.border.scardBorder,
                        marginY: 2
                    }}
                >
                    <Grid container sx={{ mb: 2 }}>
                        <Grid item xs={4}>
                            <Typography
                                sx={{
                                    fontSize: 13,
                                    fontWeight: "bold",
                                    pt: 1,
                                    pl: 2,
                                    color: theme.palette.primaryGray
                                }}
                            >
                                History
                            </Typography>
                        </Grid>
                        <Grid item xs={8} sx={{ display: "flex", justifyContent: "flex-end", paddingRight: 2 }}>
                            <div>
                                <Button
                                    disableRipple={true}
                                    disableFocusRipple={true}
                                    disableTouchRipple={true}
                                    sx={{
                                        fontSize: 11,
                                        textTransform: "capitalize",
                                        color: theme.palette.textGray,
                                        fontWeight: "bold",
                                        "&:hover": {
                                            background: "transparent"
                                        }
                                    }}
                                    id="demo-customized-button"
                                    // aria-controls={open ? "demo-customized-menu" : undefined}
                                    aria-haspopup="true"
                                    // aria-expanded={open ? "true" : undefined}
                                    variant="text"
                                    disableElevation
                                    // onClick={handleClick}
                                    endIcon={<KeyboardArrowDownIcon />}
                                    onClick={handleClick}
                                >
                                    {selectedFilter}
                                </Button>
                                <StyledMenu
                                    id="demo-customized-menu"
                                    MenuListProps={{
                                        "aria-labelledby": "demo-customized-button"
                                    }}
                                    anchorEl={anchorEl}
                                    open={open}
                                    onClose={handleClose}
                                >
                                    {["Today", "Previous 7 days", "Previous 30 days"].map((item: string) => (
                                        <MenuItem
                                            key={item}
                                            onClick={() => setFilter(item)}
                                            disableRipple
                                            sx={{ fontSize: 12, color: theme.palette.primaryGray }}
                                        >
                                            {item}
                                        </MenuItem>
                                    ))}
                                </StyledMenu>
                            </div>
                        </Grid>
                    </Grid>
                    <FilteredChatHistory data={historyItems} />
                </Box>
                {/* <ProfileButton /> */}
            </Box>
        </>
    );
};
